import { useEffect } from 'react';

export default function useKeyPress(targetKeyCode, handler) {
  function downHandler({ keyCode }) {
    if (keyCode === targetKeyCode) {
      handler();
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => window.removeEventListener('keydown', downHandler);
  }, [targetKeyCode]);
}

import React from 'react';
import PropTypes from 'prop-types';

import styled, { ThemeProvider } from 'styled-components';

import GiftCouponIcon from 'images/ic-giftcoupon.svg';

import Media from 'react-media';

import media, { mediaQuery } from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import ellipsis from 'styles/ellipsis.js';
import { gridItem } from 'styles/grid.js';
import {
  black, white, gray, red
} from 'styles/palette.js';

const Card = styled.div`
  ${gridItem(12, 6)}
  position: relative;
  margin-bottom: 16px;
  text-align: center;
  ${media.desktop`
    margin-bottom: 20px;
    text-align: left;
  `}
`;
const AdultWrapper = styled.div`
  position: relative;
`;

const Adult = styled.span`
  ${Oswald}
  display: flex;
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 28px;
  height: 28px;
  margin-top: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${gray};
  color: ${white};
  font-size: 14px;
  line-height: 1;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 40px;
  ${props => (media.desktop`
    margin-bottom: ${props.theme.primary ? '20px' : '36px'};
  `)}
`;

const Rank = styled.div`
  ${Oswald()}
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 54px;
  height: 54px;
  margin: 62.5% auto 0;
  padding: 8px;
  background-color: ${props => (props.theme.primary ? red : black)};
  transform: translateY(-50%);
  color: ${white};
  font-size: 22px;
  line-height: 1.2;
  text-align: center;
  ${media.desktop`
    margin-left: 4px;
    ${props => props.theme.primary && `
      width: 77px;
      height: 77px;
      margin-left: 8px;
      font-size: 36px;
    `}
  `}
`;

const RankText = styled.span`
  display: block;
  font-size: 12px;
  ${props => (media.desktop`
    ${props.theme.primary && 'font-size: 14px;'}
  `)}
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  ${media.mobile`
    justify-content: center;
  `}
  ${media.desktop`
    ${props => props.theme.primary && `
      margin-left: 105px;
    `}
  `}
`;

const Title = styled.h3`
  ${ellipsis}
  font-size: 20px;
  font-weight: bold;
  ${media.desktop`
    font-size: 18px;
    ${props => props.theme.primary && `
      font-size: 20px;
    `}
  `}
`;

const StyledGiftCouponIcon = styled(GiftCouponIcon)`
  width: 20px;
  margin-left: 4px;
`;

const Description = styled.p`
  ${ellipsis}
  margin-top: 8px;
  color: ${gray};
  font-size: 13px;
  ${media.desktop`
    margin-top: 10px;
    font-size: 14px;
    ${props => props.theme.primary && `
      margin-left: 105px;
    `}
  `}
`;

const Synopsis = styled.p`
  display: inline-block;
  max-height: 3.2em;
  margin-top: 8px;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.6;
  word-break: break-all;
  ${media.desktop`
    margin-top: 10px;
    font-size: 14px;
    ${props => props.theme.primary && `
      margin-left: 105px;
    `}
  `}
`;

const RankingCard = props => (
  <ThemeProvider theme={{ primary: props.rank === 1 }}>
    <Card>
      <a href={props.path}>
        <AdultWrapper>
          <Image src={props.image} alt={props.title} />
          {props.adult
            && <Adult>19</Adult>
          }
        </AdultWrapper>
        <Rank>
          <RankText>
            RANK
          </RankText>
          {props.rank}
        </Rank>
        <Heading>
          <Title>
            {props.title}
          </Title>
          {props.rentable
            && <StyledGiftCouponIcon />
          }
        </Heading>
        <Description>{props.genres}</Description>
        <Media query={mediaQuery.mobile}>
          {matches => (
            (matches || props.rank === 1) && <Synopsis>{props.synopsis}</Synopsis>
          )}
        </Media>
      </a>
    </Card>
  </ThemeProvider>
);

RankingCard.propTypes = {
  title: PropTypes.string.isRequired,
  genres: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  synopsis: PropTypes.string,
  path: PropTypes.string,
  rentable: PropTypes.bool,
  adult: PropTypes.bool.isRequired
};

export default RankingCard;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';
import ListHeader from 'components/atoms/ListHeader';

import FacebookIcon from 'images/ic-login-fb.svg';
import KakaotalkIcon from 'images/ic-login-kakaotalk.svg';

import media from 'styles/media.js';
import { primaryButton, secondaryButton } from 'styles/button.js';
import { white } from 'styles/palette.js';

const Links = styled.section`
  display: flex;
  flex-flow: row wrap;
  margin-top: 8px;
  padding: 16px;
  background-color: ${white};
  ${media.desktop`
    max-width: 414px;
    margin: 80px auto 40px;
    padding: 32px;
  `}
`;

const EmailLink = styled.a`
  ${primaryButton}
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
`;

const SNSLink = styled.a`
  ${secondaryButton}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
  font-weight: bold;
  svg {
    margin-right: 8px;
  }
`;

const SignUpLinks = props => (
  <Links>
    <GrayBackgroundStyle />
    <ListHeader
      title={props.title}
      Oswald
    />
    <EmailLink href={props.paths.email}>
      이메일로 회원가입
    </EmailLink>
    <SNSLink href={props.paths.facebook}>
      <FacebookIcon />
      페이스북 회원가입
    </SNSLink>
    <SNSLink href={props.paths.kakaotalk}>
      <KakaotalkIcon />
      카카오 회원가입
    </SNSLink>
  </Links>
);

SignUpLinks.propTypes = {
  title: PropTypes.string.isRequired,
  paths: PropTypes.object.isRequired
};

export default SignUpLinks;

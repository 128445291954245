import React from 'react';
import ReactDOM from 'react-dom';

import { createGlobalStyle } from 'styled-components';

import NanumSquareRegularWoff2 from '../fonts/NanumSquareRegular.woff2';
import NanumSquareRegularWoff from '../fonts/NanumSquareRegular.woff';
import NanumSquareExtraBoldWoff2 from '../fonts/NanumSquareExtraBold.woff2';
import NanumSquareExtraBoldWoff from '../fonts/NanumSquareExtraBold.woff';

import {
  NanumSquareRegularBase64,
  NanumSquareExtraBoldBase64,
  OswaldBase64
} from '../fonts/base64.js';

import OswaldWoff2 from '../fonts/Oswald-Medium.woff2';
import OswaldWoff from '../fonts/Oswald-Medium.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Nanum Square';
    font-weight: normal;
    src:
      url('${NanumSquareRegularWoff2}') format('woff2'),
      url('${NanumSquareRegularWoff}') format('woff'),
      url('${NanumSquareRegularBase64}') format('woff');
  }

  @font-face {
    font-family: 'Nanum Square';
    font-weight: bold;
    src:
      url('${NanumSquareExtraBoldWoff2}') format('woff2'),
      url('${NanumSquareExtraBoldWoff}') format('woff'),
      url('${NanumSquareExtraBoldBase64}') format('woff');
  }

  @font-face {
    font-family: Oswald;
    font-weight: bold;
    unicode-range: U+0020-00FF, U+20XX;
    src:
      url('${OswaldWoff2}') format('woff2'),
      url('${OswaldWoff}') format('woff'),
      url('${OswaldBase64}') format('woff');
  }

  html {
    height: 100%;
  }

  body {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    font-family: 'Nanum Square', sans-serif;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    display: flex;
    flex: 1 0 auto;
    flex-flow: column;
    min-height: 1px;
    & > * {
      min-height: 1px;
    }
  }

  [data-react-class] {
    flex: 0 0 auto;
  }

  .component-wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-flow: column;
    min-height: 1px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }

  img,
  picture {
    max-width: 100%;
    margin: 0;
  }

  button {
    -webkit-appearance: none;
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: none;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;

const element = document.createElement('div');
ReactDOM.render(<GlobalStyle />, element);

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import ListHeader from 'components/atoms/ListHeader';
import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';

import media from 'styles/media.js';
import { primaryButton } from 'styles/button.js';
import { white } from 'styles/palette.js';

const Container = styled.section`
  display: flex;
  flex-flow: row wrap;
  margin-top: 8px;
  padding: 16px;
  background-color: ${white};
  ${media.desktop`
    max-width: 414px;
    margin: 80px auto 40px;
    padding: 32px;
  `}
`;

const Form = styled.form`
  ${props => props.center && 'text-align: center;'}
  width: 100%;
  flex: 1 0 auto;
`;

const Link = styled.a`
  ${primaryButton}
  display: block;
  margin-top: 24px;
  ${media.desktop`
    margin-top: 32px;
  `}
`;

const Welcome = ({ redirectPath }) => (
  <Container>
    <GrayBackgroundStyle />
    <Form center>
      <ListHeader title='COMPLETED' Oswald />
      회원가입이 완료되었습니다.
      <Link href={redirectPath}>
        확인
      </Link>
    </Form>
  </Container>
);

Welcome.propTypes = {
  redirectPath: PropTypes.string
};

Welcome.defaultProps = {
  redirectPath: '/'
};

export default Welcome;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import {
  gray, white, red
} from 'styles/palette.js';
import ellipsis from 'styles/ellipsis.js';
import media from 'styles/media.js';

import SnackbarIcon from 'images/btn-snackbar.svg';

const Bar = styled.a`
  display: flex;
  position: fixed;
  right: 16px;
  bottom: 16px;
  left: 16px;
  align-items: center;
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
  opacity: .95;
  background-color: ${white};
`;

const Thumbnail = styled.img`
  flex: 0 0 auto;
  width: 52px;
  height: 52px;
  border-radius: 50%;
`;

const ComicInfo = styled.div`
  flex: 1 1 auto;
  margin: auto 12px;
  font-size: 13px;
  overflow: hidden;
`;

const ComicTitle = styled.p`
  ${ellipsis}
  margin-bottom: 4px;
  font-weight: bold;
`;

const RedText = styled.span`
  color: ${red};
`;

const Title = styled.p`
  ${ellipsis}
  color: ${gray};
`;

const StyledSnackbarButton = styled(SnackbarIcon)`
  margin: 0 6px;
`;

const RecentlyViewedEpisodeBar = ({
  title, path, thumbnail, comicTitle
}) => (
  <Bar href={path}>
    <Thumbnail src={thumbnail} />
    <ComicInfo>
      <ComicTitle>
        <RedText>최근 본 작품 </RedText>
        {comicTitle}
      </ComicTitle>
      <Title>
        {title}
      </Title>
    </ComicInfo>
    <StyledSnackbarButton />
  </Bar>
);

RecentlyViewedEpisodeBar.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  comicTitle: PropTypes.string.isRequired
};

export default RecentlyViewedEpisodeBar;

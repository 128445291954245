import React from 'react';
import PropTypes from 'prop-types';

const FoldableItem = props => (
  <React.Fragment>
    {props.outside}
    {props.isOpened && props.inside}
  </React.Fragment>
);

FoldableItem.propTypes = {
  isOpened: PropTypes.bool,
  outside: PropTypes.node.isRequired,
  inside: PropTypes.node.isRequired
};

FoldableItem.defaultProps = {
  isOpened: false
};

export default React.memo(FoldableItem);

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { black, lightGray, red } from 'styles/palette.js';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

const Label = styled.label`
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  line-height: 1.6;
  text-align: left;
  color: ${black};
  &::placeholder {
    color: ${lightGray};
  }
`;

const ErrorMessage = styled.p`
  margin-top: 8px;
  color: ${red};
  font-size: 14px;
`;

const InputField = props => (
  <Wrapper>
    {props.label && (
      <Label htmlFor={props.name}>
        {props.label}
      </Label>
    )}
    <Input id={props.name} {...props} />
    {props.error && (
      <ErrorMessage>
        {props.error}
      </ErrorMessage>
    )}
  </Wrapper>
);

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired
};

InputField.defaultProps = {
  type: 'text'
};

export default React.memo(InputField);

import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ItemCounter from 'components/atoms/ItemCounter';
import PresentItem from 'components/molecules/PresentItem';
import PresentComicCard from 'components/molecules/PresentComicCard';
import FoldableItem from 'components/organisms/FoldableItem';

import GiftCouponIcon from 'images/ic-giftcoupon.svg';
import QuestionIcon from 'images/ic-question.svg';

import Oswald from 'styles/Oswald.js';
import media, { mediaQuery } from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import {
  black,
  gray,
  lightGray,
  white,
  freeGreen
} from 'styles/palette.js';

const Container = styled.section`
  ${gridContainer}
  margin-top: 24px;
  ${media.desktop`
    margin-top: 12px;
  `}
`;

const DesktopLeft = React.memo(styled.div`
  ${gridItem(4)}
  display: flex;
  flex-flow: column;
`, (() => true));

const DesktopRight = React.memo(styled.div`
  ${gridItem(8)}
  display: flex;
  flex-flow: column;
`);

const Heading = React.memo(styled.div`
  ${media.mobile(gridItem(12))}
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  font-weight: bold;
  ${media.mobile`
    justify-content: center;
  `}
  ${media.desktop`
    ${props => props.center && 'justify-content: center;'}
    ${props => props.border && `border-bottom: 3px solid ${black};`}
  `}
`);

const Title = React.memo(styled.h1`
  font-weight: bold;
  ${media.mobile`
    font-size: 20px;
  `}
  ${media.desktop`
    font-size: 22px;
  `}
`);

const ToolTip = React.memo(styled.div`
  display: flex;
  position: relative;
  margin-left: 4px;
  cursor: pointer;
`);

const ToolTipContents = React.memo(styled.div`
  display: none;
  ${ToolTip.type}:hover & {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 260px;
    margin-top: 12px;
    padding: 16px;
    border-radius: 2px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, .5), 0 1px 2px 0 rgba(0, 0, 0, .4);
    background-color: ${white};
    opacity: .97;
    transform: translateX(-50%);
    color: ${gray};
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }
`);

const Em = React.memo(styled.em`
  color: ${freeGreen};
  font-weight: bold;
  font-style: normal;
`);

const StyledItemCounter = React.memo(styled(ItemCounter)`
  ${media.mobile(gridItem(12))}
`);

const ViewMoreButton = React.memo(styled.button`
  ${media.mobile(gridItem(12))}
  padding: 16px 0;
  color: ${gray};
  font-size: 13px;
  ${media.desktop`
    font-size: 16px;
    line-height: 1.6;
  `}
`);

const StyledPresentItem = React.memo(styled(PresentItem)`
  ${media.mobile(gridItem(12))}
  ${props => ((props.expired || props.count === 0) ? `color: ${lightGray};` : 'cursor: pointer;')}
  border-top: 1px solid ${lightGray};
`);

const PureGiftCouponIcon = React.memo(GiftCouponIcon);

const StyledPresentComicCard = React.memo(styled(PresentComicCard)`
  ${media.mobile(gridItem(12))}
  margin-bottom: 12px;
  ${media.desktop`
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 32px;
    }
  `}
`);

const Coupons = React.memo(styled.span`
  display: flex;
  align-items: center;
  color: ${props => (props.expired ? lightGray : freeGreen)};
  font-weight: bold;
  font-size: 13px;
  white-space: pre-wrap;
  ${media.desktop`
    font-size: 20px;
  `}
`);

const CouponsCount = React.memo(styled.span`
  ${Oswald}
  font-size: 14px;
  ${media.desktop`
    font-size: 26px;
  `}
`);

const SmallCouponIcon = React.memo(styled(GiftCouponIcon)`
  width: 20px;
  path {
    fill: currentColor;
  }
  ${media.desktop`
    width: 30px;
  `}
`);

const PresentsTemplate = props => (
  <Media query={mediaQuery.desktop}>
    {matches => (matches
      ? (
        <Container>
          <DesktopLeft>
            <Heading>
              <Title>
                이달의 무료대여권 선물
              </Title>
              <ToolTip>
                <QuestionIcon />
                <ToolTipContents>
                  <Em>무료대여권</Em>
                  을 이용하여 유료 에피소드를 24시간 동안 무료로 볼 수 있습니다.
                </ToolTipContents>
              </ToolTip>
            </Heading>
            <StyledItemCounter
              image={
                <PureGiftCouponIcon />
              }
              count={props.presentsCount}
            />
          </DesktopLeft>
          <DesktopRight>
            <Heading center border>
              <Title>
                {props.title}
              </Title>
            </Heading>
            {props.isHidingExpiredItems && (
              <ViewMoreButton
                onClick={props.renderExpiredItems}
              >
                + 지난 날짜 선물보기
              </ViewMoreButton>
            )}
            {props.presents.map(({ comics, ...date }) => (
              <FoldableItem
                key={date.title}
                isOpened={props.openedItem === date.title}
                outside={(
                  <StyledPresentItem
                    {...date}
                    count={comics.reduce((sum, comic) => sum + comic.count, 0)}
                    isOpened={props.openedItem === date.title}
                    onClick={() => comics.length && props.openItem(date.title)}
                  />
                )}
                inside={comics.map(comic => (
                  <StyledPresentComicCard
                    {...comic}
                    key={comic.title}
                  >
                    <Coupons
                      expired={date.expired}
                    >
                      {date.expired
                        ? '만료  '
                        : !date.today
                        && '예정  '
                      }
                      <SmallCouponIcon />
                      <CouponsCount>
                        {` × ${comic.count}`}
                      </CouponsCount>
                    </Coupons>
                  </StyledPresentComicCard>
                ))}
              />
            ))}
          </DesktopRight>
        </Container>
      ) : (
        <Container>
          <Heading>
            <Title>
              {props.title}
            </Title>
          </Heading>
          <StyledItemCounter
            image={
              <PureGiftCouponIcon />
            }
            count={props.presentsCount}
          />
          {props.isHidingExpiredItems && (
            <ViewMoreButton
              onClick={props.renderExpiredItems}
            >
              + 지난 날짜 선물보기
            </ViewMoreButton>
          )}
          {props.presents.map(({ comics, ...date }) => (
            <FoldableItem
              key={date.title}
              isOpened={props.openedItem === date.title}
              outside={(
                <StyledPresentItem
                  {...date}
                  count={comics.reduce((sum, comic) => sum + comic.count, 0)}
                  isOpened={props.openedItem === date.title}
                  onClick={() => comics.length && props.openItem(date.title)}
                />
              )}
              inside={comics.map(comic => (
                <StyledPresentComicCard
                  {...comic}
                  key={comic.title}
                >
                  <Coupons
                    expired={date.expired}
                  >
                    <SmallCouponIcon />
                    <CouponsCount>
                      {` × ${comic.count}`}
                    </CouponsCount>
                    {date.expired
                      ? ' 만료'
                      : !date.today
                      && ' 예정'
                    }
                  </Coupons>
                </StyledPresentComicCard>
              ))}
            />
          ))}
        </Container>
      )
    )}
  </Media>
);

PresentsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  presents: PropTypes.array.isRequired,
  presentsCount: PropTypes.number.isRequired,
  isHidingExpiredItems: PropTypes.bool.isRequired,
  renderExpiredItems: PropTypes.func.isRequired,
  openItem: PropTypes.func.isRequired,
  openedItem: PropTypes.string.isRequired
};

export default PresentsTemplate;

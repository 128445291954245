import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Media from 'react-media';

import ListHeader from 'components/atoms/ListHeader';
import MoreButton from 'components/atoms/MoreButton';

import ComicCard from 'components/molecules/ComicCard';

import media, { mediaQuery } from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { primaryButton, secondaryButton } from 'styles/button.js';

const List = styled.section`
  ${gridContainer}
  display: flex;
  flex-flow: row wrap;
  margin-top: 24px;
  margin-bottom: 24px;
  ${media.desktop`
    margin-top: 32px;
    margin-bottom: 32px;
  `}
`;

const Keywords = styled.div`
  ${gridItem(12)};
  display: flex;
  flex-flow: row;
  margin-bottom: 16px;
  ${media.mobile`
    justify-content: left;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  `}
  ${media.desktop`
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
  `}
`;

const ComicCards = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

const Keyword = styled.button`
  ${props => (props.active ? primaryButton : secondaryButton)};
  margin: 0 2px 8px;
  padding: 12px;
  font-size: 13px;
  font-weight: normal;

  &::before {
    content: '#';
  }

  ${media.desktop`
    margin: 0 5px 8px;
    padding: 16px;
    font-size: 20px;
  `}
`;

export default class KeywordList extends React.Component {
  state = {
    index: 0
  }

  handleIndex = (index) => {
    if (index !== this.state.index) {
      this.setState({ index });
    }
  }

  render = () => (
    <List>
      <ListHeader
        title={this.props.title}
        subtitle={this.props.subtitle}
        Oswald
      />
      <Keywords>
        {this.props.keywords.map((keyword, index) => (
          <Keyword
            key={keyword.keyword}
            onClick={() => this.handleIndex(index)}
            active={index === this.state.index}
          >
            {keyword.keyword}
          </Keyword>
        ))}
      </Keywords>
      <Media query={mediaQuery.desktop}>
        {matches => (
          <React.Fragment>
            <ComicCards>
              {this.props.keywords[this.state.index].comics.slice(0, matches ? 6 : 4)
                .map(comic => (
                  <ComicCard {...comic} key={comic.title} />
                ))
              }
            </ComicCards>
            {this.props.keywords[this.state.index].comics.length >= (matches ? 6 : 4)
              && <MoreButton path={this.props.keywords[this.state.index].path} />
            }
          </React.Fragment>
        )}
      </Media>
    </List>
  )
}

KeywordList.propTypes = {
  keywords: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from 'components/atoms/Container';
import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';
import ListHeader from 'components/atoms/ListHeader';
import { primaryButton } from 'styles/button.js';
import { black } from 'styles/palette.js';

import AuthenticityToken from 'components/atoms/AuthenticityToken';

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  width: 100%;
`;

const Message = styled.p`
  margin-bottom: 1em;
  line-height: 1.5;
  color: ${black};
`;

const Link = styled.a`
  color: red;
`;

export default class AccountMaximkoreaForm extends React.Component {
  static propTypes = {
    email_id: PropTypes.string,
    maxim_id: PropTypes.string.isRequired,
    omni_auth_identities: PropTypes.array
  }

  email = () => {
    const regex = /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i;
    if (this.props.email_id.match(regex)) {
      return this.props.email_id;
    }
    return '';
  }

  render = () => (
    <Container>
      <GrayBackgroundStyle />
      <ListHeader title='통합 계정 전환 안내' Oswald />
      <form action='/my/account/maximkorea' method='post'>
        <AuthenticityToken />
        <Message>
          맥심코리아에서 사용하시던 계정 정보 그대로 맥심코믹스에서 이용하실 수 있습니다.
        </Message>
        <Message>
          아래에서 동의를 선택하면 맥심코믹스로의 개인정보 이전, 맥심코리아
          {' '}
          <Link href='http://www.maximkorea.net/main2/sb_about7.php' target='_blank'>서비스 이용약관 및 개인정보 수집 및 이용</Link>
, 맥심코믹스의
          {' '}
          <Link href='/helps/terms' target='_blank'>서비스 이용약관</Link>
          {' '}

          {' '}
          <Link href='/helps/privacies' target='_blank'>개인정보 수집 및 이용</Link>
에 동의하게 됩니다.
        </Message>
        <input type='hidden' name='email' value={this.email()} />
        <input type='hidden' name='maxim_id' value={this.props.maxim_id} />
        <input type='hidden' name='omni_auth_identities' value={[this.props.omni_auth_identities]} />
        <Submit name='동의' value='동의' />
      </form>
    </Container>
  )
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import axios from 'axios';

import Rails from 'rails-ujs';

import media from 'styles/media.js';
import {
  black, lightGray, white, red
} from 'styles/palette.js';

import { primaryButton, secondaryButton } from 'styles/button.js';
import * as Popup from 'styles/popup.js';

const UnregisterForm = styled.form`
  width: 100%;
  margin-top: 24px;
  padding: 20px 12px;
  border: 4px solid black;
  ${media.desktop`
    padding: 28px 20px;
  `}
`;

const OnUnregisterButton = styled.button.attrs({
  type: 'button'
})`
  ${primaryButton}
  width: 100%;
`;

const UnregisterTitle = styled.h3`
  width: 100%;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  ${media.desktop`
    font-size: 22px;
    margin-bottom: 24px;
  `}
`;

const UnregisterText = styled.p`
  margin-bottom: 16px;
  line-height: 1.6;
  ${props => props.bold && 'font-weight: bold;'}
`;

const UnregisterLabel = styled.label`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;
const Radio = styled.input.attrs({
  type: 'radio'
})`
  display: none;
`;

const RadioButton = styled.div`
  width: 18px;
  height: 18px;
  margin: 4px 12px 4px 4px;
  border: 4px solid ${white};
  border-radius: 50%;
  box-shadow: 0 0 0 3px ${lightGray};
  ${Radio}:checked + & {
    background-color: ${red};
  }
`;

const UnregisterInput = styled.input`
  width: 100%;
  margin-bottom: 16px;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  background-color: ${white};
  line-height: 1.6;
  text-align: left;
  &::placeholder {
    color: ${lightGray};
  }
`;

const UnregisterButton = styled.button.attrs({
  type: 'button'
})`
  ${secondaryButton}
  width: 100%;
  margin-top: 4px;
`;

const unregisterReasons = [
  '만족스럽지 못한 콘텐츠',
  '잦은 시스템 장애',
  '높은 작품 구매 가격',
  '다른 웹툰 서비스로의 이동'
];

export default class extends React.Component {
  static propTypes = {
    path: PropTypes.string.isRequired
  }

  state = {
    showConfirm: false,
    reason: '',
    password: ''
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.togglePopup();
  }

  togglePopup = () => {
    if (this.state.showConfirm) {
      this.setState({ showConfirm: false });
      document.querySelector('body').style.overflow = null;
    } else {
      this.setState({ showConfirm: true });
      document.querySelector('body').style.overflow = 'hidden';
    }
  }

  handlePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  handleReason = (event) => {
    this.setState({ reason: event.target.value });
  }

  isValid = () => this.state.reason

  handleUnregister = (event) => {
    event.preventDefault();
    axios({
      method: 'delete',
      url: this.props.path,
      data: {
        reason: this.state.reason,
        password: this.state.password
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => {
      this.setState(() => ({
        errorMessage: error.response.data.message,
        errorCause: error.response.data.cause
      }));
    });
  }

  render = () => (
    <div>
      <UnregisterForm>
        <UnregisterTitle>계정 탈퇴</UnregisterTitle>
        <UnregisterText>탈퇴 사유를 알려주세요. 더욱 발전하는 맥심코믹스가 되겠습니다.</UnregisterText>
        {unregisterReasons.map(reason => (
          <UnregisterLabel htmlFor={reason} key={reason}>
            <Radio id={reason} value={reason} name='unregisterReason' onChange={this.handleReason} />
            <RadioButton />
            {reason}
          </UnregisterLabel>
        ))}
        <UnregisterLabel htmlFor='etc'>
          <Radio id='etc' value='etc' name='unregisterReason' onChange={this.handleReason} />
          <RadioButton />
          기타
        </UnregisterLabel>
        <UnregisterInput
          type='text'
          name='unregister_reason_etc'
          autoComplete='yes'
          placeholder='기타 사유'
          onChange={this.handleReason}
        />
        <UnregisterText bold>
          계정 탈퇴 시, 보유 코인과 보너스 코인, 구매 콘텐츠 정보가 모두 사라집니다.
          <br />
          정말로 탈퇴하시겠습니까?
        </UnregisterText>
        <OnUnregisterButton disabled={!this.isValid()} onClick={this.onSubmit}>탈퇴</OnUnregisterButton>
      </UnregisterForm>
      {this.state.showConfirm
      && (
        <Popup.Background onClick={this.togglePopup}>
          <Popup.Popup onSubmit={this.handleUnregister}>
            <Popup.Title>정말로 탈퇴하시겠습니까?</Popup.Title>
            <OnUnregisterButton onClick={this.togglePopup}>취소</OnUnregisterButton>
            <UnregisterButton onClick={this.handleUnregister}>탈퇴</UnregisterButton>
          </Popup.Popup>
        </Popup.Background>
      )
      }
    </div>
  )
}

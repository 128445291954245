import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import AuthenticityToken from 'components/atoms/AuthenticityToken';
import ShopRadioButton from 'components/atoms/ShopRadioButton';
import MoreButton from 'components/atoms/MoreButton';
import CouponItem from 'components/molecules/CouponItem';
import CoinWallet from 'components/molecules/CoinWallet';

import ShopBanner from 'components/atoms/ShopBanner';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { primaryButton } from 'styles/button.js';
import {
  backgroundGray, red
} from 'styles/palette.js';
import { gridContainer, gridItem, gridCollapse } from 'styles/grid.js';

import ismobile from 'utils/ismobile';

const Form = styled.form`
  ${gridContainer}
  margin-top: 12px;
  margin-bottom: 24px;
  ${media.desktop`
    margin-top: 0;
    margin-bottom: 32px;
    justify-content: flex-end;
  `}
`;

const StyledMoreButton = styled(MoreButton)`
  margin-top: 12px;
`;

const Section = styled.section`
  ${gridItem(12, 8)}
  margin-top: 32px;
  text-align: center;
  ${media.desktop`
    margin-right: 20px;
  `}
`;

const SectionWrapper = styled.div`
  ${media.desktop`
    ${gridContainer('desktop')}
    ${gridCollapse('desktop')}
  `}
`;

const SectionHeading = styled.h2`
  ${gridItem(12)}
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  ${media.desktop`
    font-size: 22px;
  `}
`;

const CoinSection = styled.aside`
  ${gridItem(12, 4)}
  ${media.desktop`
    margin-top: 32px;
  `}
`;

const LoginHeading = styled.p`
  font-size: 20px;
  ${media.mobile`
    display: none;
  `}
`;

const EmptyCoupon = styled.p`
  font-size: 20px;
  width: 100%;
  margin-bottom: 8px;
  ${media.mobile`
    font-size: 14px;
  `}
`;

const ProductName = styled.span`
  text-align: left;
  margin-left: 4px;
  line-height: 1;
`;

const ProductEventMessage = styled.p`
  color: ${red};
  font-size: 13px;
  margin-top: 8px;
`;

const ProductPrice = styled.span`
  ${Oswald}
  margin-left: auto;
  font-size: 18px;
`;

const Acknowledgement = styled.ul`
  ${gridItem(12, 8)}
  padding-left: 16px;
  font-size: 14px;
  line-height: 1.6;
  ${media.desktop`
    margin-right: 20px;
  `}
`;

const TotalPrice = styled.div`
  display: flex;
  flex-flow: column;
  padding: 16px;
  background-color: ${backgroundGray};
  text-align: center;
  ${media.desktop`
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  `}
`;

const TotalPriceTitle = styled.h2`
  font-size: 14px;
  ${media.mobile`
    margin-bottom: 4px;
  `}
`;

const TotalPriceValue = styled.div`
  ${Oswald}
  font-size: 20px;
  ${media.desktop`
    text-align: right;
  `}
`;

const TotalPriceSale = styled.p`
  margin-top: 4px;
  color: ${red};
  font-size: 14px;
`;

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: center;
`;

const Token = styled.input.attrs({
  type: 'hidden'
})``;

const Product = styled.input.attrs({
  type: 'hidden'
})``;

const Payment = styled.input.attrs({
  type: 'hidden'
})``;

const Coupon = styled.input.attrs({
  type: 'hidden'
})``;

export default class ShopForm extends React.Component {
  static propTypes = {
    currentUser: PropTypes.shape({
      logIn: PropTypes.bool.isRequired,
      coin: PropTypes.number.isRequired
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      event: PropTypes.bool.isRequired,
      eventMessage: PropTypes.string,
      default: PropTypes.bool.isRequired
    })).isRequired,
    payments: PropTypes.array.isRequired,
    coupons: PropTypes.array.isRequired,
    banner: PropTypes.object,
    path: PropTypes.string.isRequired,
    couponPath: PropTypes.string.isRequired,
    loginPath: PropTypes.string.isRequired,
    shopPath: PropTypes.string.isRequire
  };

  constructor(props) {
    super(props);
    const productId = props.products.find(product => product.default).id;
    const coupons = props.coupons.filter(coupon => coupon.productIds.includes(productId));

    this.state = {
      productId,
      coupons,
      originalPrice: props.products.find(product => product.default).price,
      totalPrice: props.products.find(product => product.default).price,
      paymentMethod: props.payments[0].value
    };
  }

  state = {
  }

  handleSubmit = (event) => {
    if (!ismobile()) {
      window.open('about:blank', 'payment', 'top=100,left=100,width=600,height=726,scrollbars=yes,resizable=yes,toolbar=no,menubar=no');
      // eslint-disable-next-line no-param-reassign
      event.target.target = 'payment';
    }
  }

  handleProduct = (price, id) => {
    const coupons = this.props.coupons.filter(coupon => coupon.productIds.includes(id));

    this.setState(() => ({
      originalPrice: price, totalPrice: price, productId: id, coupons
    }), () => {
      this.handleCoupon(null);
    });
  }

  handlePayment = (value) => {
    this.setState(() => ({ paymentMethod: value }));
  }

  handleCoupon = (coupon) => {
    this.setState(() => ({ coupon }));

    let totalPrice = this.state.originalPrice;

    if (coupon && coupon.discountPercentage) {
      totalPrice *= 1 - (coupon.discountPercentage / 100);
    }

    if (coupon && coupon.discountPrice) {
      totalPrice -= coupon.discountPrice;
    }

    this.setState(() => ({ totalPrice }));
  }

  renderProducts = () => (
    this.props.products.map(product => (
      <ShopRadioButton
        key={product.price}
        name='product'
        checked={product.id === this.state.productId}
        onChange={() => this.handleProduct(product.price, product.id)}
      >
        <ProductName>
          {product.name}
          {product.event
            && (
            <ProductEventMessage>
              {product.eventMessage}
            </ProductEventMessage>
            )
          }
        </ProductName>
        <ProductPrice>
          ₩
          {product.price.toLocaleString()}
        </ProductPrice>
      </ShopRadioButton>
    ))
  )

  renderPayments = () => (
    this.props.payments.map(payment => (
      <ShopRadioButton
        key={payment.value}
        name='payment'
        checked={payment.value === this.state.paymentMethod}
        onChange={() => this.handlePayment(payment.value)}
        half
      >
        {payment.name}
      </ShopRadioButton>
    ))
  )

  renderNoneCoupon = () => (
    <ShopRadioButton
      key='0'
      name='coupon'
      checked={!this.state.coupon}
      onChange={() => this.handleCoupon(null)}
    >
      쿠폰 선택하지 않음
    </ShopRadioButton>
  )

  renderCoupons = () => (
    this.props.coupons.map(coupon => (
      (coupon.productIds && !coupon.productIds.includes(this.state.productId)) ? ('') : (
        <ShopRadioButton
          key={coupon.id}
          name='coupon'
          checked={!!this.state.coupon && coupon.id === this.state.coupon.id}
          onChange={() => this.handleCoupon(coupon)}
        >
          <CouponItem
            id={coupon.id}
            exchangeCoin={coupon.exchangeCoin}
            discountPrice={coupon.discountPrice}
            discountPercentage={coupon.discountPercentage}
            paybackBonuscoinPercentage={coupon.paybackBonuscoinPercentage}
            paybackBonuscoin={coupon.paybackBonuscoin}
            validity={coupon.validity}
          />
        </ShopRadioButton>
      )
    ))
  )

  render = () => (
    <Form onSubmit={this.handleSubmit} action={this.props.path} method='POST'>
      <AuthenticityToken />
      <Product name='product_id' value={this.state.productId} />
      <Payment name='payment_method' value={this.state.paymentMethod} />
      {this.state.coupon
        && <Coupon name='coupon_id' value={this.state.coupon.id} />
      }
      {this.props.currentUser.logIn
        ? (
          <CoinSection>
            <CoinWallet coin={this.props.currentUser.coin} />
            {this.props.banner && (
              <ShopBanner src={this.props.banner.image} />
            )}
          </CoinSection>
        )
        : (
          <CoinSection>
            <LoginHeading>
              현재 비로그인 상태입니다.
            </LoginHeading>
            <Submit
              as='a'
              href={`${this.props.loginPath}?redirect_to=${this.props.shopPath}`}
            >
              로그인
            </Submit>
          </CoinSection>
        )
      }
      <Section>
        <SectionWrapper>
          <SectionHeading>코인 상품 선택</SectionHeading>
          {this.renderProducts()}
        </SectionWrapper>
      </Section>
      <Section>
        <SectionWrapper>
          <SectionHeading>쿠폰 선택</SectionHeading>
          {this.state.coupons.length > 0
            ? (
              <React.Fragment>
                {this.renderNoneCoupon()}
                {this.renderCoupons()}
              </React.Fragment>
            )
            : (
              <EmptyCoupon>
              해당 상품에 적용 가능한 쿠폰이 없습니다.
              </EmptyCoupon>
            )
          }
          <StyledMoreButton path={this.props.couponPath} text='쿠폰관리' Oswald={false} />
        </SectionWrapper>
      </Section>
      <Section>
        <SectionWrapper>
          <SectionHeading>결제 수단 선택</SectionHeading>
          {this.renderPayments()}
        </SectionWrapper>
      </Section>
      <Acknowledgement>
        <li>
          현재 &quot;SKT알뜰폰&quot; 결제 수단은 서비스 준비중입니다. (추후 개선될 예정이니 양해 부탁드립니다.)
        </li>
        <li>
          쿠폰은 지정된 코인 충전 상품에만 사용 가능하며, 일부 코인 충전 상품에서는 사용이 불가능할 수 있습니다.
        </li>
        <li>
          보너스코인은 맥심코믹스에서 진행하는 이벤트 및 혜택을 통해 무상으로 지급되는 결제수단입니다.
        </li>
        <li>
          보너스코인은 사용기간, 사용대상(작품), 사용가능 기기에 제한이 있을 수 있습니다.
        </li>
        <li>
          코인 및 보너스코인 적립내역은 내 정보 메뉴 &gt; 코인 내역 섹션에서 확인 가능합니다.
        </li>
      </Acknowledgement>
      <Section>
        <TotalPrice>
          <TotalPriceTitle>
            총 결제 금액
          </TotalPriceTitle>
          <TotalPriceValue>
            ₩
            {this.state.totalPrice.toLocaleString()}
            {this.state.coupon
              && (!!this.state.coupon.discountPercentage || !!this.state.coupon.discountPrice)
                && (
                <TotalPriceSale>
                  쿠폰 할인 적용
                </TotalPriceSale>
                )
            }
          </TotalPriceValue>
        </TotalPrice>
        {this.props.currentUser.logIn
          ? <Submit value='결제하기' />
          : (
            <Submit
              as='a'
              href={`${this.props.loginPath}?redirect_to=${this.props.shopPath}`}
            >
              결제하기
            </Submit>
          )
        }
      </Section>
    </Form>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import ComicCard from 'components/molecules/ComicCard';

import media from 'styles/media.js';
import { gridContainer } from 'styles/grid.js';
import { white } from 'styles/palette.js';

const List = styled.section`
  background-color: rgba(0, 0, 0, .9);
`;

const Wrapper = styled.div`
  ${gridContainer}
  padding: 24px 0 8px;
  color: ${white};
  ${media.desktop`
    padding: 32px 0 12px;
  `}
`;

const isComicsEqual = (prevProps, nextProps) => (
  JSON.stringify(prevProps.comics) === JSON.stringify(nextProps.comics)
);

const FeaturedComicCardList = React.memo(({ header, comics }) => (
  <List>
    <Wrapper>
      {header}
      {comics.map(comic => (
        <ComicCard
          {...comic}
          key={comic.title}
          grid={{
            mobile: 6,
            desktop: 3
          }}
        />
      ))}
    </Wrapper>
  </List>
), isComicsEqual);

FeaturedComicCardList.propTypes = {
  header: PropTypes.node,
  comics: PropTypes.array.isRequired
};

export default FeaturedComicCardList;

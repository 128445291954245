import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import ellipsis from 'styles/ellipsis.js';
import { gridItem } from 'styles/grid.js';
import {
  black, gray, white, red
} from 'styles/palette.js';

const Item = styled.a`
  ${gridItem(null, 5)}
  display: flex;
  position: relative;
  margin-bottom: 8px;
  align-items: flex-end;
  justify-content: space-between;
  ${media.mobile`
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), ${black});
    }
  `}
  ${media.desktop`
    flex-flow: row wrap;
  `}
`;

const Image = styled.img`
  width: 100%;
  align-self: start;
  ${media.desktop`
    margin-bottom: 20px;
  `}
`;

const Body = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-flow: row nowrap;
  align-items: flex-start;
  order: 1;
  width: 100%;
  ${media.mobile`
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 8px;
  `}
`;

const Rank = styled.div`
  ${Oswald}
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  font-size: 28px;
  ${media.mobile`
    flex: 0 0 66px;
  `}
  ${media.desktop`
    margin: 0 36px;
    color: ${red};
    font-size: 42px;
  `}
`;

const Text = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  ${media.mobile`
    height: 88px;
    color: ${white};
  `}
  ${media.desktop`
    margin-left: 16px;
  `}
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  ${media.desktop`
    margin-bottom: 10px;
    font-size: 20px;
  `}
`;

const TitleText = styled.span`
  ${ellipsis}
`;

const Adult = styled.span`
  ${Oswald}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: ${gray};
  color: ${white};
  font-size: 11px;
  text-align: center;
  line-height: 1;
  ${media.desktop`
    position: absolute;
    right: 4px;
    top: -32px;
    width: 28px;
    height: 28px;
    font-size: 14px;
    margin-top: 62.5%;
  `}
`;

const Description = styled.p`
  ${ellipsis}
  font-size: 13px;
  ${media.desktop`
    color: ${gray};
    font-size: 14px;
  `}
`;

const Synopsis = styled.p`
  margin-top: 8px;
  font-size: 13px;
  ${media.mobile`
    ${ellipsis}
  `}
  ${media.desktop`
    margin-top: 12px;
    font-size: 14px;
    line-height: 1.6;
  `}
`;

const FirstRankingItem = props => (
  <Item href={props.path}>
    <Image src={props.image} alt={props.title} />
    <Body>
      <Rank>
        {props.rank}
      </Rank>
      <Text>
        <Title>
          <TitleText>
            {props.title}
          </TitleText>
          {props.adult && <Adult>19</Adult>}
        </Title>
        <Description>{props.genres}</Description>
        <Synopsis>
          {props.synopsis}
        </Synopsis>
      </Text>
    </Body>
  </Item>
);

FirstRankingItem.propTypes = {
  title: PropTypes.string.isRequired,
  genres: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  path: PropTypes.string,
  synopsis: PropTypes.string,
  adult: PropTypes.bool
};

export default FirstRankingItem;

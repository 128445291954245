import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'styles/media.js';
import { red } from 'styles/palette.js';

function Synopsis({ content, truncatedLength = 120 }) {
  const [truncated, setTruncated] = useState(content.length > truncatedLength);

  const SynopsisWrapper = styled.p`
    display: inline-block;
    font-size: 14px;
    line-height: 1.6;
    text-align: left;
    ${media.desktop`
      margin-top: 8px;
      font-size: 16px;
    `}
    ${truncated && 'cursor: pointer'}
  `;

  const More = styled.span`
    color: ${red};
    font-size: 14px;
  `;

  return truncated ? (
    <SynopsisWrapper onClick={() => setTruncated(false)} truncated>
      {content.substring(0, truncatedLength)}
      {'...'}
      <More> + 더보기</More>
    </SynopsisWrapper>
  ) : (
    <SynopsisWrapper>{content}</SynopsisWrapper>
  );
}

Synopsis.propTypes = {
  content: PropTypes.string,
  truncatedLength: PropTypes.number
};

export default Synopsis;

import React from 'react';
import PropTypes from 'prop-types';

import CommonTemplate from 'components/templates/CommonTemplate';
import FeaturedComicCardList from 'components/organisms/FeaturedComicCardList';
import GroupedComicCardList from 'components/organisms/GroupedComicCardList';
import ListHeader from 'components/atoms/ListHeader';

import { mediaQuery } from 'styles/media.js';
import useMedia from 'components/hooks/useMedia';

const FeaturedComicsLength = {
  mobile: 2,
  desktop: 4
};

function GenresPage({
  layoutProps, recommendedComics, comics, genres
}) {
  const isDesktop = useMedia(mediaQuery.desktop);

  return (
    <CommonTemplate {...layoutProps}>
      <FeaturedComicCardList
        comics={recommendedComics.slice(0, (
          isDesktop ? FeaturedComicsLength.desktop : FeaturedComicsLength.mobile
        ))}
        header={(
          <ListHeader
            title='RECOMMEND'
            subtitle='맥심코믹스 에디터 추천작!'
            Oswald
          />
        )}
      />

      <GroupedComicCardList genres={genres} comics={comics} />
    </CommonTemplate>
  );
}

export default GenresPage;

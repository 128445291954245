import styled from 'styled-components';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { primaryButton, secondaryButton } from 'styles/button.js';
import {
  black, lightGray, white, red
} from 'styles/palette.js';

export const Title = styled.h2`
  ${props => (props.Oswald ? `
    ${Oswald()}
    font-size: 24px;
  ` : `
    font-size: 20px;
  `)}
  align-self: center;
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 1;
`;

export const Background = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  align-items: center;
  justify-content: center;
`;

export const Popup = styled.form`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 100%;
  max-width: 414px;
  margin: 0 16px;
  padding: 32px 16px 16px;
  background-color: ${white};
  ${media.desktop`
    padding: 32px 32px 16px;
  `}
`;

export const Label = styled.label`
  align-self: flex-start;
  margin-bottom: 8px;
`;

export const Input = styled.input.attrs({
  type: 'text',
  required: true
})`
  width: 100%;
  margin-bottom: 16px;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  line-height: 1.6;
  text-align: left;
  &::placeholder {
    color: ${lightGray};
  }
`;

export const ErrorMessage = styled.p`
  margin-bottom: 8px;
  color: ${red};
  font-size: 14px;
`;

export const Button = styled.button.attrs({
  type: 'button'
})`
  ${props => (props.primary ? primaryButton : secondaryButton)}
  width: 100%;
  margin-bottom: 4px;
  font-weight: bold;
`;

export const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  width: 100%;
  margin-bottom: 4px;
  border: 0;
  font-weight: bold;
`;

export const Close = styled.button.attrs({
  type: 'button'
})`
  margin: 16px 0;
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import ComicCard from 'components/molecules/ComicCard';

import media from 'styles/media.js';
import { gridContainer, gridItem, gridCollapse } from 'styles/grid.js';
import { primaryButton, secondaryButton } from 'styles/button.js';
import { white } from 'styles/palette.js';

const List = styled.section`
  ${gridContainer}
  align-items: flex-start;
  margin-bottom: 16px;
  ${media.desktop`
    margin-bottom: 12px;
  `}
`;

const Tabs = styled.div`
  ${gridItem(null, 12)}
  display: flex;
  flex: 1 0 100%;
  flex-flow: row nowrap;
  overflow-x: auto;
  ${media.mobile`
    ${gridCollapse}
    position: sticky;
    z-index: 1;
    top: 0;
    padding: 16px;
    background-color: ${white};
    touch-action: pan-x;
    -webkit-overflow-scrolling: touch;
    &::after {
      content: '';
      flex: 1 0 auto;
      width: 16px;
    }
  `}
  ${media.desktop`
    padding: 32px 0;
  `}
`;

const Tab = styled.button`
  ${props => (props.active ? primaryButton : secondaryButton)}
  margin-right: 4px;
  padding: 12px;
  font-size: 13px;
  &:last-of-type {
    margin-right: 0;
  }
  ${media.desktop`
    margin-right: 6px;
    padding: 16px;
    font-size: 20px;
  `}
`;

const Cards = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-flow: row wrap;
  width: 100%;
`;

function GroupedComicCardList({ comics, genres }) {
  const [currentGenre, setCurrentGenre] = useState('전체');

  const genreList = ['전체', '성인'].concat(genres);

  const filteredComics = comics.filter((comic) => {
    switch (currentGenre) {
      case '전체':
        return true;
      case '성인':
        return comic.adult;
      default:
        return comic.genres.includes(currentGenre);
    }
  });

  return (
    <List>
      <Tabs>
        {genreList.map(genre => (
          <Tab
            active={currentGenre === genre}
            onClick={() => setCurrentGenre(genre)}
            key={genre}
          >
            {genre}
          </Tab>
        ))}
      </Tabs>
      <Cards>
        {filteredComics.map(comic => (
          <ComicCard
            {...comic}
            key={comic.title}
            grid={{
              mobile: 6,
              desktop: (12 / 5)
            }}
          />
        ))}
      </Cards>
    </List>
  );
}

GroupedComicCardList.propTypes = {
  comics: PropTypes.arrayOf(PropTypes.shape({})),
  genres: PropTypes.array
};

export default GroupedComicCardList;

import React from 'react';
import styled from 'styled-components';

import Oswald from 'styles/Oswald.js';
import { primaryButton, secondaryButton } from 'styles/button.js';

const Notice = styled.p`
  ${props => (props.Oswald && `${Oswald()} font-size: 24px;`)}
  margin-bottom: 16px;
  line-height: 26px;
`;

const Button = styled.a`
  ${props => (props.primary ? primaryButton : secondaryButton)}
  display: block;
  width: 100%;
  margin-bottom: 4px;
  font-weight: bold;
`;

function Login({ signUpPath, loginPath }) {
  return (
    <React.Fragment>
      <Notice>이 이야기가 궁금하신가요?</Notice>
      <Notice>지금 회원가입 하시면 무료대여권을 바로 드립니다.</Notice>
      <Button primary href={signUpPath}>회원가입</Button>
      <Button href={loginPath}>로그인</Button>
    </React.Fragment>
  );
}

function NeedCertification({ certificationPath }) {
  return (
    <React.Fragment>
      <Notice>이 이야기가 궁금하신가요?</Notice>
      <Notice>지금 성인인증하시면 무료대여권을 바로 드립니다.</Notice>
      <Button primary href={certificationPath}>성인인증</Button>
    </React.Fragment>
  );
}

function Certified({ path }) {
  return (
    <React.Fragment>
      <Notice>당신을 위한 특별한 혜택!</Notice>
      <Notice>유료 회차를 무료로 볼 수 있는 무료대여권을 드립니다.</Notice>
      <Button primary href={path}>확인</Button>
    </React.Fragment>
  );
}

export { Login, NeedCertification, Certified };

import { useLayoutEffect } from 'react';

export default function useBodyBackgroundColor(color) {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = color;
    return () => {
      document.body.style.backgroundColor = null;
    };
  });
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { gridItem } from 'styles/grid.js';

const Header = styled.header`
  ${gridItem(12)}
  display: none;
  margin-bottom: 24px;
  text-align: center;
  word-break: keep-all;
  [data-react-class] > & {
    margin-top: 24px;
  }
  ${media.mobile`
    ${props => props.mobile && 'display: block;'}
  `}
  ${media.desktop`
    ${props => props.desktop && 'display: block;'}
    margin-bottom: 32px;
    [data-react-class] > & {
      margin-top: 32px;
    }
  `}
`;

const Title = styled.h1`
  ${props => (props.Oswald && Oswald())}
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  ${media.desktop`
    font-size: 32px;
  `}
`;

const Subtitle = styled.small`
  display: block;
  margin-top: 12px;
  font-size: 13px;
  line-height: 1.1;
  ${media.desktop`
    margin-top: 16px;
    font-size: 14px;
  `}
`;

const ListHeader = React.memo(props => (
  <Header {...props.display}>
    <Title Oswald={props.Oswald}>
      {props.title}
    </Title>
    {props.subtitle
      && (
      <Subtitle>
        {props.subtitle}
      </Subtitle>
      )
    }
  </Header>
));

ListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  Oswald: PropTypes.bool,
  display: PropTypes.object
};

ListHeader.defaultProps = {
  Oswald: false,
  display: {
    desktop: true,
    mobile: true
  }
};

export default ListHeader;

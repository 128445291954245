import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MainHeader from 'components/organisms/MainHeader';
import FlashMessage from 'components/organisms/FlashMessage';
import MainFooter from 'components/organisms/MainFooter';

import media from 'styles/media.js';
import { backgroundGray, white } from 'styles/palette.js';

const Main = styled.main.attrs({
  role: 'main'
})`
  position: relative;
  ${media.desktop`
    justify-content: center;
    background-color: ${backgroundGray};
  `}
`;

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  padding: 24px 16px;
  background-color: ${white};
  ${media.desktop`
    width: 414px;
    margin: 64px auto;
    padding: 32px;
  `}
`;

const FormTemplate = props => (
  <React.Fragment>
    <MainHeader {...props.headerProps} />
    {props.flashProps && <FlashMessage {...props.flashProps} />}
    <Main>
      <Wrapper>
        {props.children}
      </Wrapper>
    </Main>
    <MainFooter {...props.footerProps} />
  </React.Fragment>
);

FormTemplate.propTypes = {
  overlay: PropTypes.node,
  headerProps: PropTypes.object,
  flashProps: PropTypes.object,
  footerProps: PropTypes.object
};

export default FormTemplate;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';

const Message = styled.p`
  margin: 0 auto;
  padding-top: 32px;
  text-align: center;
  ${media.desktop`
    padding-top: 64px;
    font-size: 20px;
  `}
`;

const NoticeMessage = props => (
  <Message>
    {props.message}
  </Message>
);

NoticeMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default NoticeMessage;

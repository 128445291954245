import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';

import Rails from 'rails-ujs';

import PasswordField from 'components/atoms/PasswordField';
import AccountEditorSection from 'components/molecules/AccountEditorSection';

import UnregisterForm from 'components/molecules/UnregisterForm';

import media from 'styles/media.js';
import * as Popup from 'styles/popup.js';
import { primaryButton } from 'styles/button.js';
import { gridContainer, gridItem, gridCollapse } from 'styles/grid.js';
import {
  black, gray, white
} from 'styles/palette.js';

const Wrapper = styled.section`
  ${gridContainer}
  margin-top: 24px;
  margin-bottom: 24px;
  ${media.desktop`
    margin-top: 32px;
    margin-bottom: 32px;
    justify-content: center;
  `}
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  ${media.desktop`
    font-size: 26px;
  `}
`;

const Editor = styled.div`
  ${gridItem(12, 6)}
  display: flex;
  flex-flow: column;
  ${media.desktop`
    margin-right: 20px;
  `}
`;

const Row = styled.div`
  display: flex;
  min-width: 100%;
  margin-bottom: 24px;
  flex-flow: row wrap;
  &:last-child {
    margin-bottom: 0;
  }
  ${props => props.collapse && gridCollapse}
`;

const Label = styled.p`
  display: inline-flex;
  align-items: center;
  ${media.mobile`
    width: 100%;
    margin-bottom: 24px;
  `}
  ${props => props.nextline && media.desktop`
    order: 1;
    width: 100%;
  `}
`;

const CurrentValue = styled.span`
  color: ${gray};
  font-size: 14px;
  margin-left: auto;
  ${media.desktop`
    margin-left: 24px;
  `}
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  ${primaryButton}
  height: 52px;
  padding: 0 15px;
  flex: 1 0 auto;
  & + & {
    margin-left: 16px;
  }
  ${media.desktop`
    flex-grow: 0;
    margin-left: auto;
  `}
`;

const LinkButton = styled(Button).attrs({
  as: 'a'
})`
  ${primaryButton}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioSwitch = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  border: 2px solid ${black};
`;

const SwitchButton = styled.label`
  display: inline-flex;
  width: 50%;
  height: 52px;
  padding: 0 32px;
  align-items: center;
  justify-content: center;
  ${props => props.selected && `
    background-color: ${black};
    color: ${white};
    font-weight: bold;
  `}
  input {
    display: none;
  }
`;

const CenteredButton = styled.button`
  width: 100%;
  margin: 0px auto;
`;

export default class AccountEditor extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    integrated: PropTypes.bool.isRequired,
    integratePath: PropTypes.string.isRequired
  }

  state = {
    showUnregisterForm: false,
    user: this.props.user
  }

  toggleUnregisterForm = () => {
    this.setState(prevState => ({ showUnregisterForm: !prevState.showUnregisterForm }));
  }

  openOnUnregisterPopup = (event) => {
    event.preventDefault();
    this.setState({ onRegister: true });
  }

  closeOnUnregisterPopup = () => {
    this.setState({ onRegister: false });
  }

  openPopup = (type) => {
    this.setState({ popupType: type, isEditting: true });
  }

  closePopup = (event) => {
    if (event.target === event.currentTarget) {
      this.setState({
        isEditting: false,
        user: this.props.user,
        errorCause: null,
        errorMessage: null
      });
    }
  }

  handleInput = (event, parent = undefined) => {
    event.persist();
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked
      : target.value === 'true' ? true
        : target.value === 'false' ? false
          : target.value;
    const { name } = target;

    if (parent) {
      this.setState(prevState => ({
        [parent]: {
          ...prevState[parent],
          [name]: value
        }
      }));
    } else {
      this.setState(() => ({ [name]: value }));
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'patch',
      url: this.props.path,
      data: {
        user: {
          ...this.state.user
        }
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then(() => {
      // TODO : 플래시메시지 추가
      window.location.reload();
    }).catch((error) => {
      this.setState(() => ({
        errorMessage: error.response.data.message,
        errorCause: error.response.data.cause
      }));
    });
  }

  render = () => (
    <Wrapper>
      <Editor>
        <Title>
          계정 관리
        </Title>
        <AccountEditorSection title='계정'>
          <Row>
            <Label>
              이메일
              <CurrentValue>
                {this.props.user.email_confirmed === false ? this.props.user.email : `${this.props.user.email} (인증됨)`}
              </CurrentValue>
            </Label>
          </Row>
          <Row>
            <Label>
              비밀번호
            </Label>
            <Button onClick={() => this.openPopup('비밀번호 변경')}>
              {this.props.user.hasPassword ? '변경' : '설정' }
            </Button>
          </Row>

          {!this.props.user.integrated
          && (
            <LinkButton href={this.props.integratePath}>맥심 통합 회원으로 전환</LinkButton>
          )}
        </AccountEditorSection>
        <AccountEditorSection title='알림 설정'>
          <Row>
            <Label>
              프로모션 및 이벤트 메일
              <CurrentValue>
                {this.props.user.accept_ad ? '켜짐' : '꺼짐'}
              </CurrentValue>
            </Label>
            <Button onClick={() => this.openPopup('알림 설정')}>
              설정
            </Button>
          </Row>
        </AccountEditorSection>

        <div>
          <CenteredButton type='button' onClick={this.toggleUnregisterForm}>계정 탈퇴</CenteredButton>
          {this.state.showUnregisterForm
          && <UnregisterForm path={this.props.path} />
          }
        </div>

      </Editor>
      {this.state.isEditting
        && (
        <Popup.Background onClick={event => this.closePopup(event)}>
          <Popup.Popup onSubmit={this.handleSubmit}>
            <Popup.Title>
              {this.state.popupType}
            </Popup.Title>
            {
              this.state.popupType === '이메일 변경'
                ? (
                  <React.Fragment>
                    <Popup.Label htmlFor='email'>
                    이메일
                    </Popup.Label>
                    <Popup.Input
                      placeholder='id@maximcomics.com'
                      id='email'
                      type='email'
                      autoComplete='email'
                      name='email'
                      error={this.state.errorCause === 'email'}
                      onChange={event => this.handleInput(event, 'user')}
                    />
                    {this.state.errorCause === 'email'
                    && (
                    <Popup.ErrorMessage>
                      {this.state.errorMessage}
                    </Popup.ErrorMessage>
                    )
                  }
                    {this.state.user.hasPassword === true
                    && (
                    <div>
                      <Popup.Label htmlFor='password'>
                        비밀번호
                      </Popup.Label>
                      <PasswordField
                        id='password'
                        autoComplete='current-password'
                        name='password_confirmation'
                        error={this.state.errorCause === 'current_password'}
                        onChange={event => this.handleInput(event, 'user')}
                      />
                      {this.state.errorCause === 'current_password'
                        && (
                        <Popup.ErrorMessage>
                          {this.state.errorMessage}
                        </Popup.ErrorMessage>
                        )
                      }
                    </div>
                    )
                  }
                  </React.Fragment>
                )
                : this.state.popupType === '비밀번호 변경'
                  ? (
                    <React.Fragment>
                      <input type='email' autoComplete='email' hidden />
                      {this.state.user.hasPassword === true
                    && (
                    <div>
                      <Popup.Label htmlFor='current_password'>
                        기존 비밀번호
                      </Popup.Label>
                      <PasswordField
                        id='current_password'
                        autoComplete='current-password'
                        name='password_confirmation'
                        error={this.state.errorCause === 'current_password'}
                        onChange={event => this.handleInput(event, 'user')}
                      />
                      {this.state.errorCause === 'current_password'
                        && (
                        <Popup.ErrorMessage>
                          {this.state.errorMessage}
                        </Popup.ErrorMessage>
                        )
                      }
                    </div>
                    )
                  }
                      <Popup.Label htmlFor='new_password'>
                    새 비밀번호
                      </Popup.Label>
                      <PasswordField
                        id='new_password'
                        autoComplete='new-password'
                        name='password'
                        error={this.state.errorCause === 'password'}
                        onChange={event => this.handleInput(event, 'user')}
                      />
                      {this.state.errorCause === 'password'
                    && (
                    <Popup.ErrorMessage>
                      {this.state.errorMessage}
                    </Popup.ErrorMessage>
                    )
                  }
                    </React.Fragment>
                  )
                  : this.state.popupType === '알림 설정'
                    ? (
                      <React.Fragment>
                        <RadioSwitch>
                          <SwitchButton htmlFor='accept_ad' selected={this.state.user.accept_ad}>
                            <input
                              id='accept_ad'
                              type='radio'
                              value='true'
                              name='accept_ad'
                              onChange={event => this.handleInput(event, 'user')}
                            />
                    동의
                          </SwitchButton>
                          <SwitchButton htmlFor='reject_ad' selected={!this.state.user.accept_ad}>
                            <input
                              id='reject_ad'
                              type='radio'
                              value='false'
                              name='accept_ad'
                              onChange={event => this.handleInput(event, 'user')}
                            />
                    미동의
                          </SwitchButton>
                        </RadioSwitch>
                      </React.Fragment>
                    )
                    : ''
            }
            <Popup.Submit
              value='완료'
              primary
            />
            <Popup.Close onClick={event => this.closePopup(event)}>
              취소
            </Popup.Close>
          </Popup.Popup>
        </Popup.Background>
        )
      }
    </Wrapper>
  )
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Media from 'react-media';

import ListHeader from 'components/atoms/ListHeader';
import MoreButton from 'components/atoms/MoreButton';
import RankingCard from 'components/molecules/RankingCard';

import media, { mediaQuery } from 'styles/media.js';
import { gridContainer, gridItem, gridCollapse } from 'styles/grid.js';

const List = styled.section`
  ${gridContainer}
  margin-top: 24px;
  margin-bottom: 24px;
  ${media.desktop`
    margin-top: 32px;
    margin-bottom: 32px;
  `}
`;

const DesktopCardsWrapper = styled.div`
  ${gridItem(6)}
`;

const DesktopCards = styled.div`
  ${gridCollapse}
  display: flex;
  flex-flow: row wrap;
`;

export default class RankingCardList extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    comics: PropTypes.array.isRequired,
    path: PropTypes.string
  }

  state = {
    isClient: false
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  renderDesktopCards = comics => (
    <React.Fragment>
      <RankingCard {...comics[0]} rank={1} key={comics[0].title} />
      <DesktopCardsWrapper>
        <DesktopCards>
          {comics.slice(1).map((comic, index) => (
            <RankingCard {...comic} rank={index + 2} key={comic.title} />
          ))}
        </DesktopCards>
      </DesktopCardsWrapper>
    </React.Fragment>
  )

  renderMobileCards = comics => (
    comics.slice(0, 3).map((comic, index) => (
      <RankingCard {...comic} rank={index + 1} key={comic.title} />
    ))
  )

  render = () => (
    <List>
      <ListHeader
        title={this.props.title}
        subtitle={this.props.subtitle}
      />
      {this.state.isClient
        && (
        <Media query={mediaQuery.desktop}>
          {matches => (matches
            ? this.renderDesktopCards(this.props.comics) : this.renderMobileCards(this.props.comics)
          )}
        </Media>
        )
      }
      {this.props.path
        && <MoreButton path={this.props.path} />
      }
    </List>
  );
}

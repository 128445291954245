import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'styles/media.js';
import { gray } from 'styles/palette.js';

const ArtistsWrapper = styled.p`
  margin-top: 8px;
  color: ${gray};
  font-size: 13px;
  ${media.desktop`
    margin-top: 16px;
  `}
`;

const ArtistRole = styled.span`
  margin-right: 4px;
  font-weight: bold;
`;

function Artists({ artists }) {
  return (
    <ArtistsWrapper>
      {artists.length === 1
        ? artists[0].name
        : artists.map((artist, index, array) => (
          <React.Fragment key={artist.name}>
            {(index === 0)
              ? (<ArtistRole>{artist.role}</ArtistRole>) : (array[index - 1].role !== artist.role)
                ? (
                  <React.Fragment>
                    {' / '}
                    <ArtistRole>{artist.role}</ArtistRole>
                  </React.Fragment>
                )
                : ', '}
            {artist.name}
          </React.Fragment>
        ))}
    </ArtistsWrapper>
  );
}

Artists.propTypes = {
  artists: PropTypes.array
};

export default Artists;

import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';

import Rails from 'rails-ujs';

import MoreButton from 'components/atoms/MoreButton';
import ComicCard from 'components/molecules/ComicCard';

import checkIcon from 'images/ic-check.svg';

import ellipsis from 'styles/ellipsis.js';
import media from 'styles/media.js';
import { primaryButton, secondaryButton } from 'styles/button.js';

import { gridContainer, gridItem } from 'styles/grid.js';
import { gray, white, red } from 'styles/palette.js';

const List = styled.section`
  ${gridContainer}
  margin-bottom: 24px;
  ${media.desktop`
    margin-bottom: 32px;
  `}
`;

const ListHeader = styled.header`
  ${gridItem(12)}
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  ${media.desktop`
    margin-bottom: 16px;
  `}
`;

const Title = styled.h1`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    font-size: 26px;
  `}
`;

const Buttons = styled.div`
  margin-bottom: 8px;
  ${media.desktop`
    position: absolute;
    top: -4px;
    right: 0;
  `}
`;

const Button = styled.button`
  ${props => (props.primary ? primaryButton : secondaryButton)}
  display: inline-block;
  margin: 0 4px;
  padding: 12px 14px;
`;

const Checkbox = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: calc(100% - 6px);
  border: 3px solid ${red};
`;

const CheckIcon = styled(checkIcon)`
  position: absolute;
  top: -3px;
  left: -3px;
  width: 42px;
  height: 42px;
  padding: 6px;
  border: 3px solid ${red};
  background-color: ${props => (props.checked ? red : white)};
  ${props => (props.checked
    && `path {fill: ${white}};`
  )}
`;

const Cards = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

const Text = styled.p`
  ${ellipsis}
  margin-top: 8px;
  color: ${gray};
  font-size: 13px;
`;

const EmptyNotice = styled.p`
  ${gridItem(12)}
  margin-bottom: 16px;
  text-align: center;
`;

export default class LibraryCardList extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    comics: PropTypes.array.isRequired,
    path: PropTypes.string,
    edit: PropTypes.bool,
    emptyNotice: PropTypes.string
  }

  state = {
    edit: false,
    comics: this.props.comics
  }

  handleEdit = (value) => {
    this.setState(() => ({ edit: value }));
  }

  checkComic = (comic, index) => {
    const nextComics = [...this.state.comics];
    nextComics[index].checked = !comic.checked;
    this.setState(() => ({ comics: nextComics }));
  }

  removeData = () => {
    const nextComics = [...this.state.comics];
    const checkedComics = nextComics.filter(comic => (comic.checked));
    checkedComics.forEach((comic) => {
      axios({
        method: 'delete',
        url: comic.deletePath,
        responseType: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': Rails.csrfToken()
        }
      }).then(() => {
        nextComics.splice(nextComics.indexOf(comic), 1);
        this.handleEdit(false);
      });
    });
    this.setState(() => ({ comics: nextComics }));
  }

  renderButtons = () => (
    this.state.edit
      ? (
        <Buttons>
          <Button onClick={() => this.removeData()} primary>
          삭제
          </Button>
          <Button onClick={() => this.handleEdit(false)}>
        취소
          </Button>
        </Buttons>
      )
      : (
        <Buttons>
          <Button onClick={() => this.handleEdit(true)} primary>
          편집
          </Button>
        </Buttons>
      )
  )

  renderCards = () => this.state.comics.map((comic, index) => (
    <ComicCard
      {...comic}
      path={this.state.edit ? undefined : comic.path}
      key={comic.title}
      grid={{
        mobile: 6,
        desktop: 3
      }}
    >
      {comic.text
        && (
        <Text>
          {comic.text}
        </Text>
        )
      }
      {this.state.edit
        && (
        <Checkbox
          checked={comic.checked}
          onClick={() => this.checkComic(comic, index)}
        >
          <CheckIcon
            checked={comic.checked}
          />
        </Checkbox>
        )
      }
    </ComicCard>
  ))

  render = () => (
    <List>
      <ListHeader>
        <Title>
          {this.props.title}
        </Title>
        {(this.props.editable && this.state.comics.length > 0) && this.renderButtons()}
      </ListHeader>
      {this.state.comics.length ? (
        <Cards>
          {this.renderCards()}
        </Cards>
      ) : (
        <EmptyNotice>
          {this.props.emptyNotice}
        </EmptyNotice>
      )}
      {this.props.path
        && <MoreButton path={this.props.path} />
      }
    </List>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Oswald from 'styles/Oswald.js';
import { black, coinYellow } from 'styles/palette.js';

const Icon = styled.span`
  ${Oswald}
  display: inline-flex;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.size}px;
`;

const CoinIcon = props => (
  <Icon
    size={props.size}
    fontSize={props.fontSize}
    bgColor={props.bgColor}
    color={props.color}
  >
    C
  </Icon>
);

CoinIcon.propTypes = {
  size: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
  color: PropTypes.string
};

CoinIcon.defaultProps = {
  bgColor: coinYellow,
  color: black
};

export default CoinIcon;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';

import ShopBanner from 'components/atoms/ShopBanner';
import CoinWallet from 'components/molecules/CoinWallet';

const Wrapper = styled.div`
  ${gridContainer}
  margin-top: 12px;
  margin-bottom: 24px;
  ${media.desktop`
    margin-top: 0;
    margin-bottom: 32px;
    justify-content: flex-end;
  `}
`;

const CoinSection = styled.aside`
  ${gridItem(12, 4)}
  ${media.desktop`
    margin-top: 32px;
  `}
`;

const Iframe = styled.iframe`
  ${gridItem(12, 8)}
  border: 0;
  margin-top: 32px;
  text-align: center;
  height: 900px;
  ${media.desktop`
    margin-right: 20px;
  `}
`;

const PointForm = ({ src, currentUser, banner }) => (
  <Wrapper>
    <CoinSection>
      <CoinWallet coin={currentUser.coin} />
      {banner &&
        <ShopBanner src={banner.image} />
      }
    </CoinSection>
    <Iframe title='포인트 파크' src={src} />
  </Wrapper>
);

PointForm.propTypes = {
  src: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    coin: PropTypes.number.isRequired
  }),
  banner: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string
  })
};

export default PointForm;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import {
  black, gray, lightGray, white
} from 'styles/palette.js';

const Header = styled.header`
  display: flex;
  justify-content: center;
  text-align: center;
  ${media.mobile`
    margin-top: 24px;
  `}
  ${media.desktop`
    padding: 24px;
    background-color: ${black};
    line-height: 1;
  `}
`;

const Title = styled.h1`
  color: ${black};
  font-size: 24px;
  font-weight: bold;
  ${media.desktop`
    color: ${white};
    font-size: 28px;
  `}
`;

const Subtitle = styled.small`
  display: block;
  margin-bottom: 8px;
  color: ${gray};
  font-size: 14px;
  font-weight: normal;
  ${media.desktop`
    color: ${lightGray};
  `}
`;

const SearchHeader = props => (
  <Header>
    <Title>
      <Subtitle>
        {props.subtitle}
      </Subtitle>
      {props.title}
    </Title>
  </Header>
);

SearchHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default SearchHeader;

import React, { useContext } from 'react';
import styled from 'styled-components';
import media, { mediaQuery } from 'styles/media.js';
import { gray, white, freeGreen } from 'styles/palette.js';
import QuestionIcon from 'images/ic-question.svg';

import useMedia from 'components/hooks/useMedia';

import CurrentUserContext from 'components/contexts/CurrentUserContext';

const CouponsCount = styled.p`
  display: flex;
  align-items: center;
  margin: 16px auto 4px;
  color: ${freeGreen};
  font-weight: bold;
  font-size: 13px;
  ${media.mobile`
    justify-content: center;
  `}
`;

const ToolTip = React.memo(styled.div`
  display: flex;
  position: relative;
  margin-left: 4px;
  cursor: pointer;
`);

const ToolTipQuestionIcon = React.memo(styled(QuestionIcon)`
  width: 20px;
  height: 20px;
`);

const ToolTipContents = React.memo(styled.div`
  display: none;
  ${ToolTip.type}:hover & {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 260px;
    margin-top: 12px;
    padding: 16px;
    border-radius: 2px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, .5), 0 1px 2px 0 rgba(0, 0, 0, .4);
    background-color: ${white};
    opacity: .97;
    transform: translateX(-50%);
    color: ${gray};
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }
`);

const Em = React.memo(styled.em`
  color: ${freeGreen};
  font-weight: bold;
  font-style: normal;
`);

function Coupons({ count }) {
  const isDesktop = useMedia(mediaQuery.desktop);
  const user = useContext(CurrentUserContext);

  return (
    (typeof count !== 'undefined') && (
      <CouponsCount>
        {`${user.isPresent ? '' : '로그인 시'} 무료대여권 ${count}장 사용 가능`}

        {isDesktop && (
          <ToolTip>
            <ToolTipQuestionIcon />
            <ToolTipContents>
              <Em>무료대여권</Em>
              을 이용하여 유료 에피소드를 24시간 동안 무료로 볼 수 있습니다.
            </ToolTipContents>
          </ToolTip>
        )}
      </CouponsCount>
    )
  );
}

export default Coupons;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import prevImage from 'images/btn-viewer-prev.svg';
import nextImage from 'images/btn-viewer-next.svg';
import lockedImage from 'images/ic-viewer-locked.svg';
import GiftCouponIcon from 'images/ic-giftcoupon.svg';

import media from 'styles/media.js';
import { black, white } from 'styles/palette.js';

const Footer = styled.footer`
  display: ${props => (props.active ? 'flex' : 'none')};
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: ${props => props.height}px;
  background-color: ${black};
  justify-content: center;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  margin: 0 24px;
  opacity: ${props => (props.href ? '1' : '.4')};
  color: ${white};
  font-weight: bold;
  ${media.desktop`
    margin: 0 32px;
  `}
`;

const PrevImage = styled(prevImage)`
  margin-right: 4px;
`;

const NextImage = styled(nextImage)`
  margin-left: 4px;
`;

const StyledGiftCouponIcon = styled(GiftCouponIcon)`
  ${props => props.prev && 'margin-right: 8px;'}
  ${props => props.next && 'margin-left: 8px;'}
`;

const LockedImage = styled(lockedImage)`
  ${props => props.prev && 'margin-right: 4px;'}
  ${props => props.next && 'margin-left: 4px;'}
`;

const ViewerFooter = props => (
  <Footer active={props.active} height={props.height}>
    <Link href={props.previousPath || undefined} onClick={props.onPrev}>
      {props.isPreviousShowable || !(props.previousPath) ? (
        <PrevImage />
      ) : props.isPreviousRentable ? (
        <StyledGiftCouponIcon prev='true' />
      ) : (
        <LockedImage prev='true' />
      )}
      이전화
    </Link>
    <Link href={props.nextPath || undefined} onClick={props.onNext}>
      다음화
      {props.isNextShowable || !(props.nextPath) ? (
        <NextImage />
      ) : props.isNextRentable ? (
        <StyledGiftCouponIcon next='true' />
      ) : (
        <LockedImage next='true' />
      )}
    </Link>
  </Footer>
);

ViewerFooter.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.number.isRequired,
  previousPath: PropTypes.string,
  nextPath: PropTypes.string,
  isPreviousShowable: PropTypes.bool,
  isNextShowable: PropTypes.bool,
  isPreviousRentable: PropTypes.bool,
  isNextRentable: PropTypes.bool
};

export default ViewerFooter;

import React from 'react';
import PropTypes from 'prop-types';

import { createGlobalStyle } from 'styled-components';

import { backgroundGray } from 'styles/palette.js';
import media from 'styles/media.js';

const GlobalStyle = createGlobalStyle`
  ${media.desktop`
    body {
      background-color: ${backgroundGray};
    }
  `}
`;

const GrayBackgroundStyle = props => (
  <GlobalStyle {...props} />
);

GrayBackgroundStyle.propTypes = {
  color: PropTypes.string
};

GrayBackgroundStyle.defaultProps = {
  color: backgroundGray
};

export default GrayBackgroundStyle;

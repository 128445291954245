import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import smoothscroll from 'smoothscroll-polyfill';

import ScrollUpIcon from 'images/btn-scroll-up.svg';

import media from 'styles/media.js';

const Button = styled.button`
  position: fixed;
  z-index: 100;
  right: 16px;
  bottom: 16px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  opacity: .85;
  background-color: #333333;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .1);
  ${media.desktop`
    display: none;
  `}
`;

const StyledScrollUpIcon = styled(ScrollUpIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default class ScrollUpButton extends React.Component {
  state = {
    isShow: false
  }

  static propTypes = {
    baseScrollY: PropTypes.number
  }

  static defaultProps = {
    baseScrollY: 500
  }

  componentDidMount() {
    smoothscroll.polyfill();
    window.addEventListener('scroll', this.handleButton);
    this.handleButton();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleButton);
  }

  handleButton = () => {
    const { isShow } = this.state;
    if (window.scrollY > this.props.baseScrollY) {
      isShow || this.setState({ isShow: true });
    } else {
      isShow && this.setState({ isShow: false });
    }
  }

  handleScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState({ isShow: false });
  }

  render = () => this.state.isShow && (
    <Button onClick={this.handleScroll}>
      <StyledScrollUpIcon />
    </Button>
  )
}

import styled from 'styled-components';
import media from 'styles/media.js';
import {
  white
} from 'styles/palette.js';

const Container = styled.section`
  display: flex;
  flex-flow: row wrap;
  margin-top: 8px;
  padding: 16px;
  background-color: ${white};
  ${media.desktop`
    max-width: 414px;
    margin: 80px auto 40px;
    padding: 32px;
  `}
`;

export default Container;

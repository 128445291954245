import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import ComicCard from 'components/molecules/ComicCard';

import media from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { black, white } from 'styles/palette.js';

const Container = styled.section`
  ${gridContainer}
  ${media.desktop`
    align-items: flex-start;
    padding: 16px 10px 24px;
  `}
`;

const Tabs = styled.div`
  ${gridItem(12)}
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  ${props => props.active && `
    background-color: ${black};
    color: ${white};
    font-weight: bold;
  `}
`;

const Synopsis = styled.p`
  display: inline-block;
  max-height: 3.2em;
  margin-top: 8px;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.6;
  word-break: break-all;
`;

const Column = styled.section`
  display: flex;
  flex: 1;
  flex-flow: column;
  min-width: 1px;
  padding: 0 10px;
  ${props => props.theme.black && `
    border-radius: 8px;
    background-color: ${black};
    color: ${white};
  `}
`;

const Name = styled.h2`
  margin: 16px auto 24px;
  font-size: 20px;
  text-align: center;
  ${props => props.theme.black && `
    color: ${white};
    font-weight: bold;
  `}
`;

export const DesktopSchedules = React.memo(({ schedules }) => (
  <Container>
    {schedules.map(schedule => (
      <ThemeProvider
        theme={{ black: schedule.default }}
        key={schedule.name}
      >
        <Column>
          <Name>{schedule.name}</Name>
          {schedule.comics.map(comic => (
            <ComicCard
              {...comic}
              grid={false}
              key={comic.title}
            />
          ))}
        </Column>
      </ThemeProvider>
    ))}
  </Container>
));

export const MobileSchedules = React.memo(({ schedules, active, selectDay }) => (
  <Container>
    <Tabs>
      {schedules.map(schedule => (
        <Tab
          active={schedule.name === active}
          onClick={() => selectDay(schedule.name)}
          key={schedule.name}
        >
          {schedule.name}
        </Tab>
      ))}
    </Tabs>
    {schedules.find(schedule => schedule.name === active).comics.map(comic => (
      <ComicCard
        {...comic}
        thumbnail={comic.image}
        type='large'
        grid={{
          mobile: 12
        }}
        key={comic.title}
      >
        <Synopsis>
          {comic.synopsis}
        </Synopsis>
      </ComicCard>
    ))}
  </Container>
));

DesktopSchedules.propTypes = {
  schedules: PropTypes.array.isRequired
};

MobileSchedules.propTypes = {
  schedules: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
  selectDay: PropTypes.func.isRequired
};

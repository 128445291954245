import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { primaryButton } from 'styles/button.js';

const JumpToEpisode = styled.a`
  ${primaryButton}
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
`;

const JumpToEpisodeTitle = styled.span`
  flex: 0 1 auto;
  min-width: 0;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const JumpToEpisodeText = styled.span`
  flex: 0 0 auto;
`;
function NavigationButton({ firstEpisodePath, lastViewedEpisode }) {
  return (
    lastViewedEpisode
      ? (
        <JumpToEpisode href={lastViewedEpisode.path}>
          <JumpToEpisodeTitle>
            {lastViewedEpisode.title}
          </JumpToEpisodeTitle>
          <JumpToEpisodeText>
          이어보기
          </JumpToEpisodeText>
        </JumpToEpisode>
      )
      : (
        firstEpisodePath ? (
          <JumpToEpisode href={firstEpisodePath}>
          첫화보기
          </JumpToEpisode>
        ) : ''
      )
  );
}

NavigationButton.propTypes = {
  firstEpisodePath: PropTypes.string,
  lastViewedEpisode: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })
};

export default NavigationButton;

import React from 'react';
import styled from 'styled-components';

import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';

import media from 'styles/media.js';
import { white, red } from 'styles/palette.js';

const Container = styled.section`
  display: flex;
  flex-flow: column;
  margin-top: 8px;
  padding: 16px;
  background-color: ${white};
  ${media.desktop`
    max-width: 414px;
    margin: 80px auto 40px;
    padding: 32px;
  `}
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
`;

const Body = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const Address = styled.div`
  line-height: 1.6;
`;

const Terms = styled.ul`
  list-style-type: none;
  font-size: 16px;
  line-height: 1.71;
  font-weight: bold;
  padding-left: 12px;
`;

const Term = styled.li`
  ::before {
    content: '-';
    margin-right: 4px;
  }
`;

const Warning = styled.div`
  font-size: 14px;
  line-height: 1.6;
`;

const Red = styled.span`
  color: ${red}
`;

const Proposal = () => (
  <Container>
    <GrayBackgroundStyle />
    <Title>맥심코믹스 연재문의</Title>
    <Body>
      <Address>
        재미있는 만화를 함께 만들어갈 작가님을 찾습니다.
        <Red> welcome@maximcomics.com</Red>
        으로
        하단의 내용들을 첨부하여 보내주시면 검토 후 연락
        드리도록 하겠습니다.
      </Address>
      <Terms>
        <Term>1화이상 완성 원고 (5화 이내 콘티, 3화 이상 완성원고 권장)</Term>
        <Term>캐릭터 시트(별도 양식 없음)</Term>
        <Term>시넙시스 및 작품설명(별도 양식 없음)</Term>
        <Term>대표 이미지 또는 포스터(별도 양식 없음)</Term>
      </Terms>
    </Body>
    <Warning>
      많은 작품이 투고되어 제안 기준 분량에 미달할 경우 내부검토가
      이루어지지 않아 회신이 되지 않을 수도 있습니다.
    </Warning>
  </Container>
);

export default Proposal;

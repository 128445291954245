import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import styled from 'styled-components';

import CommonTemplate from 'components/templates/CommonTemplate';

import Oswald from 'styles/Oswald.js';
import media, { mediaQuery } from 'styles/media.js';
import {
  black,
  darkGray,
  gray,
  white,
  bronze
} from 'styles/palette.js';

function importAll(r) {
  const images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const Images = importAll(require.context('images/promotion/grandopen/', false, /\.(jpg|png)$/));

const TitleSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Images['img-background.jpg']});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ${media.mobile`
    padding: 24px 22px 32px;
  `}
`;

const TitleImage = styled.img`
  ${media.desktop`
    z-index: 1;
    margin-top: 48px;
    margin-bottom: 30px;
  `}
`;

const ContentSection = styled.section`
  display: flex;
  position: relative;
  flex-flow: column;
  padding: 56px 0 32px;
  border-top: 2px solid ${white};
  background-color: ${black};
  color: ${bronze};
  text-align: center;
  &::before {
    ${Oswald}
    content: 'C';
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    transform: translate(-50%, -50%);
    border: 2px solid;
    border-radius: 50%;
    background-color: ${black};
    color: ${white};
    font-size: 28px;
    line-height: 1;
    ${media.desktop`
      width: 76px;
      height: 76px;
      font-size: 32px;
    `}
  }
  ${media.desktop`
    padding: 85px 0 48px;
  `}
`;

const Amount = styled.p`
  ${Oswald}
  margin-bottom: 32px;
  color: ${white};
  font-size: 40px;
  line-height: 1;
  &::after {
    content: '';
    display: flex;
    width: 48px;
    height: 12px;
    margin: 32px auto 0;
    background-color: ${white};
  }
  ${media.desktop`
    font-size: 60px;
  `}
`;

const Sign = styled.div`
  margin-bottom: 24px;
  font-size: 13px;
  ${media.desktop`
    margin-bottom: 32px;
    font-size: 20px;
  `}
`;

const ButtonLink = styled.a`
  margin: 0 32px;
  padding: 1em;
  border: 0;
  border-radius: 9999px;
  background-color: ${bronze};
  color: ${black};
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  ${media.desktop`
    width: 312px;
    margin: auto;
  `}
`;

const Emphasized = styled.em`
  color: ${white};
  font-style: normal;
  font-weight: bold;
`;

const Curation = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 32px 0;
  background-color: ${bronze};
  ${media.desktop`
    padding: 64px 0;
  `}
`;

const CurationTitle = styled.img`
  margin-bottom: 32px;
`;

const CurationComics = styled.div`
  display: flex;
  ${media.mobile`
    flex-flow: column;
    align-items: center;
  `}
  ${media.desktop`
    flex-flow: row;
    margin: 0 20px;
  `}
`;

const CurationItem = styled.a`
  display: flex;
  align-items: flex-start;
  ${media.mobile`
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  `}
  ${media.desktop`
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  `}
`;

const Acknowledgement = styled.section`
  padding: 32px 16px;
  background-color: ${darkGray};
  color: ${gray};
  font-size: 12px;
  line-height: 1.7;
  text-align: center;
  ${media.desktop`
    font-size: 14px;
    line-height: 1.6;
  `}
`;

const AcknowledgementHeading = styled.h1`
  margin-bottom: 10px;
  color: ${bronze};
  font-size: 13px;
  font-weight: bold;
  ${media.desktop`
    margin-bottom: 16px;
    font-size: 20px;
  `}
`;

const LoginSection = ({ path }) => (
  <ContentSection>
    <Sign>
      결제 내역확인을 위해
      <Emphasized> 로그인</Emphasized>
      이 필요합니다.
    </Sign>
    <ButtonLink href={path}>
      로그인
    </ButtonLink>
  </ContentSection>
);

const PaymentSection = ({ paymentAmount, paths }) => (
  <ContentSection>
    <Sign>이벤트 기간 내 결제 금액</Sign>
    <Amount>{`₩${paymentAmount.toLocaleString()}`}</Amount>
    {paymentAmount >= 10000 ? (
      <React.Fragment>
        <Sign>
          이벤트 경품 발송을 위해
          <Emphasized> 개인정보 수집</Emphasized>
          이 필요합니다.
        </Sign>
        <ButtonLink href={paths.profilePath}>
          경품 배송 정보 입력
        </ButtonLink>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Sign>
          본 이벤트에 참여하기 위해서는
          <Emphasized> 1만원 이상의 결제</Emphasized>
          가 필요합니다.
        </Sign>
        <ButtonLink href={paths.shopPath}>
          결제 페이지로 가기
        </ButtonLink>
      </React.Fragment>
    )}
  </ContentSection>
);

const JoinedSection = () => (
  <ContentSection>
    <Sign>신청 완료되었습니다.</Sign>
  </ContentSection>
);

const ClosedSection = () => (
  <ContentSection>
    <Sign>이벤트가 종료되었습니다.</Sign>
  </ContentSection>
);

const CurationComic = ({ href, src, alt }) => (
  <CurationItem href={href}>
    <img alt={alt} src={src} />
  </CurationItem>
);

const GrandopenPage = React.memo(({
  layoutProps,
  paths,
  user,
  isEventClosed
}) => (
  <CommonTemplate
    {...layoutProps}
  >
    <TitleSection>
      <Media query={mediaQuery.desktop}>
        {matches => (
          <TitleImage
            src={Images[matches ? 'img-title-desktop.png' : 'img-title-mobile.png']}
            alt='그랜드 오픈 이미지'
          />
        )}
      </Media>
    </TitleSection>
    {isEventClosed
      ? <ClosedSection />
      : user.isPresent
        ? user.isJoined
          ? <JoinedSection />
          : <PaymentSection paymentAmount={user.paymentAmount} paths={paths} />
        : <LoginSection path={paths.loginPath} />
    }
    <Curation>
      <Media query={mediaQuery.desktop}>
        {matches => (
          <React.Fragment>
            <CurationTitle
              src={Images[matches ? 'img-curation-title-desktop.png' : 'img-curation-title-mobile.png']}
            />
            <CurationComics>
              <CurationComic
                href='/comics/134'
                src={Images[matches ? 'img-curation-resizer-desktop.png' : 'img-curation-resizer-mobile.png']}
              />
              <CurationComic
                href='/comics/154'
                src={Images[matches ? 'img-curation-sheiss-desktop.png' : 'img-curation-sheiss-mobile.png']}
              />
              <CurationComic
                href='/comics/153'
                src={Images[matches ? 'img-curation-temptation-desktop.png' : 'img-curation-temptation-mobile.png']}
              />
              <CurationComic
                href='/comics/106'
                src={Images[matches ? 'img-curation-bj-desktop.png' : 'img-curation-bj-mobile.png']}
              />
            </CurationComics>
          </React.Fragment>
        )}
      </Media>
    </Curation>
    <Acknowledgement>
      <AcknowledgementHeading>
        · 유의사항 ·
      </AcknowledgementHeading>
      <Emphasized>맥심코믹스 코인 상품 1만원 미만 구매자</Emphasized>
      는 참여되지 않습니다.
      <br />
      본 이벤트는 ID 당 1회 참여 가능합니다.
      <br />
      본 이벤트에 참여된 결제내역은 환불 대상에서 제외됩니다.
      <br />
      마감 이후의 접수분에 대해서는 경품을 지급하여 드리지 않습니다.
      <br />
      경품 발송은 월 1회, 매달 말일에 발송됩니다.
      <br />
      이벤트 관련 문의는 contact@maximcomics.com 으로 보내주시기 바랍니다.
    </Acknowledgement>
  </CommonTemplate>
));

LoginSection.propTypes = {
  path: PropTypes.string.isRequired
};
PaymentSection.propTypes = {
  paymentAmount: PropTypes.number.isRequired,
  paths: PropTypes.objectOf(PropTypes.string).isRequired
};
GrandopenPage.propTypes = {
  isEventClosed: PropTypes.bool,
  layoutProps: PropTypes.object.isRequired,
  paths: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.object.isRequired
};
CurationComic.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default GrandopenPage;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import DropdownButton from 'images/btn-dropdown.svg';

import { gridItem } from 'styles/grid.js';
import { black, lightGray, white } from 'styles/palette.js';

const Item = styled.article`
  ${gridItem(12)}
  border: 3px solid ${lightGray};
  margin-bottom: 4px;
  padding: 13px;
  color: ${black};
  background-color: ${white};
  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  flex: 1;
  font-weight: bold;
`;

const StyledDropdownButton = styled(DropdownButton)`
  ${props => props.active && 'transform: rotate(180deg);'}
`;

const Contents = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${lightGray};
  line-height: 1.6;
  white-space: pre-wrap;
`;

const Dropdown = props => (
  <Item onClick={props.onClick}>
    <Header>
      <Title>
        {props.title}
      </Title>
      <StyledDropdownButton active={props.active ? 'true' : undefined} />
    </Header>
    {props.active
      && (
      <Contents>
        {props.contents}
      </Contents>
      )
    }
  </Item>
);

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  contents: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

export default Dropdown;

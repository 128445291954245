import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from 'components/atoms/Container';
import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';
import ListHeader from 'components/atoms/ListHeader';
import { primaryButton } from 'styles/button.js';
import {
  white, red, black, lightGray
} from 'styles/palette.js'
import PasswordField from 'components/atoms/PasswordField';

import AuthenticityToken from 'components/atoms/AuthenticityToken';

const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
`;

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
`;

const Revoke = styled.input.attrs({
  type: 'submit'
})`
  width: 100%;
  appearance: none;
  padding: 1em;
  border: 0;
  cursor: pointer;
`;

const EmailBox = styled.div`
  text-align: center;
  padding: 1em;
  margin-bottom: 1em;
  color: ${black};
  background-color: ${lightGray};
  font-weight: bold;
`;

const Textfield = styled.input.attrs({
  required: 'required'
})`
  width: 100%;
  margin-bottom: 16px;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  background-color: ${white};
  line-height: 1.6;
  text-align: left;
  &::placeholder {
    color: ${lightGray};
  }
`;

const Message = styled.p`
  margin-bottom: 1em;
  line-height: 1.5;
  color: ${black};
`;

const Link = styled.a`
  color: red;
`;

export default class AccountIntegrationForm extends React.Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    maximkorea_account: PropTypes.bool.isRequired,
    omni_authed: PropTypes.bool
  }

  createAccount = () => (
    <div>
      <input type='hidden' name='type' value='new_account' />
      <Message>
        통합 계정 전환을 위해 <strong>맥심코리아</strong> 가입이 필요합니다. 아래 정보로 맥심코리아 계정이 생성됩니다.
      </Message>
      <Label>이름</Label>
      <Textfield
        placeholder='최소 2자 ~ 최대 12자'
        id='name'
        name='name'
      />
      <Label>휴대폰</Label>
      <Textfield
        placeholder='010-0000-0000'
        id='mobile'
        name='mobile'
      />
      {!this.props.omni_authed
       &&
          (
            <div>
              <input type='hidden' name='omni_authed' value='true' />
              <Label>비밀번호</Label>
              <PasswordField
                id='password'
                name='password'
                autoComplete='new-password'
              />
            </div>
          )
      }
    </div>
  )

  confirmPassword = () => (
    <div>
      <input type='hidden' name='type' value='confirm_account' />
      {!this.props.maximkorea_omni_authed &&
          (
            <div>
              <Message>
                통합 계정 전환을 위해 <strong>맥심코리아</strong> 계정 확인이 필요합니다. 맥심코리아에서 사용하고 계시는 비밀번호를 입력해주세요.
              </Message>
              <Label>맥심코리아 비밀번호</Label>
              <PasswordField
                id='password'
                name='password'
                autoComplete='confirm'
              />
            </div>
          )
      }
    </div>
  )

  render = () => (
    <Container>
      <GrayBackgroundStyle />
      <ListHeader title='통합 계정 전환 안내' Oswald />
      <Form action='/my/account/integrations' method='post'>
        <AuthenticityToken />
        <EmailBox>
          {this.props.email}
        </EmailBox>
        <Message>
          맥심코리아에서 사용하시던 계정 정보 그대로 맥심코믹스에서 이용하실 수 있습니다.
        </Message>
        <Message>
          아래에서 동의를 선택하면 맥심코믹스로의 개인정보 이전, 맥심코리아 <Link href='http://www.maximkorea.net/main2/sb_about7.php' target='_blank'>서비스 이용약관 및 개인정보 수집 및 이용</Link>, 맥심코믹스의 <Link href='/helps/terms' target='_blank'>서비스 이용약관</Link> 및 <Link href='/helps/privacies' target='_blank'>개인정보 수집 및 이용</Link>에 동의하게 됩니다.
        </Message>
        {this.props.maximkorea_account ? this.confirmPassword() : this.createAccount() }
        <Submit value='동의' name='동의' />
      </Form>
      <Form action='/my/account/integrations/revoke' method='post'>
        <AuthenticityToken />
        <Revoke value='계정통합을 하지 않겠습니다' name='계정통합을 하지 않겠습니다' />
      </Form>
    </Container>
  )
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownButton from 'images/btn-dropdown.svg';

import Oswald from 'styles/Oswald.js';
import media from 'styles/media.js';
import { freeGreen, lightGray, white } from 'styles/palette.js';

const Item = styled.div`
  ${props => props.disabled && `color: ${lightGray};`}
  display: flex;
  align-items: center;
  padding: 16px 0;
  ${media.desktop`
    padding: 32px 0;
  `}
`;

const Title = React.memo(styled.h2`
  font-size: 20px;
  font-weight: bold;
  ${media.desktop`
    font-size: 26px;
  `}
`);

const TodayLabel = styled.span`
  ${Oswald}
  margin-left: 8px;
  padding: 6px;
  background-color: ${freeGreen};
  color: ${white};
  text-align: center;
  line-height: 1;
`;

const Count = React.memo(styled.span`
  margin-left: auto;
  font-size: 14px;
  ${media.desktop`
    font-size: 16px;
    line-height: 1.6;
  `}
`);

const DropdownButtonWrapper = React.memo(styled.div`
  ${props => props.isOpened && 'transform: rotate(180deg);'}
  display: flex;
  margin-left: 4px;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  ${media.desktop`
    margin-left: 8px;
  `}
`);

const PureDropdownButton = React.memo(DropdownButton);

const PresentItem = props => (
  <Item
    className={props.className}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <Title>
      {props.title}
    </Title>
    {props.today && <TodayLabel>TODAY</TodayLabel>}
    <Count>
      {`무료대여권 ${props.count}장`}
    </Count>
    <DropdownButtonWrapper
      isOpened={props.isOpened}
      isVisible={props.count > 0}
    >
      <PureDropdownButton />
    </DropdownButtonWrapper>
  </Item>
);

PresentItem.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  today: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isOpened: PropTypes.bool
};

PresentItem.defaultProps = {
  today: false,
  disabled: false,
  isOpened: false
};

export default PresentItem;

import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';

import Rails from 'rails-ujs';

import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';
import Container from 'components/atoms/Container';
import ListHeader from 'components/atoms/ListHeader';
import Checkbox from 'components/atoms/Checkbox';
import PasswordField from 'components/atoms/PasswordField';

import { primaryButton } from 'styles/button.js';
import {
  black, gray, lightGray, white, red
} from 'styles/palette.js';

const Form = styled.form`
  ${props => props.center && 'text-align: center;'}
  width: 100%;
  flex: 1 0 auto;
`;

const SelectAll = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const Term = styled.div`
  margin-left: 16px;
  margin-bottom: 16px;
  &:first-of-type {
    margin-top: 20px;
  }
`;

const Required = styled.span`
  margin-left: 4px;
  color: ${red};
`;

const Optional = styled.span`
  margin-left: 4px;
  color: ${black};
`;

const TermPath = styled.a.attrs({
  target: '_blank'
})`
  margin-left: auto;
  color: ${red};
  flex-shrink: 0;
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  ${primaryButton()}
  width: 100%;
  margin-top: 8px;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
`;

const Textfield = styled.input.attrs({
  required: 'required'
})`
  width: 100%;
  margin-bottom: 16px;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  background-color: ${white};
  line-height: 1.6;
  text-align: left;
  &::placeholder {
    color: ${lightGray};
  }
`;

const ErrorMessage = styled.p`
  margin-bottom: 16px;
  color: ${red};
  font-size: 14px;
`;

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  width: 100%;
  margin-top: 16px;
`;

export default class SignUpForm extends React.Component {
  static propTypes = {
    action: PropTypes.string.isRequired,
    welcomePath: PropTypes.string,
    terms: PropTypes.object.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
      omniAuthIdentities: PropTypes.array
    }),
    joinWithSns: PropTypes.bool
  }

  static defaultProps = {
    welcomePath: '/welcome',
    user: {
      email: '',
      omniAuthIdentities: []
    }
  }

  state = {
    terms: Object.assign({}, ...Object.keys(this.props.terms).map(term => ({
      [term]: false
    }))),
    requiredTerms: Object.assign({}, ...Object.keys(this.props.terms).map((term) => {
      if (this.props.terms[term].required) {
        return ({ [term]: false });
      }
      return null;
    })),
    user: { ...this.props.user }
  }

  isAllTermsChecked = terms => (
    Object.values(terms).every(value => (value))
  )

  handleAllTerms = (event) => {
    event.persist();
    const { checked } = event.target;
    this.setState(prevState => ({
      terms: Object.assign({}, ...Object.keys(prevState.terms).map(term => ({
        [term]: checked
      }))),
      requiredTerms: Object.assign({}, ...Object.keys(prevState.requiredTerms).map(term => ({
        [term]: checked
      })))
    }));
  }

  handleTerm = (event) => {
    event.persist();
    const { checked, name, required } = event.target;

    this.setState(prevState => ({
      terms: {
        ...prevState.terms,
        [name]: checked
      }
    }));

    if (required) {
      this.setState(prevState => ({
        requiredTerms: {
          ...prevState.requiredTerms,
          [name]: checked
        }
      }));
    }
  }

  handleUser = (event) => {
    event.persist();
    const { name, value } = event.target;
    const user = { ...this.state.user };
    user[name] = value;

    this.setState(() => ({ user }));
  }

  handleInput = (event) => {
    event.persist();
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value }));
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const userData = {
      terms_of_age: this.state.terms.age,
      terms_of_service: this.state.terms.age,
      terms_of_collect_privacy: this.state.terms.collect_privacy,
      email_agree: this.state.terms.maximkorea_marketing,
      mobile_agree: this.state.terms.maximkorea_marketing,
      accept_ad: this.state.terms.ad,
      email: this.state.user.email,
      password: this.state.user.password,
      name: this.state.user.name,
      mobile: this.state.user.mobile,
      omni_auth_identity_ids: this.props.user.omniAuthIdentities
    };

    axios({
      method: 'post',
      url: this.props.action,
      data: {
        user: userData
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then(() => {
      window.location.href = this.props.welcomePath;
    }).catch((error) => {
      this.setState(() => ({
        errorMessage: error.response.data.message,
        errorCause: error.response.data.cause
      }));
    });
  }

  renderInfo = () => (
    <Form onSubmit={this.handleSubmit}>
      <Label htmlFor='email'>
        이메일
      </Label>
      <Textfield
        placeholder='id@maximcomics.com'
        id='email'
        type='email'
        name='email'
        autoComplete='email'
        autoFocus
        value={this.state.user.email}
        error={this.state.errorCause === 'email'}
        onChange={this.handleUser}
      />
      {this.state.errorCause === 'email'
        && (
        <ErrorMessage>
          {this.state.errorMessage}
        </ErrorMessage>
        )
      }
      {this.props.joinWithSns
        || (
        <React.Fragment>
          <Label htmlFor='password'>
            비밀번호
          </Label>
          <PasswordField
            id='password'
            name='password'
            autoComplete='new-password'
            error={this.state.errorCause === 'password'}
            onChange={this.handleUser}
          />
        </React.Fragment>
        )
      }
      {this.state.errorCause === 'password'
        && (
        <ErrorMessage>
          {this.state.errorMessage}
        </ErrorMessage>
        )
      }
      <React.Fragment>
        <Label htmlFor='name'>
          이름
        </Label>
        <Textfield
          placeholder='최소 2자 ~ 최대 12자'
          id='name'
          name='name'
          error={this.state.errorCause === 'name'}
          onChange={this.handleUser}
        />
      </React.Fragment>
      <React.Fragment>
        <Label htmlFor='mobile'>
          휴대폰
        </Label>
        <Textfield
          placeholder='010-0000-0000'
          id='mobile'
          name='mobile'
          error={this.state.errorCause === 'mobile'}
          onChange={this.handleUser}
        />
      </React.Fragment>
      <Submit
        disabled={!(this.state.user.email && (this.props.joinWithSns || this.state.user.password))}
        value='완료'
      />
    </Form>
  )

  renderTerms = terms => (
    <Form onSubmit={this.handleSubmit}>
      <Checkbox
        name='SelectAll'
        onChange={this.handleAllTerms}
        checked={this.isAllTermsChecked(this.state.terms)}
      >
        <SelectAll>전체동의</SelectAll>
      </Checkbox>
      {Object.keys(terms).map(term => (
        <Term key={term}>
          <Checkbox
            name={term}
            onChange={this.handleTerm}
            checked={this.state.terms[term] && true}
            required={terms[term].required}
          >
            <div>
              <span>{terms[term].text}</span>
              {terms[term].required
                ? <Required>(필수)</Required>
                : <Optional>(선택)</Optional>
              }
            </div>
            {terms[term].path
              && (
              <TermPath href={terms[term].path}>
                상세보기
              </TermPath>
              )
            }
          </Checkbox>
        </Term>
      ))}
      <Button
        disabled={!this.isAllTermsChecked(this.state.requiredTerms)}
        onClick={this.isAllTermsChecked(this.state.requiredTerms) ? this.handleInput : undefined}
        name='isTermsAgreed'
        value
      >
        다음
      </Button>
    </Form>
  )

  render = () => (
    <Container>
      <GrayBackgroundStyle />
      <ListHeader title='맥심코리아 회원가입' Oswald />
      {this.state.isTermsAgreed
        ? this.renderInfo()
        : this.renderTerms(this.props.terms)
      }
    </Container>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import MaskImage from 'images/btn-pw-mask.svg';
import UnmaskImage from 'images/btn-pw-unmask.svg';

import { black, lightGray, red } from 'styles/palette.js';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const Password = styled.input.attrs(({ masked }) => ({
  required: 'required',
  type: masked ? 'password' : 'text'
}))`
  width: 100%;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  line-height: 1.6;
  text-align: left;
  ${props => props.masked && 'font-family: sans-serif;'}
  &::placeholder {
    color: ${lightGray};
    font-family: 'Nanum Square', sans-serif;
  }
`;

const MaskButton = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  align-items: center;
`;

export default class PasswordField extends React.PureComponent {
  static propTypes = {
    placeholder: PropTypes.string
  };

  static defaultProps = {
    placeholder: '8자 이상'
  }

  state = {
    isMasked: true
  }

  handleMask = (event) => {
    event.preventDefault();
    this.setState(prevState => ({ isMasked: !prevState.isMasked }));
  }

  render = () => (
    <Wrapper>
      <Password {...this.props} masked={this.state.isMasked} />
      <MaskButton onClick={this.handleMask}>
        {this.state.isMasked ? <MaskImage /> : <UnmaskImage />}
      </MaskButton>
    </Wrapper>
  );
}

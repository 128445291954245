import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Images = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
`;

const Image = styled.img`
  display: block;
  flex: 0 0 auto;
`;

const EventPage = props => (
  <Images>
    {props.images.map(image => (
      image.link
        ? (
          <a href={image.link} key={`${image.url}${image.index}`}>
            <Image
              alt={`${props.title} 이미지 ${image.index}`}
              src={image.url}
            />
          </a>
        )
        : (
          <Image
            alt={`${props.title} 이미지 ${image.index}`}
            key={`${image.url}${image.index}`}
            src={image.url}
          />
        )
    ))}
  </Images>
);

EventPage.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    link: PropTypes.string
  }))
};

export default EventPage;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { primaryButton, secondaryButton } from 'styles/button.js';
import {
  black, lightGray, white, red
} from 'styles/palette.js';

const Popup = styled.form`
  width: 100%;
  max-width: 414px;
  background-color: ${white};
  align-self: center;
  text-align: center;
`;

const Notice = styled.p`
  ${props => (props.Oswald && `${Oswald()} font-size: 24px;`)}
  margin-bottom: 16px;
  line-height: 26px;
`;

const Input = styled.input.attrs({
  type: 'text'
})`
  width: 100%;
  margin-bottom: 8px;
  padding: 11px 0;
  border: 2px solid ${props => (props.error ? red : black)};
  line-height: 1.6;
  text-align: center;
  &::placeholder {
    color: ${lightGray};
  }
`;

const ErrorMessage = styled.p`
  margin-bottom: 8px;
  color: ${red};
  font-size: 14px;
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  ${props => (props.primary ? primaryButton : secondaryButton)}
  width: 100%;
  margin-bottom: 4px;
  font-weight: bold;
`;


export default class PurchasePopup extends React.Component {
  static propTypes = {
    isUser: PropTypes.bool,
    purchaseEpisode: PropTypes.func.isRequired,
    purchaseAll: PropTypes.func,
    purchaseBySecretCode: PropTypes.func,
    episode: PropTypes.shape({
      price: PropTypes.number.isRequired,
      secretCodePath: PropTypes.string,
      hasSecretCode: PropTypes.bool
    }),
    sumPrice: PropTypes.number,
    error: PropTypes.string
  }

  state = {
    canInputCode: false, // TODO: 변수명 변경?
    secretCode: ''
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.purchaseBySecretCode(
      this.props.episode.secretCodePath,
      this.state.secretCode
    );
  }

  handleInput = (status) => {
    this.setState(() => ({ canInputCode: status }));
  }

  handleSecretCode = (event) => {
    event.persist();
    this.setState(() => ({ secretCode: event.target.value }));
  }

  render = () => (
    <React.Fragment>
      <Popup>
        <Notice>에피소드를 구매할 수 없습니다</Notice>
      </Popup>
    </React.Fragment>
  );
}

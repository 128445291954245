import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';

import Rails from 'rails-ujs';

import media from 'styles/media.js';
import {
  red, black, lightGray, white
} from 'styles/palette.js';
import { primaryButton } from 'styles/button.js';

import ListHeader from 'components/atoms/ListHeader';
import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';

const Container = styled.section`
  display: flex;
  flex-flow: column;
  margin-top: 8px;
  padding: 16px;
  background-color: ${white};
  ${media.desktop`
    max-width: 414px;
    margin: 80px auto 40px;
    padding: 32px;
  `}
`;

const Description = styled.span`
  line-height: 1.6;
  text-align: center;
`;

const Textfield = styled.input.attrs({
  required: 'required'
})`
  margin-top: 18px;
  width: 100%;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  line-height: 1.6;
  text-align: left;
  &::placeholder {
    color: ${lightGray};
  }
`;

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  margin-top: 18px;
  width: 100%;
`;

const Home = styled.a`
  ${primaryButton}
  margin-top: 24px;
  width: 100%;
  text-align: center;
`;

const ErrorMessage = styled.p`
  width: 100%;
  color: ${red};
  font-size: 14px;
`;

export default class ForgotPasswordForm extends React.Component {
  static propTypes = {
    paths: PropTypes.object.isRequired
  }

  state = {
    sendMail: false
  }

  handleInput = (event) => {
    event.persist();
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'post',
      url: this.props.paths.password,
      data: {
        email: this.state.email
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then(() => {
      this.setState(() => ({ sendMail: true }));
    }).catch((error) => {
      this.setState(() => ({ errorMessage: error.response.data.message }));
    });
  }

  render = () => (
    <Container>
      <GrayBackgroundStyle />
      <ListHeader title='비밀번호 찾기' />
      {this.state.sendMail
        || (
        <React.Fragment>
          <Description>
            비밀번호를 다시 설정하여 로그인할 수 있습니다.
            가입한 메일 주소를 입력해 주세요.
          </Description>
          <form onSubmit={this.handleSubmit}>
            <Textfield
              placeholder='id@maximcomics.com'
              id='email'
              type='email'
              name='email'
              autoComplete='email'
              error={this.state.errorMessage}
              onChange={this.handleInput}
            />
            {this.state.errorMessage
              && (
              <ErrorMessage>
                {this.state.errorMessage}
              </ErrorMessage>
              )
            }
            <Submit value='완료' />
          </form>
        </React.Fragment>
        )
      }
      {this.state.sendMail
        && (
        <React.Fragment>
          <Description>
            비밀번호 재설정 메일을 전송했습니다.
            메일함을 확인해 주세요.
          </Description>
          <Home href={this.props.paths.home}>
            맥심코믹스 홈으로
          </Home>
        </React.Fragment>
        )
      }
    </Container>
  );
}

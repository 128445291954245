import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Motion, spring, presets } from 'react-motion';
import { forceCheck } from 'react-lazyload';

import Rails from 'rails-ujs';

import axios from 'axios';

import styled from 'styled-components';

import Modal from 'components/molecules/Modal';
import CoinIcon from 'components/atoms/CoinIcon';
import EpisodeCard from 'components/molecules/EpisodeCard';
import PurchasePopup from 'components/molecules/PurchasePopup';
import CurrentUserContext from 'components/contexts/CurrentUserContext';

import sortIcon from 'images/ic-sort.svg';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { black, white, coinYellow } from 'styles/palette.js';

const List = React.memo(styled.section`
  padding: 0 8px 16px;
  ${media.desktop`
    ${gridContainer('desktop')}
    padding: 0 0 24px;
  `}
`);

const Header = React.memo(styled.header`
  ${gridItem(null, 9)}
  display: flex;
  padding: 16px 8px;
  align-items: center;
  justify-content: space-between;
  color: ${white};
  font-size: 14px;
  ${media.desktop`
    padding: 20px 0;
  `}
`);

const Sort = React.memo(styled.button`
  display: inline-flex;
  align-items: center;
  color: ${white};
`);

const SortIcon = React.memo(styled(sortIcon)`
  margin-right: 4px;
  vertical-align: text-bottom;
`);

const PurchaseAll = React.memo(styled.div`
  ${gridItem(null, 9)}
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 12px 0 8px;
  border-radius: 4px;
  background-color: ${white};
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  ${media.desktop`
    flex-flow: row wrap;
    margin-bottom: 8px;
    padding: 16px 0;
  `}
`);

const PurchaseAllText = React.memo(styled.span`
  ${media.mobile`
    margin-bottom: 4px;
  `}
  ${media.desktop`
    margin-right: 6px;
  `}
`);

const Price = React.memo(styled.span`
  ${Oswald()}
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
`);

const PriceText = React.memo(styled.span`
  margin: 0 4px;
`);

const PurchaseAllButton = React.memo(styled.button`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${coinYellow};
  font-size: 14px;
  font-weight: bold;
`);

const PurchaseAllButtonText = React.memo(styled.span`
  margin-right: 8px;
`);

const PurchaseAllButtonPrice = React.memo(styled.span`
  ${Oswald()}
  margin-left: 4px;
  font-size: 16px;
`);

const Wrapper = React.memo(styled.div`
  ${gridItem(null, 9)}
  display: flex;
  flex-flow: ${props => (props.reverse ? 'column-reverse' : 'column')};
`);

function EpisodeCards({
  episodes,
  lastViewedEpisode,
  archived,
  handleClick
}) {
  let index = 0;
  return (
    episodes.map((episode) => {
      if (!episode.displayed) {
        index += 1;
      }
      return (
        <EpisodeCard
          {...episode}
          index={episode.displayed ? '-' : index}
          key={episode.title}
          onClick={event => handleClick(episode, event)}
          lastViewed={lastViewedEpisode === episode.id}
          archived={archived}
        />
      );
    })
  );
}

function BulkPurchase({ sumPrice, onPurchaseAll }) {
  const [activate, setActivate] = useState(false);

  return (
    <PurchaseAll onClick={() => {
      setActivate(true);
      setTimeout(() => {
        setActivate(false);
      }, 3000);
    }}
    >
      <PurchaseAllText>전체 에피소드 구매하기</PurchaseAllText>
      <Price>
        <CoinIcon size={24} fontSize={12} />
        <PriceText>
          {sumPrice}
        </PriceText>
      </Price>
      <Motion style={{ opacity: spring(activate ? 1 : 0, presets.stiff) }}>
        { style => (
          <PurchaseAllButton
            style={{ opacity: style.opacity }}
            onClick={() => (style.opacity > 0) && onPurchaseAll()}
          >
            <PurchaseAllButtonText>
              한 번 더 누르면 구매
            </PurchaseAllButtonText>
            <CoinIcon
              size={24}
              fontSize={12}
              color={white}
              bgColor={black}
            />
            <PurchaseAllButtonPrice>
              {sumPrice}
            </PurchaseAllButtonPrice>
          </PurchaseAllButton>
        )}
      </Motion>
    </PurchaseAll>
  );
}
BulkPurchase.propTypes = {
  sumPrice: PropTypes.number,
  onPurchaseAll: PropTypes.func
};

function EpisodeList({
  schedules,
  episodes,
  purchase,
  archived = false,
  ...props
}) {
  const [isReversed, setIsReversed] = useState(false);
  const user = useContext(CurrentUserContext);
  const [buyingEpisode, setBuyingEpisode] = useState(null);
  const [secretCodeError, setSecretCodeError] = useState(null);

  useEffect(() => {
    forceCheck();
  }, [isReversed]);

  function onPurchaseAll() {
    axios({
      method: 'post',
      url: purchase.path,
      data: {
        comic_id: props.comicId,
        episode_ids: purchase.episodeIds,
        redirect_episode_id: false
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.data.url;
    }).catch((err) => {
      window.location.href = err.response.data.url;
    });
  }
  function onPurchaseEpisode(episode) {
    axios({
      method: 'post',
      url: purchase.path,
      data: {
        comic_id: props.comicId,
        episode_ids: [episode.id],
        redirect_episode_id: episode.id
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.data.url;
    }).catch((err) => {
      window.location.href = err.response.data.url;
    });
  }

  function onPurchaseBySecretCode(path, secretCode) {
    axios({
      method: 'post',
      url: path,
      data: {
        secret_code: secretCode
      },
      headers: {
        'Content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.data.url;
    }).catch((err) => {
      if (err.response.data.url) {
        window.location.href = err.response.data.url;
      } else {
        setSecretCodeError(err.response.data.message);
      }
    });
  }

  function handleEpisodeClick(episode, event) {
    if (!episode.free
      && !episode.purchased
      && !episode.isOpenedBySecretCode
      && !episode.onRentaled
      && !episode.isRentable
    ) {
      event.preventDefault();
      // Should be bought
      if (!archived) {
        setBuyingEpisode(episode);
      }
    }
  }

  return (
    <List>

      <Header>
        <span>
          {schedules && `매주 ${schedules} 연재, `}
          {`총 ${episodes.length}화`}
        </span>
        <Sort onClick={() => setIsReversed(!isReversed)}>
          <SortIcon />
          정렬변경
        </Sort>
      </Header>

      <Wrapper reverse={isReversed}>
        <EpisodeCards
          episodes={episodes}
          lastViewedEpisode={props.lastViewedEpisode}
          handleClick={handleEpisodeClick}
          archived={archived}
        />
      </Wrapper>

      {buyingEpisode && (
        <Modal onClose={() => setBuyingEpisode(null)}>
          <PurchasePopup
            sumPrice={purchase.sumPrice}
            isUser={user.isPresent}
            episode={buyingEpisode}
            purchaseAll={onPurchaseAll}
            purchaseEpisode={onPurchaseEpisode}
            purchaseBySecretCode={onPurchaseBySecretCode}
            error={secretCodeError}
          />
        </Modal>
      )}
    </List>
  );
}

EpisodeList.propTypes = {
  schedules: PropTypes.string,
  episodes: PropTypes.array,
  purchase: PropTypes.shape({
    episodeIds: PropTypes.array,
    path: PropTypes.string,
    sumPrice: PropTypes.number
  })
};

export default EpisodeList;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { bannerHeight } from 'components/molecules/HomeBannerSlide';

import media from 'styles/media.js';

const Background = styled.div`
  display: flex;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  height: ${bannerHeight.mobile};
  background-color: ${props => (props.color)};
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${props => (props.image)});
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
  ${media.desktop`
    height: ${bannerHeight.desktop};
  `}
`;

const HomeBannerBackground = props => (
  <Background
    style={{ opacity: props.opacity }}
    color={props.color}
    image={props.image}
  />
);

HomeBannerBackground.propTypes = {
  image: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number
};

export default HomeBannerBackground;

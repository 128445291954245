import React from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab } from 'components/atoms/Tab';

const ShopTabs = ({active}) => {
  return (
    <Tabs>
      <Tab active={active.coins} href={'/shop/coin'}>
        코인 충전
      </Tab>
      <Tab active={active.points} href={'/shop/point'}>
        무료 코인 충전
      </Tab>
    </Tabs>
  )
}

ShopTabs.propTypes = {
  active: PropTypes.shape({
    coins: PropTypes.bool
  })
}

export default ShopTabs;

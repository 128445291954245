import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import searchIcon from 'images/btn-search.svg';

import media from 'styles/media.js';
import { black, lightGray, white } from 'styles/palette.js';

const Search = styled.form`
  display: flex;
  flex: 0 0 auto;
  position: relative;
  color: #7A7A7A;
  ${media.desktop`
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0 24px;
    padding: 0;
    background-color: transparent;
    color: ${black};
    &:last-child {
      margin-right: 0;
    }
  `}
`;

const SearchInput = styled.input.attrs({
  type: 'search',
  required: true
})`
  flex: 1 0 auto;
  border: 0;
  line-height: 2;
  color: inherit;
  &::-webkit-search-cancel-button {
    display: none;
  }
  ${media.mobile`
    height: 58px;
    padding: 12px 0px 12px 54px;
    border-radius: 8px;
    background-color: #222222;
    &::placeholder {
      color: #666666;
    }
  `}
  ${media.desktop`
    flex: 0 0 auto;
    height: 34px;
    padding: 0;
    border-bottom: 2px solid;
    background-color: ${white};
    font-size: 14px;
    &::placeholder {
      color: ${lightGray};
    }
  `}
`;

const SearchIcon = styled(searchIcon)`
  display: flex;
  g {
    stroke: currentColor;
  }
  ${media.mobile`
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  `}
`;

const SearchBar = props => (
  <Search
    action={props.searchPath}
    method='get'
  >
    <SearchInput
      placeholder={props.placeholder}
      name='query'
    />
    <SearchIcon />
  </Search>
);

SearchBar.propTypes = {
  searchPath: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

SearchBar.defaultProps = {
  placeholder: '작가명, 작품명'
};

export default SearchBar;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  black, lightGray, white
} from 'styles/palette.js';
import media from 'styles/media.js';

import LogoImage from 'images/img-viewer-logo-mobile.svg';
import CloseImage from 'images/btn-close.svg';

const Tooltip = styled.a`
  display: flex;
  position: fixed;
  bottom: 24px;
  left: 50%;
  align-items: center;
  width: 268px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .2);
  transform: translateX(-50%);
  background-color: ${white};
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: rotate(45deg) translate(-50%, 50%);
    transform-origin: bottom left;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, .2);
    background-color: ${white};
  }
`;

const Logo = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 8px;
  background-color: ${black};
`;

const StyledLogoImage = styled(LogoImage)`
  width: 48px;
  height: 48px;
`;

const Text = styled.p`
  margin-left: 16px;
  font-size: 13px;
  line-height: 1.4;
  white-space: pre-wrap;
`;

const CloseButton = styled.button`
  display: flex;
  position: absolute;
  top: -12px;
  right: -12px;
  padding: 8px;
  border-radius: 50%;
  background-color: ${lightGray};
`;

const StyledCloseImage = styled(CloseImage)`
  width: 20px;
  height: 20px;
`;

const AddToHomescreenTooltip = props => (
  <Tooltip>
    <Logo>
      <StyledLogoImage />
    </Logo>
    <Text>
      {'이 곳에서 '}
      <strong>‘홈 화면 추가’</strong>
      로
      <br />
      바로가기 아이콘을 생성하세요.
      <br />
      편리하게 접속할 수 있습니다.
    </Text>
    <CloseButton onClick={props.closeTooltip}>
      <StyledCloseImage />
    </CloseButton>
  </Tooltip>
);

AddToHomescreenTooltip.propTypes = {
  closeTooltip: PropTypes.func.isRequired
};

export default AddToHomescreenTooltip;

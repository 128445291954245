import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import Rails from 'rails-ujs';

import styled, { css } from 'styled-components';

import LogoImage from 'images/img-viewer-logo-mobile.svg';
import EpisodeListImage from 'images/btn-episodelist.svg';
import subscribeImage from 'images/btn-viewer-subscribe.svg';

import ellipsis from 'styles/ellipsis.js';
import { black, white } from 'styles/palette.js';

const Header = styled.header`
  display: ${props => (props.active ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: ${props => props.height}px;
  background-color: ${white};
  justify-content: space-between;
`;

const ButtonStyle = css`
  display: flex;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
`;

const Logo = styled.a`
  ${ButtonStyle}
  background-color: ${black};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  overflow: hidden;
`;

const EpisodeList = styled.a`
  display: inline-flex;
  flex: 0 0 auto;
  margin: 0 8px;
`;

const TitleText = styled.h1`
  ${ellipsis}
  font-size: 16px;
`;

const Subscribe = styled.button`
  ${ButtonStyle}
`;

const SubscribeImage = styled(subscribeImage)`
  ${props => (
    props.black && `
      path {
        fill: ${black};
      }
    `
  )};
`;

export default class ViewerHeader extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    height: PropTypes.number.isRequired,
    homePath: PropTypes.string.isRequired,
    comicPath: PropTypes.string.isRequired,
    subscribe: PropTypes.object.isRequired,
    isUser: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ])
  }

  state = {
    subscribe: this.props.subscribe.active
  }

  handleSubscribe = () => {
    if (this.state.subscribe) {
      if (window.confirm('정말 해제하시겠습니까?')) {
        axios({
          method: 'delete',
          url: this.props.subscribe.path,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': Rails.csrfToken()
          }
        });
        this.setState(() => ({ subscribe: false }));
      }
    } else {
      axios({
        method: 'post',
        url: this.props.subscribe.path,
        responseType: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': Rails.csrfToken()
        }
      });
      this.setState(() => ({ subscribe: true }));
    }
  }

  render = () => (
    <Header active={this.props.active} height={this.props.height}>
      <Logo href={this.props.homePath} size={this.props.height}>
        <LogoImage />
      </Logo>
      <Title>
        <EpisodeList href={this.props.comicPath}>
          <EpisodeListImage />
        </EpisodeList>
        <TitleText>
          {this.props.children}
        </TitleText>
      </Title>
    </Header>
  );
}

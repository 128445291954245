import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import checkboxIcon from 'images/ic-checkbox.svg';

import { black, white } from 'styles/palette.js';

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`;

const Input = styled.input.attrs({
  type: 'checkbox'
})`
  display: none;
`;

const CheckboxIcon = styled(checkboxIcon)`
  margin-right: 8px;
  flex-shrink: 0;
  input[type='checkbox']:checked + & {
    [fill] {
      fill: ${white};
    }

    [stroke] {
      fill: ${black};
      stroke: ${black};
    }
  }
`;

const Checkbox = ({
  children, className, name, ...inputProps
}) => (
  <Label htmlFor={name} className={className}>
    <Input
      id={name}
      name={name}
      {...inputProps}
    />
    <CheckboxIcon />
    {children}
  </Label>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool
};

Checkbox.defaultProps = {
  required: false
};

export default React.memo(Checkbox);

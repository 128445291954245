import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media, { mediaQuery } from 'styles/media.js';
import ellipsis from 'styles/ellipsis.js';
import Oswald from 'styles/Oswald.js';
import { gray, white } from 'styles/palette.js';

const Item = styled.a`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: center;
  height: 70px;
  ${media.desktop`
    height: 120px;
  `}
`;

const AdultWrapper = styled.div`
  position: relative;
  height: 100%;
  margin-right: 12px;
`;

const AdultLabel = styled.div`
  ${Oswald}
  display: flex;
  position: absolute;
  bottom: 4px;
  right: 4px;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: ${gray};
  color: ${white};
  font-size: 11px;
  text-align: center;
  line-height: 1;
  ${media.desktop`
    width: 28px;
    height: 28px;
    font-size: 14px;
  `}
`;

const Image = styled.img`
  width: 112px;
  height: 100%;
  ${media.desktop`
    width: 192px;
  `}
`;

const Texts = styled.div`
  flex: 1 1;
  overflow: hidden;
`;

const Title = styled.h3`
  ${ellipsis}
  font-weight: bold;
  ${media.mobile`
    margin-bottom: 3px;
  `}
  ${media.desktop`
    font-size: 20px;
  `}
`;

const Text = styled.p`
  margin-top: 8px;
  color: ${gray};
  font-size: 14px;
`;

const PresentComicCard = props => (
  <Item href={props.path} className={props.className}>
    <AdultWrapper>
      <Image src={props.thumbnail} alt={`${props.title} 이미지`} />
      {props.isAdult && (
        <AdultLabel>
          19
        </AdultLabel>
      )}
    </AdultWrapper>
    <Media query={mediaQuery.desktop}>
      {matches => (matches
        ? (
          <React.Fragment>
            <Texts>
              <Title>
                {props.title}
              </Title>
              <Text>
                {props.artist}
              </Text>
              <Text>
                {props.genre}
              </Text>
            </Texts>
            {props.children}
          </React.Fragment>
        ) : (
          <Texts>
            <Title>
              {props.title}
            </Title>
            {props.children}
          </Texts>
        )
      )}
    </Media>
  </Item>
);

PresentComicCard.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  genre: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  isAdult: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default PresentComicCard;

import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import styled, { createGlobalStyle } from 'styled-components';

import Media from 'react-media';

import CloseButton from 'images/btn-close.svg';

import Oswald from 'styles/Oswald.js';
import media, { mediaQuery } from 'styles/media.js';
import { black, white, freeGreen } from 'styles/palette.js';

const Banner = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  position: relative;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: ${freeGreen};
  ${media.desktop`
    position: fixed;
    z-index: 10;
    right: 56px;
    bottom: 56px;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .5);
    background-color: ${black};
  `}
`;

const GlobalStyle = createGlobalStyle`
  #sidemenu {
    top: 120px;
    height: calc(100vh - 120px);
  }
`;

const Path = styled.a`
  display: flex;
  flex: 1 0 auto;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  ${media.desktop`
    flex-flow: column;
  `}
`;

const PrimaryText = styled.p`
  font-size: 14px;
  font-weight: bold;
  ${media.desktop`
    margin-bottom: 12px;
    color: ${white};
    font-size: 32px;
    text-align: center;
  `}
`;

const SecondaryText = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  ${media.desktop`
    padding-bottom: 4px;
    border-bottom: 3px solid;
    color: ${freeGreen};
    font-size: 20px;
    font-weight: bold;
  `}
`;

const TicketCount = styled.span`
  ${Oswald}
  margin-left: 4px;
  padding: 3px 4px 5px;
  border-radius: 4px;
  background-color: ${white};
  font-size: 18px;
  line-height: 1;
  &:last-of-type {
    margin-right: 4px;
  }
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  position: absolute;
  ${media.mobile`
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  `}
  ${media.desktop`
    right: 0;
    bottom: 0;
    svg {
      width: 20px;
      height: 20px;
    }
  `}
`;

export default class PresentsBanner extends React.Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired
  }

  state = {
    hidden: false
  }

  closeBanner = () => {
    // FIXME : state가 아닌 상위 메인 페이지 component를 만들어서 관리
    this.setState({ hidden: true });
    const endOfToday = new Date().setHours(23, 59, 59, 999);
    Cookies.set('no_new_presents', true, { expires: new Date(endOfToday) });
  }

  render = () => (
    <Banner hidden={this.state.hidden}>
      <Media query={mediaQuery.desktop}>
        {matches => (matches ? (
          <React.Fragment>
            <Path href={this.props.path}>
              <PrimaryText>
                {`${this.props.month}월의`}
                <br />
                무료대여권
              </PrimaryText>
              <SecondaryText>
                {`${this.props.count}장 받으러 가기`}
              </SecondaryText>
            </Path>
            <Button
              onClick={this.closeBanner}
            >
              닫기
              <CloseButton />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.state.hidden || <GlobalStyle />}
            <Path href={this.props.path}>
              <PrimaryText>
                {`${this.props.month}월`}
              </PrimaryText>
              <SecondaryText>
                의 무료대여권 총
                {[...`${this.props.count}`].map((number, index) => (
                  <TicketCount key={index}>
                    {number}
                  </TicketCount>
                ))}
                장 도착!
              </SecondaryText>
            </Path>
            <Button
              onClick={this.closeBanner}
            >
              <CloseButton />
            </Button>
          </React.Fragment>
        ))}
      </Media>
    </Banner>
  )
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { secondaryButton } from 'styles/button.js';

const KeywordsWrapper = styled.div`
  margin-top: 16px;
`;

const Keyword = styled.a`
  ${secondaryButton}
  display: inline-block;
  margin: 0 2px 8px;
  font-size: 13px;
`;

function Keywords({ keywords }) {
  return (
    <KeywordsWrapper>
      {keywords.map(keyword => (
        <Keyword href={keyword.path} key={keyword.keyword}>
          {`#${keyword.keyword}`}
        </Keyword>
      ))}
    </KeywordsWrapper>
  );
}
Keywords.propTypes = {
  keywords: PropTypes.array
};

export default Keywords;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import ellipsis from 'styles/ellipsis.js';
import Oswald from 'styles/Oswald.js';
import { white, red, gray } from 'styles/palette.js';
import { maxWidth } from 'styles/grid.js';

export const bannerHeight = {
  mobile: '70vw',
  desktop: '480px'
};

const Slide = styled.a`
  width: 100%;
  position: relative;
  flex: 1 0 auto;
  text-align: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: ${bannerHeight.mobile};
  ${media.desktop`
    height: ${bannerHeight.desktop};
  `}
`;

const Image = styled.img`
  z-index: 2;
  height: 100%;
  object-fit: cover;
  user-select: none;
  user-drag: none;
`;

const Label = styled.span`
  ${Oswald}
  position: absolute;
  z-index: 3;
  top: ${bannerHeight.mobile};
  left: 50%;
  padding: 6px;
  background-color: ${red};
  transform: translate(-50%, -50%);
  align-self: flex-end;
  color: ${white};
  text-align: center;
  font-size: 14px;
  line-height: 1;
  ${media.desktop`
    top: ${bannerHeight.desktop};
  `}
`;

const Title = styled.h3`
  ${ellipsis}
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.1;
  ${media.desktop`
    margin-top: 30px;
    font-size: 36px;
  `}
`;

const SubTitle = styled.p`
  ${ellipsis}
  margin-bottom: 8px;
  color: ${gray};
  font-size: 13px;
  ${media.desktop`
    font-size: 14px;
    margin-bottom: 12px;
  `}
`;

const Description = styled.p`
  height: 3.2em;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.6;
  ${media.desktop`
    display: inline-block;
    max-width: ${maxWidth}px;
    padding: 0 20px;
    font-size: 16px;
  `}
`;

const HomeBannerSlide = props => (
  <Slide
    href={props.url}
    onTouchStart={props.onTouchStart}
  >
    <ImageWrapper>
      <Image
        src={props.image}
        alt={`${props.title} 배너 이미지`}
      />
    </ImageWrapper>
    <Label>{props.label}</Label>
    <Title>{props.title}</Title>
    <SubTitle>{props.subTitle}</SubTitle>
    <Description>{props.description}</Description>
  </Slide>
);

HomeBannerSlide.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onTouchStart: PropTypes.func
};

export default HomeBannerSlide;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import CoinIcon from 'components/atoms/CoinIcon';
import CoinItem from 'components/molecules/CoinItem';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { primaryButton } from 'styles/button.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { black, lightGray } from 'styles/palette.js';

const Wrapper = styled.section`
  ${gridContainer}
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: flex-start;
  ${media.desktop`
    margin-top: 32px;
    margin-bottom: 32px;
  `}
`;

const UserInfo = styled.header`
  ${gridItem(12, 4)}
  margin-bottom: 32px;
  padding: 16px;
  border: 3px solid ${lightGray};
  text-align: center;
`;

const Email = styled.h2`
  ${Oswald}
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: bold;
`;

const LinkButton = styled.a`
  ${primaryButton}
  display: inline-block;
  margin: 0 4px;
  padding: 12px 16px;
`;

const Coins = styled.section`
  ${gridItem(12, 8)}
  text-align: center;
`;

const CoinsHeader = styled.header`
  padding-bottom: 32px;
  border-bottom: 3px solid ${black};
`;

const CoinsTitle = styled.h3`
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: bold;
  ${media.desktop`
    font-size: 22px;
  `}
`;

const TotalCoin = styled.div`
  ${Oswald}
  display: inline-flex;
  align-items: center;
  font-size: 29px;
`;

const TotalCoinText = styled.span`
  margin-left: 8px;
`;

const CoinHistory = props => (
  <Wrapper>
    <UserInfo>
      <Email>
        {props.userIdentity}
      </Email>
      <LinkButton href={props.editAccountPath}>
        계정 관리
      </LinkButton>
    </UserInfo>
    <Coins>
      <CoinsHeader>
        <CoinsTitle>코인 내역</CoinsTitle>
        <TotalCoin>
          <CoinIcon size={32} fontSize={17} />
          <TotalCoinText>{props.totalCoin}</TotalCoinText>
        </TotalCoin>
      </CoinsHeader>
      {props.coins.map(coin => (
        <CoinItem {...coin} key={coin.id} />
      ))}
    </Coins>
  </Wrapper>
);

CoinHistory.propTypes = {
  userIdentity: PropTypes.string.isRequired,
  editAccountPath: PropTypes.string,
  coins: PropTypes.array.isRequired,
  totalCoin: PropTypes.number.isRequired
};

export default CoinHistory;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CurrentUserContext from 'components/contexts/CurrentUserContext';

import MainHeader from 'components/organisms/MainHeader';
import FlashMessage from 'components/organisms/FlashMessage';
import MainFooter from 'components/organisms/MainFooter';

const Main = styled.main.attrs({
  role: 'main'
})`
  position: relative;
`;

const CommonTemplate = ({
  currentUser, headerProps, flashProps, footerProps, children
}) => (
  <CurrentUserContext.Provider value={currentUser}>
    <MainHeader {...headerProps} user={currentUser} />

    {flashProps && <FlashMessage {...flashProps} />}

    <Main>
      {children}
    </Main>

    <MainFooter {...footerProps} />
  </CurrentUserContext.Provider>
);

CommonTemplate.propTypes = {
  currentUser: PropTypes.shape({
    isPresent: PropTypes.bool,
    isCertified: PropTypes.bool,
    email: PropTypes.string,
    coin: PropTypes.number
  }),
  overlay: PropTypes.node,
  headerProps: PropTypes.object,
  flashProps: PropTypes.object,
  footerProps: PropTypes.object
};

export default CommonTemplate;

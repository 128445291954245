import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';

import styled from 'styled-components';

import Swipeable from 'react-swipeable';

import CarouselButton from 'images/btn-carousel.svg';

import media from 'styles/media.js';
import HomeBannerBackground from 'components/atoms/HomeBannerBackground';
import HomeBannerSlide from 'components/molecules/HomeBannerSlide';

import { black, lightGray } from 'styles/palette.js';
import { maxWidth } from 'styles/grid.js';

const Banner = styled(Swipeable).attrs({
  nodeName: 'section'
})`
  position: relative;
  margin-bottom: 24px;
  overflow: hidden;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  ${media.desktop`
    margin-bottom: 32px;
  `}
`;

const Slides = styled.div`
  display: flex;
  position: relative;
`;

const Buttons = styled.div`
  ${media.mobile`
    display: none;
  `}
  position: absolute;
  top: 240px;
  right: 0;
  left: 0;
  width: 100%;
  max-width: ${maxWidth}px;
  margin: auto;
`;

const Button = styled.button`
  position: absolute;
  cursor: pointer;
  transition: opacity 200ms;
  opacity: .56;
  ${props => (props.type === 'next' ? `
    transform: translateY(-50%) rotate(180deg);
    right: 20px;
  ` : `
    transform: translateY(-50%);
    left: 20px;
  `)}
  &:hover {
    opacity: 1;
  }
`;

const Pagination = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: center;
`;

const Bullet = styled.button`
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 4px;
  background-color: ${props => (props.active ? black : lightGray)};
  transition: background-color 100ms;
`;

export default class HomeBanner extends React.Component {
  static propTypes = {
    banners: PropTypes.array.isRequired
  }

  state = {
    beforeIndex: 0,
    index: 0,
    // https://github.com/yannickcr/eslint-plugin-react/issues/1697
    // eslint-disable-next-line react/no-unused-state
    width: 0
  }

  componentDidMount = () => {
    this.handleWidth();
    window.addEventListener('resize', this.handleWidth);
    if (this.props.banners.length > 1) {
      this.handleInterval();
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  }

  handleInterval = () => {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.handleIndex(this.state.index + 1);
    }, 5000);
  }

  handleWidth = () => {
    // https://github.com/yannickcr/eslint-plugin-react/issues/1697
    // eslint-disable-next-line react/no-unused-state
    this.setState({ width: document.body.offsetWidth });
  }

  handleIndex = (index) => {
    const lastIndex = this.props.banners.length - 1;
    let afterIndex = index;
    if (index < 0) {
      afterIndex = lastIndex;
    } else if (index > lastIndex) {
      afterIndex = 0;
    }
    this.setState({
      beforeIndex: afterIndex,
      index: afterIndex
    });
  }

  swiping = (e, deltaX) => {
    clearInterval(this.interval);
    this.setState(state => ({
      index: state.beforeIndex + (deltaX / state.width)
    }));
  }

  swiped = (e, deltaX, deltaY, isFlick) => {
    let index = this.state.beforeIndex;
    if (isFlick) {
      if (deltaX > 0) {
        index += 1;
      } else {
        index -= 1;
      }
    } else {
      index = Math.round(this.state.index);
    }
    this.handleIndex(index);
  }

  rest = () => {
    if (this.state.index % 1 === 0) {
      this.handleInterval();
    }
  }

  render = () => {
    const { banners } = this.props;
    return (
      <Motion
        style={{
          index: spring(this.state.index)
        }}
        onRest={this.rest}
      >
        {style => (
          <Banner
            onSwiping={this.swiping}
            onSwiped={this.swiped}
            disabled={banners.length <= 1}
          >
            {banners.map((banner, index) => (
              <HomeBannerBackground
                image={banner.backgroundImage}
                color={banner.backgroundColor}
                opacity={1 - Math.abs(style.index - index)}
                key={banner.title}
              />
            ))}
            <Slides
              style={{ transform: `translateX(${style.index * -100}%)` }}
            >
              {banners.map(banner => (
                <HomeBannerSlide
                  {...banner}
                  key={banner.title}
                />
              ))}
            </Slides>
            {banners.length > 1
              && (
              <React.Fragment>
                <Buttons>
                  <Button type='prev' onClick={() => this.handleIndex(this.state.beforeIndex - 1)}>
                    <CarouselButton />
                  </Button>
                  <Button type='next' onClick={() => this.handleIndex(this.state.beforeIndex + 1)}>
                    <CarouselButton />
                  </Button>
                </Buttons>
                <Pagination>
                  {Array.from(Array(banners.length).keys()).map(index => (
                    <Bullet
                      active={this.state.beforeIndex === index}
                      key={index}
                      onClick={() => this.handleIndex(index)}
                    />
                  ))}
                </Pagination>
              </React.Fragment>
              )
            }
          </Banner>
        )}
      </Motion>
    );
  }
}

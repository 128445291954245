import React from 'react';
import PropTypes from 'prop-types';

import styled, { ThemeProvider } from 'styled-components';
import LazyLoad from 'react-lazyload';

import GiftCouponIcon from 'images/ic-giftcoupon.svg';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import ellipsis from 'styles/ellipsis.js';
import { gridItem } from 'styles/grid.js';
import {
  black, gray, white, red
} from 'styles/palette.js';


const Card = styled.article`
  ${props => props.grid && gridItem(props.grid.mobile, props.grid.desktop)}
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  ${media.desktop`
    margin-bottom: 20px;
  `}
`;

const Image = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 12px;
  ${media.desktop`
    margin-bottom: 20px;
  `}
`;

const Body = styled.div`
  display: flex;
  flex-flow: column;
  ${media.mobile`
    ${props => props.theme.type === 'large' && `
      text-align: center;
    `}
  `}
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  ${media.mobile`
    ${props => props.theme.type === 'large' && `
      justify-content: center;
    `}
  `}
`;

const Title = styled.h3`
  ${ellipsis}
  font-size: 16px;
  font-weight: bold;
  ${media.mobile`
    ${props => props.theme.type === 'large' && `
      font-size: 20px;
    `}
  `}
  ${media.desktop`
    font-size: 18px;
  `}
`;

const StyledGiftCouponIcon = styled(GiftCouponIcon)`
  flex: 0 0 auto;
  width: 20px;
  margin-left: 4px;
`;

const Description = React.memo(styled.p`
  ${ellipsis}
  margin-top: 8px;
  color: ${gray};
  font-size: 13px;
  ${media.desktop`
    margin-top: 10px;
  `}
`);

const AdultWrapper = styled.div`
  position: relative;
`;

const Adult = styled.span`
  ${Oswald}
  display: flex;
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 28px;
  height: 28px;
  margin-top: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${gray};
  color: ${white};
  font-size: 14px;
  line-height: 1;
`;

const Labels = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-flow: column;
`;

const Label = styled.span`
  ${props => props.Oswald && Oswald}
  display: inline-block;
  width: 39px;
  padding: 6px;
  background-color: ${props => (props.color)};
  color: ${white};
  font-size: ${props => (props.Oswald ? '14px' : '13px')};
  font-weight: bold;
  line-height: 14px;
  text-align: center;
`;

const ComicCard = props => (
  <ThemeProvider theme={{ type: props.type }}>
    <Card grid={props.grid}>
      <a href={props.path}>
        <AdultWrapper>
          <LazyLoad height={200} once offset={400}>
            <Image src={props.thumbnail} alt={props.title} />
          </LazyLoad>
          {props.adult
            && <Adult>19</Adult>
          }
        </AdultWrapper>
        <Body>
          <Heading>
            <Title>
              {props.title}
            </Title>
            {props.rentable
              && <StyledGiftCouponIcon />
            }
          </Heading>
          {props.genres && <Description>{props.genres}</Description>}
          {props.artist && <Description>{props.artist}</Description>}
          {props.children}
        </Body>
        <Labels>
          {props.new
            && <Label color={black}>신작</Label>
          }
          {props.update
            && <Label color={black} Oswald>UP</Label>
          }
          {props.dormant
            && <Label color={gray} Oswald>휴재</Label>
          }
          {props.discount
            && <Label color={red} Oswald>SALE</Label>
          }
        </Labels>
      </a>
    </Card>
  </ThemeProvider>
);

ComicCard.propTypes = {
  thumbnail: PropTypes.string,
  grid: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.number),
    PropTypes.bool
  ]),
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  genres: PropTypes.string,
  artist: PropTypes.string,
  path: PropTypes.string,
  adult: PropTypes.bool,
  new: PropTypes.bool,
  update: PropTypes.bool,
  dormant: PropTypes.bool,
  discount: PropTypes.bool,
  rentable: PropTypes.bool,
  children: PropTypes.node
};

ComicCard.defaultProps = {
  grid: {
    mobile: 6,
    desktop: 2
  }
};

export default ComicCard;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Media from 'react-media';

import ListHeader from 'components/atoms/ListHeader';
import MoreButton from 'components/atoms/MoreButton';
import ComicCard from 'components/molecules/ComicCard';

import media, { mediaQuery } from 'styles/media.js';
import ellipsis from 'styles/ellipsis.js';
import { gridContainer } from 'styles/grid.js';
import { gray } from 'styles/palette.js';

const List = styled.section`
  ${gridContainer}
  margin-top: 24px;
  margin-bottom: 24px;
  ${media.desktop`
    margin-top: 32px;
    margin-bottom: 32px;
  `}
`;

const Cards = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

const Text = styled.p`
  ${ellipsis}
  margin-top: 8px;
  color: ${gray};
  font-size: 13px;
`;

const Synopsis = styled.p`
  display: inline-block;
  max-height: 3.2em;
  margin-top: 8px;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.6;
  word-break: break-all;
`;

export default class ComicCardList extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    comics: PropTypes.array.isRequired,
    path: PropTypes.string,
    type: PropTypes.string,
    length: PropTypes.arrayOf(PropTypes.number)
  }

  static defaultProps = {
    type: 'small'
  }

  maxLength = {
    desktop: this.props.type === 'large' ? 4 : 6,
    mobile: this.props.type === 'large' ? 3 : 4
  }

  render = () => (
    <List>
      {this.props.title && (
        <ListHeader
          title={this.props.title}
          subtitle={this.props.subtitle}
        />
      )}
      <Media query={mediaQuery.desktop}>
        {matches => (
          <React.Fragment>
            <Cards>
              {this.props.comics
                .slice(0, matches ? this.maxLength.desktop : this.maxLength.mobile)
                .map(comic => (
                  <ComicCard
                    {...comic}
                    thumbnail={
                      (!matches && this.props.type === 'large') ? comic.image : comic.thumbnail
                    }
                    grid={{
                      mobile: this.props.type === 'large' ? 12 : 6,
                      desktop: this.props.type === 'large' ? 3 : 2
                    }}
                    type={this.props.type}
                    key={comic.title}
                  >
                    {comic.text
                      && (
                      <Text>
                        {comic.text}
                      </Text>
                      )
                    }
                    {(comic.synopsis && !matches && this.props.type === 'large')
                      && (
                      <Synopsis>
                        {comic.synopsis}
                      </Synopsis>
                      )
                    }
                  </ComicCard>
                ))
              }
            </Cards>
            {this.props.path
              && (this.props.comics
                .length >= (matches ? this.maxLength.desktop : this.maxLength.mobile))
              && <MoreButton path={this.props.path} />
            }
          </React.Fragment>
        )}
      </Media>
    </List>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { gray } from 'styles/palette.js';

const Wrapper = styled.section`
  ${gridContainer};
  margin-top: 24px;
  ${media.desktop`
    margin-top: 32px;
  `}
`;

const Title = styled.h1`
  ${gridItem(12)}
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const Term = styled.article`
  ${gridItem(12)}
  word-break: keep-all;
`;

const Name = styled.h2`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
`;

const Clause = styled.div`
  margin-bottom: 3px;
  color: ${gray};
  font-size: 14px;
`;

const Content = styled.p`
  margin-bottom: 32px;
  line-height: 1.6;
  white-space: pre-line;
`;

const Paragraphs = styled.ol`
  margin-bottom: 32px;
  padding: 0;
  list-style-type: none;
  counter-reset: item;
`;

const Paragraph = styled.li`
  margin-bottom: 1.6em;
  line-height: 1.6;
  counter-increment: item;
  ${props => (props.disc || `
    &::before {
      content: counter(item) '. ';
      font-weight: bold;
    }
  `)}
`;

const ParagraphContent = styled.span``;

const SubParagraphs = styled.ol`
  margin-top: 1.6em;
  margin-bottom: 32px;
  padding-left: 24px;
  list-style-type: ${props => (props.disc ? 'disc' : 'lower-alpha')};
`;

const Subparagraph = styled.li`
  margin-bottom: 1.6em;
`;

const Terms = (props) => {
  let index = 0;
  return (
    <Wrapper>
      <Title>
        {props.title}
      </Title>
      {props.terms.map((term) => {
        if (term.name && !term.bylaw) {
          index += 1;
        }
        return (
          <Term key={term.name || term.content}>
            {term.name
              && (
              <Name>
                <Clause>
                  {term.bylaw
                    || `제${index}조 `
                  }
                </Clause>
                {term.name}
              </Name>
              )
            }
            {term.content
              && (
              <Content>
                {term.content}
              </Content>
              )
            }
            {term.paragraphs
              && (
              <Paragraphs>
                {term.paragraphs.map(paragraph => (
                  <Paragraph key={paragraph.content || paragraph} disc={term.disc}>
                    {paragraph.content
                      ? (
                        <ParagraphContent key={paragraph.content}>
                          <span dangerouslySetInnerHTML={{ __html: paragraph.content }} />
                          <SubParagraphs disc={term.disc}>
                            {paragraph.subparagraphs.map(subparagraph => (
                              <Subparagraph
                                key={subparagraph}
                                dangerouslySetInnerHTML={{ __html: subparagraph }}
                              />
                            ))}
                          </SubParagraphs>
                        </ParagraphContent>
                      )
                      : <span dangerouslySetInnerHTML={{ __html: paragraph }} />
                    }
                  </Paragraph>
                ))}
              </Paragraphs>
              )
            }
          </Term>
        );
      })}
    </Wrapper>
  );
};

Terms.propTypes = {
  title: PropTypes.string.isRequired,
  terms: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
    paragraphs: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])),
    bylaw: PropTypes.bool
  }))
};

export default Terms;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'styles/media.js';
import {
  black, gray, white, red, freeGreen
} from 'styles/palette.js';
import Oswald from 'styles/Oswald.js';

const Label = styled.div`
  ${props => (
    ((props.sale || props.adult || props.free) && `${Oswald()} font-size: 14px; line-height: 1;`)
    || 'font-size: 13px; line-height: 14px'
  )};
  display: inline-flex;
  margin-left: 4px;
  padding: ${props => (props.adult ? '7px 7px 7px 8px' : '6px')};
  ${props => (props.adult && 'border-radius: 50%')};
  background-color: ${props => ((props.sale && red) || (props.new && black) || (props.free && freeGreen) || gray)};
  transform: translateY(-50%);
  color: ${white};
  font-weight: bold;
  vertical-align: top;
  &:first-child {
    margin: 0;
  }
`;

function Labels({ label }) {
  const LabelsWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    ${media.desktop`
      left: 20px;
    `}
  `;

  return (
    <LabelsWrapper>
      {label.sale && <Label sale>SALE</Label>}
      {label.new && <Label new>신작</Label>}
      {label.adult && <Label adult>19</Label>}
      {label.free && <Label free>FREE</Label>}
      {label.dormant && <Label dormant>휴재</Label>}
    </LabelsWrapper>
  );
}

Labels.propTypes = {
  label: PropTypes.object
};

export default Labels;

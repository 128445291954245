import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import ellipsis from 'styles/ellipsis.js';
import { gray, lightGray, white } from 'styles/palette.js';

const Item = styled.a`
  display: flex;
  position: relative;
  width: 100%;
  height: 70px;
  margin-bottom: 8px;
  justify-content: space-between;
  ${media.desktop`
    height: 120px;
    margin-bottom: 0;
    border-bottom: 1px solid ${lightGray};
  `}
`;

const Image = styled.img`
  height: 100%;
  order: 2;
  ${media.mobile`
    margin-right: 8px;
  `}
`;

const Rank = styled.div`
  ${Oswald}
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  color: ${gray};
  font-size: 28px;
  ${media.mobile`
    width: 66px;
  `}
  ${media.desktop`
    width: 90px;
    font-size: 42px;
  `}
`;

const Text = styled.div`
  margin-top: 12px;
  margin-right: 16px;
  flex: 1 1 auto;
  overflow: hidden;
  ${media.desktop`
    margin-top: 36px;
    margin-left: 16px;
  `}
`;

const Title = styled.h3`
  ${ellipsis}
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  ${media.desktop`
    margin-bottom: 16px;
    font-size: 20px;
  `}
`;

const Description = styled.p`
  ${ellipsis}
  color: ${gray};
  font-size: 13px;
  ${media.desktop`
    font-size: 14px;
  `}
`;

const Adult = styled.span`
  ${Oswald}
  display: inline-flex;
  position: absolute;
  right: 10px;
  bottom: 2px;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: ${gray};
  color: ${white};
  font-size: 11px;
  text-align: center;
  line-height: 1;
  ${media.desktop`
    width: 28px;
    height: 28px;
    font-size: 14px;
    right: 4px;
  `}
`;

const RankingItem = props => (
  <Item href={props.path}>
    <Rank>
      {props.rank}
    </Rank>
    <Text>
      <Title>
        {props.title}
      </Title>
      <Description>{props.genres}</Description>
    </Text>
    <Image src={props.image} alt={props.title} />
    {props.adult && <Adult>19</Adult>}
  </Item>
);

RankingItem.propTypes = {
  title: PropTypes.string.isRequired,
  genres: PropTypes.string.isRequired,
  rank: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  path: PropTypes.string,
  adult: PropTypes.bool
};

export default RankingItem;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CoinIcon from 'components/atoms/CoinIcon';
import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { black, white } from 'styles/palette.js';

const MyCoin = styled.div`
  display: flex;
  height: 64px;
  background-color: ${black};
  align-items: center;
  justify-content: center;
  ${media.desktop`
    height: 72px;
  `}
`;

const CoinHeading = styled.h2`
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: bold;
  ${media.mobile`
    display: none;
  `}
`;

const CoinNumber = styled.span`
  ${Oswald}
  margin-left: 8px;
  color: ${white};
  font-size: 28px;
  line-height: 1;
`;

const CoinWallet = ({ coin }) => (
  <div>
    <CoinHeading bold>
      보유 코인
    </CoinHeading>
    <MyCoin>
      <CoinIcon size={32} fontSize={17} />
      <CoinNumber>{coin}</CoinNumber>
    </MyCoin>
  </div>
);

CoinWallet.propTypes = {
  coin: PropTypes.number.isRequired
};

export default CoinWallet;

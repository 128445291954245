import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';

import Rails from 'rails-ujs';

import media from 'styles/media.js';
import { red, white } from 'styles/palette.js';
import { primaryButton } from 'styles/button.js';

import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';
import ListHeader from 'components/atoms/ListHeader';
import PasswordField from 'components/atoms/PasswordField';

const Container = styled.section`
  display: flex;
  flex-flow: column;
  margin-top: 8px;
  padding: 16px;
  background-color: ${white};
  ${media.desktop`
    max-width: 414px;
    margin: 80px auto 40px;
    padding: 32px;
  `}
`;

const Description = styled.span`
  line-height: 1.6;
  text-align: center;
  margin-bottom: 18px;
`;

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  margin-top: 8px;
  width: 100%;
`;

const ErrorMessage = styled.p`
  width: 100%;
  color: ${red};
  font-size: 14px;
`;

export default class ChangePasswordForm extends React.Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    paths: PropTypes.object.isRequired
  }

  state = {
    errorMessage: ''
  }

  handleInput = (event) => {
    event.persist();
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'put',
      url: this.props.paths.password,
      data: {
        token: this.props.token,
        password: this.state.password
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then(() => {
      window.location.href = this.props.paths.home;
    }).catch((error) => {
      if (error.response.status === 422) {
        this.setState(() => ({ errorMessage: error.response.data.message }));
      } else {
        window.location.href = error.response.data.url;
      }
    });
  }

  render = () => (
    <Container>
      <GrayBackgroundStyle />
      <ListHeader title='비밀번호 재설정' />
      <Description>
        새로 설정할 비밀번호를 입력해 주세요.
      </Description>
      <form onSubmit={this.handleSubmit}>
        <PasswordField
          id='password'
          name='password'
          autoComplete='current-password'
          error={this.state.errorMessage}
          onChange={this.handleInput}
        />
        {this.state.errorMessage
          && (
          <ErrorMessage>
            {this.state.errorMessage}
          </ErrorMessage>
          )
        }
        <Submit value='완료' />
      </form>
    </Container>
  );
}

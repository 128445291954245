import React from 'react';
import PropTypes from 'prop-types';

import CurrentUserContext from 'components/contexts/CurrentUserContext';

import ComicInfo from 'components/organisms/ComicInfo';
import EpisodeList from 'components/organisms/EpisodeList';
import EpisodeListForMarketing from 'components/organisms/EpisodeListForMarketing';
import { black } from 'styles/palette.js';

import useBodyBackgroundColor from 'components/hooks/useBodyBackgroundColor';

const ComicPage = ({
  marketing = false,
  archived = false,
  user,
  info,
  episodes,
}) => {
  useBodyBackgroundColor(black);

  return (
    <CurrentUserContext.Provider value={user}>
      <ComicInfo {...info} archived={archived} />
      {
        marketing
          ? <EpisodeListForMarketing {...episodes} comicUrl={info.url} />
          : <EpisodeList {...episodes} comicUrl={info.url} archived={archived} />
      }
    </CurrentUserContext.Provider>
  );
};

export default ComicPage;

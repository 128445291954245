import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Oswald from 'styles/Oswald.js';

import { white, black } from 'styles/palette.js';

const Counter = React.memo(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  background-color: ${black};
  color: white;
`);

const Count = React.memo(styled.span`
  ${Oswald}
  margin-left: 8px;
  color: ${white};
  font-size: 28px;
  line-height: 1;
`);

const ItemCounter = props => (
  <Counter className={props.className}>
    {props.image}
    <Count>
      {props.count}
    </Count>
  </Counter>
);

ItemCounter.propTypes = {
  image: PropTypes.node.isRequired,
  count: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default ItemCounter;

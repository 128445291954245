import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import HomeTemplate from 'components/templates/HomeTemplate';
import HomeBanner from 'components/organisms/HomeBanner';
import ComicCardList from 'components/organisms/ComicCardList';
import KeywordList from 'components/organisms/KeywordList';
import RankingCardList from 'components/organisms/RankingCardList';
import EventList from 'components/organisms/EventList';
import ScrollUpButton from 'components/organisms/ScrollUpButton';
import RecentlyViewedEpisodeBar from 'components/organisms/RecentlyViewedEpisodeBar';

export default class HomePage extends React.Component {
  static propTypes = {
    layoutProps: PropTypes.object.isRequired,
    presentsBannerProps: PropTypes.object,
    homeBanners: PropTypes.array,
    curations: PropTypes.array,
    recentlyViewedComics: PropTypes.object
  }

  state = {
    overlay: <ScrollUpButton />
  }

  componentDidMount() {
    this.handleOverlay();
  }

  closeTooltip = () => {
    Cookies.set('no-add-home', true, { expires: 7 });
    this.handleOverlay();
  }

  handleOverlay = () => {
    if (this.props.recentlyViewedEpisode) {
      this.setState({
        overlay: <RecentlyViewedEpisodeBar {...this.props.recentlyViewedEpisode} />
      });
    } else {
      this.setState({ overlay: <ScrollUpButton /> });
    }
  }

  render = () => (
    <HomeTemplate
      {...this.props.layoutProps}
      overlay={this.state.overlay}
    >
      {this.props.homeBanners.length > 0 && (
        <HomeBanner banners={this.props.homeBanners} />
      )}
    </HomeTemplate>
  )
}

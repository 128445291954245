import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import { gridContainer } from 'styles/grid.js';
import {
  black, white, gray, darkGray
} from 'styles/palette.js';

const Footer = styled.footer`
  background-color: ${black};
  text-align: center;
  ${media.mobile`
    padding-bottom: 92px;
  `}
  ${media.desktop`
    text-align: left;
  `}
`;

const Notice = styled.div`
  padding: 16px 0;
  background-color: ${darkGray};
  color: ${white};
  font-size: 12px;
  ${media.desktop`
    font-size: 14px;
  `}
`;

const DesktopWrapper = styled.div`
  ${media.desktop`
    ${gridContainer('desktop')}
    padding: 0 20px;
    justify-content: flex-start;
  `}
`;

const NoticeLabel = styled.span`
  margin-right: 12px;
  font-weight: bold;
`;

const NoticeLink = styled.a`
  color: white;
`;

const Links = styled.div`
  margin: 16px auto;
  ${media.desktop`
    margin: 34px 0 24px;
  `}
`;

const Link = styled.a`
  margin: 0 8px;
  color: ${white};
  font-size: 12px;

  ${props => props.bold && `
    font-weight: bold;
  `}

  ${media.desktop`
    margin: 0 24px 0 0;
    font-size: 14px;
  `}
`;

const Informations = styled.div`
  width: 100%;
  padding: 0 16px 16px;
  ${media.desktop`
    margin-bottom: 32px;
    padding: 0;
    white-space: pre-wrap;
  `}
`;

const Information = styled.span`
  color: ${gray};
  font-size: 12px;
  line-height: 1.6;

  &::before {
    ${props => (props.newLine || 'content: " | ";')}
    display: inline;
  }

  &:first-of-type::before {
    display: none;
  }
`;

const InformationPath = styled.a`
  text-decoration: underline;
`;

export default class MainFooter extends React.PureComponent {
  static propTypes = {
    links: PropTypes.array.isRequired,
    information: PropTypes.array.isRequired,
    notice: PropTypes.object.isRequired
  };

  renderLink = this.props.links.map(link => (
    <Link href={link.path} key={link.text} bold={link.bold}>
      {link.text}
    </Link>
  ));

  renderInformation = this.props.information.map(information => (
    <Information key={information.text} newLine={information.newLine}>
      {information.newLine && <br />}
      {`${information.name} `}
      {information.path
        ? (
          <InformationPath href={information.path}>
            {information.text}
          </InformationPath>
        )
        : information.text
      }
    </Information>
  ));

  render = () => (
    <Footer>
      <Notice>
        <DesktopWrapper>
          <NoticeLabel>
            공지
          </NoticeLabel>
          <NoticeLink href={this.props.notice.path}>
            {this.props.notice.text}
          </NoticeLink>
        </DesktopWrapper>
      </Notice>
      <DesktopWrapper>
        <Links>
          {this.renderLink}
        </Links>
        <Informations>
          {this.renderInformation}
        </Informations>
      </DesktopWrapper>
    </Footer>
  );
}

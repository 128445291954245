import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { white } from 'styles/palette.js';
import media from 'styles/media.js';

import useLockBodyScroll from 'components/hooks/useLockBodyScroll';
import useKeyPress from 'components/hooks/useKeyPress';

const Background = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  width: 100%;
  max-width: 414px;
  margin: 0 16px;
  padding: 32px 16px;
  background-color: ${white};
  align-self: center;
  text-align: center;
  ${media.desktop`
    padding: 32px;
  `}
`;

const Close = styled.button.attrs({
  type: 'button'
})`
  margin-top: 16px;
`;

function Modal({ children, onClose }) {
  useLockBodyScroll();
  useKeyPress(27, onClose);

  return (
    <Background onClick={onClose}>
      <Body onClick={e => e.stopPropagation()}>
        {children}
        <Close onClick={onClose}>취소</Close>
      </Body>
    </Background>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
};

export default Modal;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import CoinIcon from 'components/atoms/CoinIcon';

import Oswald from 'styles/Oswald.js';
import { lightGray, gray } from 'styles/palette.js';

const Item = styled.div`
  display: flex;
  padding: 16px 0 13px;
  border-bottom: 1px solid ${lightGray};
  flex-flow: row wrap;
  align-items: center;
  text-align: left;
`;

const Wrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

const Reason = styled.p`
  word-break: break-all;
`;

const Date = styled.p`
  margin-top: 8px;
  color: ${gray};
  font-size: 14px;
`;

const Value = styled.span`
  ${Oswald}
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  margin-left: 8px;
  white-space: pre;
`;

const CoinItem = props => (
  <Item>
    <Wrapper>
      <Reason>
        {props.reason}
      </Reason>
      <Date>
        {props.date}
        {props.expiryDate
          && ` | ${props.expiryDate}까지`
        }
      </Date>
    </Wrapper>
    {props.value > 0
      ? (
        <Value>
          {'+ '}
          <CoinIcon size={22} fontSize={11} />
          {` ${props.value}`}
        </Value>
      )
      : (
        <Value>
          {'- '}
          <CoinIcon size={22} fontSize={11} />
          {` ${props.value * -1}`}
        </Value>
      )
    }
  </Item>
);

CoinItem.propTypes = {
  reason: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  expiryDate: PropTypes.string,
  value: PropTypes.number.isRequired
};

export default CoinItem;

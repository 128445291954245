import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';

import Rails from 'rails-ujs';

import Modal from 'components/molecules/Modal';
import PurchasePopup from 'components/molecules/PurchasePopup';
import ViewerHeader from 'components/molecules/ViewerHeader';
import ViewerFooter from 'components/molecules/ViewerFooter';
import EventList from 'components/organisms/EventList';

import BylineImage from 'images/img-logo-byline.png';

import SecretCodePattern from 'images/img-pattern-secretcode.png';

import { primaryButton } from 'styles/button.js';
import { lightGray, white, freeGreen } from 'styles/palette.js';

const HeaderHeight = 60;
const FooterHeight = 60;

const SecretCodeCurtain = styled.div`
  position: fixed;
  top: ${props => (props.isNavigationActive ? HeaderHeight : 0)}px;
  right: 0;
  left: 0;
  padding: 16px;
  background-color: ${freeGreen};
  opacity: .9;
  font-weight: bold;
  text-align: center;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .06;
    background-image: url(${SecretCodePattern});
  }
`;

const SecretCodeNotice = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const PurchaseButton = styled.button`
  ${primaryButton}
  margin: 0 8px;
  padding: 12px 16px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  padding: ${HeaderHeight}px 0 ${FooterHeight}px;
  background-color: ${props => props.background};
`;

const Cuts = styled.main`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Cut = styled.img`
  flex: 0 0 auto;
  user-drag: none;
  user-select: none;
`;

const Byline = styled.img`
  margin: 24px 0 32px;
  align-self: center;
`;

export default class Viewer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    cuts: PropTypes.array,
    path: PropTypes.object.isRequired,
    subscribe: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    isUser: PropTypes.bool,
    purchaseInfo: PropTypes.object,
    comicId: PropTypes.number.isRequired,
    episodeId: PropTypes.number.isRequired,
    events: PropTypes.array
  }

  state = {
    navigate: true,
    isHidden: this.props.type === 'buy'
  }

  componentDidMount = () => {
    window.addEventListener('scroll', () => this.handleScroll());
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', () => this.handleScroll());
  }

  purchaseAll = () => {
    axios({
      method: 'post',
      url: this.props.purchaseInfo.path,
      data: {
        comic_id: this.props.comicId,
        episode_ids: this.props.purchaseInfo.episodeIds,
        redirect_episode_id: this.props.episodeId
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => {
      window.location.href = error.response.data.url;
    });
  }

  purchaseEpisode = () => {
    axios({
      method: 'post',
      url: this.props.purchaseInfo.path,
      data: {
        comic_id: this.props.comicId,
        episode_ids: [this.props.episodeId],
        redirect_episode_id: this.props.episodeId
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => {
      window.location.href = error.response.data.url;
    });
  }

  purchaseBySecretCode = (path, value) => {
    axios({
      method: 'post',
      url: path,
      data: {
        secret_code: value
      },
      headers: {
        'Content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => {
      this.setState(() => ({ error: error.response.data.message }));
    });
  }

  closePurchasePopup = () => {
    if (this.props.type === 'buy') {
      window.location.href = this.props.path.comic;
    } else {
      this.setState(() => ({ isHidden: false }));
    }
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const scrollBottom = window.innerHeight + scrollTop;
    const bodyHeight = document.body.scrollHeight;

    if (bodyHeight - scrollBottom < FooterHeight) {
      this.handleNavigate(true);
    } else {
      this.handleNavigate(false);
    }
  }

  handleNavigate = (value) => {
    this.setState(() => ({ navigate: value }));
  }

  openPurchasePopup = () => {
    this.setState(() => ({ isHidden: true }));
  }

  preventContext = (e) => {
    e.preventDefault();
  }

  renderCuts = () => this.props.cuts.map((cut, index) => (
    <Cut
      onContextMenu={this.preventContext}
      src={cut}
      alt={`컷 ${index}`}
      key={cut}
    />
  ));

  render = () => (
    <Wrapper background={this.state.isHidden ? lightGray : white}>
      <ViewerHeader
        active={this.state.navigate}
        homePath={this.props.path.home}
        comicPath={this.props.path.comic}
        isUser={this.props.isUser}
        subscribe={this.props.subscribe}
        height={HeaderHeight}
      >
        {this.props.title}
      </ViewerHeader>
      { (this.props.type === 'secretCode' && !this.state.isHidden)
        && (
        <SecretCodeCurtain isNavigationActive={this.state.navigate}>
          <SecretCodeNotice>
            에피소드 구매 시 편리하게 감상하실 수 있습니다.
          </SecretCodeNotice>
          <PurchaseButton onClick={() => this.openPurchasePopup()}>
            구매하기
          </PurchaseButton>
        </SecretCodeCurtain>
        )
      }
      { this.state.isHidden
        ? (
          <Modal onClose={this.closePurchasePopup}>
            <PurchasePopup
              closePopup={this.closePurchasePopup}
              isUser={this.props.isUser}
              episode={{
                price: this.props.purchaseInfo.episodePrice,
                hasSecretCode: this.props.purchaseInfo.hasSecretCode,
                secretCodePath: this.props.purchaseInfo.secretCodePath
              }}
              purchaseAll={this.purchaseAll}
              purchaseEpisode={this.purchaseEpisode}
              purchaseBySecretCode={this.purchaseBySecretCode}
              sumPrice={this.props.purchaseInfo.sumPrice}
              error={this.state.error}
            />
          </Modal>
        )
        : (
          <Cuts onClick={() => this.handleNavigate(!this.state.navigate)}>
            {this.renderCuts()}
            <Byline src={BylineImage} alt='MAXIM COMICS LOGO' />
            {this.props.events
            && <EventList events={this.props.events} />
          }
          </Cuts>
        )
      }
      <ViewerFooter
        active={this.state.navigate}
        isPreviousShowable={this.props.path.isPreviousShowable}
        isNextShowable={this.props.path.isNextShowable}
        isPreviousRentable={this.props.path.isPreviousRentable}
        isNextRentable={this.props.path.isNextRentable}
        previousPath={this.props.path.previous}
        nextPath={this.props.path.next}
        height={FooterHeight}
      />
    </Wrapper>
  );
}

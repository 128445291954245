import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { lightGray, white, red } from 'styles/palette.js';
import { gridItem } from 'styles/grid.js';

const Label = styled.label`
  ${props => gridItem(null, props.half ? 6 : 12)}
  display: flex;
  position: relative;
  margin-bottom: 4px;
  padding: 24px 16px;
  align-items: center;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 3px solid ${lightGray};
  }
`;

const Radio = styled.input.attrs({
  type: 'radio'
})`
  display: none;
`;

const RadioButton = styled.div`
  width: 18px;
  height: 18px;
  margin: 4px 12px 4px 4px;
  border: 4px solid ${white};
  border-radius: 50%;
  box-shadow: 0 0 0 3px ${lightGray};
  ${Radio}:checked + & {
    background-color: ${red};
  }
`;

const ShopRadioButton = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Label half={props.half}>
      <Radio {...otherProps} />
      <RadioButton />
      {props.children}
    </Label>
  );
};

ShopRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  half: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default ShopRadioButton;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import CoinIcon from 'components/atoms/CoinIcon';

import readCompleteIcon from 'images/ic-read-complete.svg';
import GiftCouponIcon from 'images/ic-giftcoupon.svg';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import ellipsis from 'styles/ellipsis.js';
import {
  black, gray, lightGray, white, red, freeGreen
} from 'styles/palette.js';

const Card = styled.a`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  position: relative;
  margin-bottom: 8px;
`;

const Up = styled.span`
  ${Oswald}
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 12px;
  background-color: ${black};
  color: ${white};
  font-size: 14px;
  line-height: 1;
`;

const Thumbnail = styled.img`
  width: 100%;
  align-self: flex-start;
  ${media.desktop`
    width: 295px;
  `}
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
  background-color: ${props => (props.light ? '#585858' : '#333333')};
  ${media.desktop`
    flex: 1;
    align-items: center;
    overflow: hidden;
  `}
`;

const Index = styled.span`
  ${Oswald}
  display: inline-block;
  width: 66px;
  flex: 0 0 auto;
  color: ${gray};
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  ${media.desktop`
    font-size: 32px;
  `}
`;

const Text = styled.div`
  margin-right: 16px;
  flex: 1;
  overflow: hidden;
`;

const Title = styled.h3`
  ${ellipsis}
  margin-bottom: 8px;
  color: ${white};
  font-size: 15px;
  font-weight: bold;
  ${media.desktop`
    margin-bottom: 10px;
    font-size: 20px;
  `}
`;

const Purchased = styled.p`
  color: ${lightGray};
  font-size: 14px;
  font-weight: bold;
`;

const Archived = styled.p`
  color: ${red};
  font-size: 14px;
  font-weight: bold;
`;

const Rental = styled.p`
  display: flex;
  align-items: center;
  color: ${freeGreen};
  font-weight: bold;
`;

const Free = styled.span`
  ${Oswald}
  color: ${freeGreen};
`;

const StyledGiftCouponIcon = styled(GiftCouponIcon)`
  width: 20px;
  margin-right: 6px;
`;

const Coin = styled.p`
  ${Oswald};
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Price = styled.span`
  margin-left: 4px;
  color: ${props => props.color || white};
`;

const ReadCompleteIcon = styled(readCompleteIcon)`
  margin-right: 16px;
  flex: 0 0 auto;
`;

const EpisodeCard = props => (
  <Card onClick={props.onClick} href={props.path}>
    {
      props.up
      && <Up>UP</Up>
    }
    <LazyLoad height={120} once offset={240}>
      <Thumbnail src={props.thumbnail} alt={props.title} />
    </LazyLoad>
    <Info light={props.lastViewed}>
      <Index>{props.index}</Index>
      <Text>
        <Title>{props.title}</Title>
        {props.purchased ? (
          <Purchased>구매 완료</Purchased>
        ) : props.isOpenedBySecretCode ? (
          <Purchased>시크릿코드 적용 중</Purchased>
        ) : props.onRentaled ? (
          <Purchased>무료대여권 사용중</Purchased>
        ) : props.free ? (
          <Free>FREE</Free>
        ) : props.archived ? (
          <Archived>서비스 종료</Archived>
        ) : props.isRentable ? (
          <Rental>
            <StyledGiftCouponIcon />
            무료대여권
          </Rental>
        ) : props.discount ? (
          <Coin>
            <CoinIcon size={24} fontSize={12} color={white} bgColor={red} />
            <Price>{props.defaultPrice}</Price>
            {props.free ? (
              <Free>{' -> FREE'}</Free>
            ) : (
              <Price color={red}>{`-> ${props.price}`}</Price>
            )}
          </Coin>
        ) : (
          <Coin>
            <CoinIcon size={24} fontSize={12} />
            <Price>{props.price}</Price>
          </Coin>
        )}
      </Text>
      {props.read && <ReadCompleteIcon />}
    </Info>
  </Card>
);

EpisodeCard.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  read: PropTypes.bool,
  lastViewed: PropTypes.bool.isRequired,
  up: PropTypes.bool.isRequired,
  free: PropTypes.bool.isRequired,
  purchased: PropTypes.bool,
  price: PropTypes.number,
  defaultPrice: PropTypes.number,
  isOpenedBySecretCode: PropTypes.bool,
  onRentaled: PropTypes.bool,
  isRentable: PropTypes.bool,
  discount: PropTypes.bool,
  archived: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};


export default React.memo(EpisodeCard, () => true);

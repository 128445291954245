import React from 'react';

import styled from 'styled-components';

import Modal from 'components/molecules/Modal';
import ViewerHeader from 'components/molecules/ViewerHeader';

import { lightGray, white } from 'styles/palette.js';

const HeaderHeight = 60;
const FooterHeight = 60;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  padding: ${HeaderHeight}px 0 ${FooterHeight}px;
  background-color: ${props => props.background};
`;

const Popup = styled.div`
  width: 100%;
  max-width: 414px;
  background-color: ${white};
  align-self: center;
  text-align: center;
`;

const ViewerArchived = ({ title, path, subscribe }) => {
  function onClose() {
    window.location.href = path.comic;
  }
  return (
    <Wrapper background={lightGray}>
      <ViewerHeader
        active
        homePath={path.home}
        comicPath={path.comic}
        isUser={false}
        subscribe={subscribe}
        height={HeaderHeight}
      >
        {title}
      </ViewerHeader>
      <Modal onClose={onClose}>
        <Popup>
          서비스 종료된 작품입니다.
        </Popup>
      </Modal>
    </Wrapper>
  );
};

export default ViewerArchived;

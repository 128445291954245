import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import CoinIcon from 'components/atoms/CoinIcon';

import Oswald from 'styles/Oswald.js';
import { gray } from 'styles/palette.js';

const Item = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  white-space: pre;
`;

const Reward = styled.span`
  ${Oswald}
  font-size: 18px;
`;

const Validity = styled.p`
  margin-top: 8px;
  color: ${gray};
  font-size: 14px;
`;

const CouponItem = props => (
  <Item>
    <Title>
      {!!props.exchangeCoin
        && (
        <React.Fragment>
          <CoinIcon size={24} fontSize={12} />
          <Reward>
            {' '}
            {props.exchangeCoin}
            {' '}
          </Reward>
          지급 교환권
        </React.Fragment>
        )
      }
      {!!props.discountPrice
        && (
        <React.Fragment>
          코인 구매 시
          {' '}
          {props.discountPrice}
원 할인
        </React.Fragment>
        )
      }
      {!!props.discountPercentage
        && (
        <React.Fragment>
          코인 구매 시
          {' '}
          {props.discountPercentage}
% 할인
        </React.Fragment>
        )
      }
      {!!props.paybackBonuscoinPercentage
        && (
        <React.Fragment>
          구매 코인의
          {' '}
          {props.paybackBonuscoinPercentage}
% 보너스코인 추가
        </React.Fragment>
        )
      }
      {!!props.paybackBonuscoin
        && (
        <React.Fragment>
          코인 구매 시 보너스
          {' '}
          <CoinIcon size={24} fontSize={12} />
          <Reward>
            {' '}
            {props.paybackBonuscoin}
            {' '}
          </Reward>
          추가
        </React.Fragment>
        )
      }
    </Title>
    {props.validity
      && (
      <Validity>
        {props.validity}
      </Validity>
      )
    }
  </Item>
);

CouponItem.propTypes = {
  exchangeCoin: PropTypes.number,
  discountPrice: PropTypes.number,
  discountPercentage: PropTypes.number,
  paybackBonuscoinPercentage: PropTypes.number,
  paybackBonuscoin: PropTypes.number,
  validity: PropTypes.string
};

export default CouponItem;

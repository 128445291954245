import React from 'react';
import PropTypes from 'prop-types';

import styled, { css, keyframes } from 'styled-components';

import { black, freeGreen } from 'styles/palette.js';

const fadeOutKeyframes = keyframes`
  from { opacity: .9; }
  to { opacity: 0; }
`;

const fadeOutAnimation = css`
  500ms ease-in 5000ms forwards ${fadeOutKeyframes}
`;

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 84px;
  right: 16px;
  left: 16px;
  justify-content: center;
  opacity: .9;
  animation: ${fadeOutAnimation};
`;

const Message = styled.div`
  display: flex;
  padding: 24px;
  background-color: ${freeGreen};
  color: ${black};
  font-weight: bold;
`;

const ViewerFlashMessage = props => (
  <Wrapper>
    <Message>
      {props.text}
    </Message>
  </Wrapper>
);

ViewerFlashMessage.propTypes = {
  text: PropTypes.string.isRequired
};

export default ViewerFlashMessage;

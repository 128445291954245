import React from 'react';
import styled from 'styled-components';

function Viewers({ count }) {
  const MINIMUM_VIEWED_COUNT_TO_RENDER = 1000;

  const Description = styled.p`
    margin-top: 8px;
    font-size: 12px;
  `;

  return (
    count >= MINIMUM_VIEWED_COUNT_TO_RENDER && (
      <Description>
        {`현재 ${count.toLocaleString()}명이 보고 있는 작품입니다.`}
      </Description>
    )
  );
}

export default Viewers;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import FirstRankingItem from 'components/molecules/FirstRankingItem';
import RankingItem from 'components/molecules/RankingItem';

import media from 'styles/media.js';
import { gridItem, gridContainer } from 'styles/grid.js';

const List = styled.div`
  margin-bottom: 16px;
  &::before {
    ${gridItem(null, 12)}
  }
  ${media.desktop`
    ${gridContainer('desktop')}
    align-items: flex-start;
    margin-top: 32px;
    margin-bottom: 32px;
    &::before {
      content: '';
      border-top: 3px solid;
    }
  `}
`;

const Items = styled.div`
  ${gridContainer}
  ${gridItem(null, 7)}
`;

export default class RankingList extends React.Component {
  static propTypes = {
    rankings: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  state = {
    isClient: false
  }

  componentDidMount() {
    this.setState(() => ({ isClient: true }));
  }

  render = () => (
    this.state.isClient
    && (
    <List>
      <FirstRankingItem
        {...this.props.rankings[0]}
      />
      <Items>
        {[...this.props.rankings.slice(1)].map(ranking => (
          <RankingItem
            {...ranking}
            key={ranking.title}
          />
        ))}
      </Items>
    </List>
    )
  )
}

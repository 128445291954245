import '@babel/polyfill';
import ReactRailsUJS from 'react_ujs';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

import './dummy-images.js';

import 'normalize.css';
import 'styles/global.js';

const componentRequireContext = require.context('components', true, /^((?!\.test).)+$/);

Turbolinks.start();
window.Turbolinks = Turbolinks;

ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.detectEvents();

delete window.Turbolinks;

Rails.start();

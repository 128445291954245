import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';
import Rails from 'rails-ujs';

import Container from 'components/atoms/Container';
import GrayBackgroundStyle from 'components/atoms/GrayBackgroundStyle';
import Checkbox from 'components/atoms/Checkbox';
import PasswordField from 'components/atoms/PasswordField';
import ListHeader from 'components/atoms/ListHeader';

import FacebookIcon from 'images/ic-login-fb.svg';
import KakaotalkIcon from 'images/ic-login-kakaotalk.svg';

import { primaryButton, secondaryButton } from 'styles/button.js';
import {
  black, lightGray, red
} from 'styles/palette.js';

const Form = styled.form`
  width: 100%;
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
`;

const Textfield = styled.input.attrs({
  required: 'required'
})`
  width: 100%;
  margin-bottom: 16px;
  padding: 11px 14px;
  border: 2px solid ${props => (props.error ? red : black)};
  line-height: 1.6;
  text-align: left;
  &::placeholder {
    color: ${lightGray};
  }
`;

const ErrorMessage = styled.p`
  width: 100%;
  color: ${red};
  margin-bottom: 16px;
  font-size: 14px;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  margin-bottom: 20px;
`;

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
  width: 100%;
`;

const Links = styled.div`
  margin: 24px auto;
  text-align: center;
`;

const Link = styled.a`
  margin: 0 12px;
`;

const SNSLink = styled.a`
  ${secondaryButton}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
  font-weight: bold;
  svg {
    margin-right: 8px;
  }
`;

export default class LoginForm extends React.Component {
  static propTypes = {
    action: PropTypes.string.isRequired,
    paths: PropTypes.object.isRequired
  }

  state = {
    email: '',
    password: '',
    rememberMe: false,
    errorMessage: ''
  }

  shouldComponentUpdate = (nextProps, nextState) => (
    this.state.errorMessage !== nextState.errorMessage
  )

  handleInput = (event) => {
    event.persist();
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState(() => ({ [name]: value }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'post',
      url: this.props.action,
      data: {
        user: {
          email: this.state.email,
          password: this.state.password
        },
        remember_me: this.state.rememberMe
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then((response) => {
      window.location.href = response.headers.location;
    }).catch((error) => {
      this.setState(() => ({
        errorMessage: error.response.data.message
      }));
    });
  }

  render = () => (
    <Container>
      <GrayBackgroundStyle />
      <ListHeader title='LOGIN' Oswald />
      <Form onSubmit={this.handleSubmit}>
        <Label htmlFor='email'>
          맥심 기존 ID 또는 이메일 ID
        </Label>
        <Textfield
          placeholder='ID 또는 이메일'
          id='email'
          name='email'
          autoComplete='email'
          onChange={this.handleInput}
        />
        <Label htmlFor='password'>
          비밀번호
        </Label>
        <PasswordField
          id='password'
          name='password'
          autoComplete='current-password'
          error={this.state.errorMessage.length > 0}
          onChange={this.handleInput}
        />
        {this.state.errorMessage && (
          <ErrorMessage>
            {this.state.errorMessage}
          </ErrorMessage>
        )}
        <StyledCheckbox
          name='rememberMe'
          onChange={this.handleInput}
        >
          로그인 상태 유지
        </StyledCheckbox>
        <Submit value='로그인' />
      </Form>
      <Links>
        <Link href={this.props.paths.newPassword}>
          비밀번호 찾기
        </Link>
      </Links>
      <SNSLink href={this.props.paths.kakaotalk}>
        <KakaotalkIcon />
        카카오 로그인
      </SNSLink>
    </Container>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import MoreButton from 'components/atoms/MoreButton';
import Dropdown from 'components/molecules/Dropdown';

import { gridContainer, gridItem } from 'styles/grid.js';

const List = styled.section`
  ${gridContainer}
  margin: 0 8px 32px;
`;

const Title = styled.h2`
  ${gridItem(12)}
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export default class DropdownList extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    path: PropTypes.string,
    length: PropTypes.number
  }

  state = {
    index: 0
  }

  handleIndex = (index) => {
    this.setState(() => ({ index }));
  }

  render = () => (
    <List>
      <Title>
        {this.props.title}
      </Title>
      {this.props.items.map((item, index) => (
        <Dropdown
          {...item}
          key={item.title}
          active={this.state.index === index}
          onClick={() => this.handleIndex(index)}
        />
      ))}
      {(this.props.items.length >= this.props.length && this.props.path)
        && <MoreButton path={this.props.path} />
      }
    </List>
  );
}

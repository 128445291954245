import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mediaQuery } from 'styles/media.js';

import CurrentUserContext from 'components/contexts/CurrentUserContext';
import useMedia from 'components/hooks/useMedia';

import MainHeader from 'components/organisms/MainHeader';
import FlashMessage from 'components/organisms/FlashMessage';
import MainFooter from 'components/organisms/MainFooter';

import MaximKoreaLogo from 'images/img-logo-maximkorea.svg';

const Main = styled.main.attrs({
  role: 'main'
})`
  position: relative;
`;

const MaximKoreaPath = styled.a`
  display: flex;
  position: absolute;
  z-index: 5;
  top: 35vw;
  left: 0;
  padding: 12px;
  background-color: rgba(0, 0, 0, .7);
  transform: translate(-50%, -100%) rotate(90deg);
  transform-origin: bottom center;
`;

const MaximkoreaLink = () => (
  <MaximKoreaPath href='http://www.maximkorea.net'>
    <MaximKoreaLogo />
  </MaximKoreaPath>
);

function HomeTemplate({
  topBanner, overlay, headerProps, flashProps, footerProps, currentUser, children
}) {
  const isMobile = useMedia(mediaQuery.mobile);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {topBanner}

      <MainHeader {...headerProps} user={currentUser} />

      {flashProps && <FlashMessage {...flashProps} />}

      <Main>
        {isMobile && <MaximkoreaLink />}

        {children}
      </Main>
      <MainFooter {...footerProps} />

      {isMobile && overlay}
    </CurrentUserContext.Provider>
  );
}

HomeTemplate.propTypes = {
  topBanner: PropTypes.node,
  overlay: PropTypes.node,
  headerProps: PropTypes.object,
  flashProps: PropTypes.object,
  footerProps: PropTypes.object
};

export default HomeTemplate;

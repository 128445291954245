import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import MoreIcon from 'images/ic-more.svg';

import Oswald from 'styles/Oswald.js';
import { secondaryButton } from 'styles/button.js';

const StyledMoreButton = styled.a`
  ${secondaryButton}
  ${props => (props.Oswald && Oswald())}
  display: inline-flex;
  margin: 0 auto;
  padding: 16px 20px 16px 16px;
  border-radius: 24px;
  align-items: center;
  line-height: 1;
  font-weight: bold;
`;

const StyledMoreIcon = styled(MoreIcon)`
  width: 13px;
  height: 13px;
  margin-right: 8px;
`;

const MoreButton = props => (
  <StyledMoreButton
    href={props.path}
    Oswald={props.Oswald}
    className={props.className}
  >
    <StyledMoreIcon />
    {props.text}
  </StyledMoreButton>
);

MoreButton.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
  Oswald: PropTypes.bool
};

MoreButton.defaultProps = {
  text: 'MORE',
  Oswald: true
};

export default MoreButton;

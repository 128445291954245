import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useMedia from 'components/hooks/useMedia';

import media, { mediaQuery } from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { black, white } from 'styles/palette.js';

// Children components
import Artists from './Artists';
import Labels from './Labels';
import Keywords from './Keywords';
import Viewers from './Viewers';
import SubscriptionButton from './SubscriptionButton';
import Synopsis from './Synopsis';
import Coupons from './Coupons';
import NavigationButton from './NavigationButton';

const mainImageWidth = 1440;
const mainImageHeight = 450;

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  ${media.mobile`
    background-color: ${white};
  `}
  ${media.desktop`
    ${gridContainer('desktop')}
    align-items: flex-start;
  `}
`;

const Image = styled.div`
  width: 100%;
  padding-top: 62.5%;
  background-image: ${props => `url(${props.src})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  ${media.desktop`
    position: absolute;
    right: 0;
    left: 0;
    max-width: ${mainImageWidth}px;
    padding-top: ${mainImageHeight}px;
    margin: 0 auto;
  `}
`;

const GradientImage = styled.div`
  display: flex;
  position: absolute;
  z-index: -1;
  right: 0;
  left: 0;
  padding-top: ${(mainImageHeight / mainImageWidth) * 100}%;
  background-image: ${props => `url(${props.src})`};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 100%;
  opacity: .6;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .6), ${black});
  }
`;

const Info = styled.div`
  ${gridItem(null, 9)}
  position: relative;
  margin: -24px 8px 0;
  padding: 24px 8px 0;
  background-color: ${white};
  text-align: center;
  ${media.desktop`
    margin-top: 400px;
    margin-right: 0;
    margin-bottom: 10px;
    padding: 28px 20px 20px;
    text-align: left;
  `}
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  ${media.desktop`
    font-size: 36px;
  `}
`;

const Buttons = styled.div`
  ${gridItem(null, 3)}
  z-index: 1;
  padding: 16px 16px 24px;
  background-color: ${white};
  text-align: center;
  ${media.desktop`
    margin-top: 400px;
  `}
`;

function ComicInfo({
  title,
  synopsis,
  desktopImage,
  image,
  artists,
  keywords,
  label,
  couponsCount,
  firstEpisode,
  lastViewedEpisode,
  subscribe,
  viewedComicsCount,
  archived = false
}) {
  const isDesktop = useMedia(mediaQuery.desktop);

  return (
    <Wrapper>
      <Image src={isDesktop ? desktopImage : image} />
      {isDesktop && <GradientImage src={desktopImage} />}

      <Info>
        <Labels label={label} />
        <Title>{title}</Title>
        <Artists artists={artists} />
        <Synopsis content={synopsis} />
        <Coupons count={couponsCount} />
      </Info>

      <Buttons>
        <NavigationButton
          firstEpisodePath={firstEpisode}
          lastViewedEpisode={lastViewedEpisode}
        />
        {!archived && (
          <React.Fragment>
            <Viewers count={viewedComicsCount} />
          </React.Fragment>
        )}
      </Buttons>
    </Wrapper>
  );
}

ComicInfo.propTypes = {
  title: PropTypes.string,
  synopsis: PropTypes.string,
  desktopImage: PropTypes.string,
  image: PropTypes.string,
  artists: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  })),
  keywords: PropTypes.arrayOf(PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })),
  label: PropTypes.shape({
    adult: PropTypes.bool,
    dormant: PropTypes.bool,
    free: PropTypes.bool,
    new: PropTypes.bool,
    sale: PropTypes.bool
  }),
  couponsCount: PropTypes.number,
  firstEpisode: PropTypes.string,
  lastViewedEpisode: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string
  }),
  subscribe: PropTypes.shape({
    path: PropTypes.string,
    active: PropTypes.bool
  }),
  viewedComicsCount: PropTypes.number
};

export default ComicInfo;

import { css } from 'styled-components';

import media from 'styles/media.js';

const grid = {
  columns: 12,
  gutter: {
    mobile: 8,
    desktop: 20
  }
};

export const maxWidth = 1280;

export const gridContainer = (type = 'all') => (type === 'desktop' ? css`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: ${maxWidth}px;
  margin-right: auto;
  margin-left: auto;
` : css`
  display: flex;
  flex-flow: row wrap;
  margin-right: ${grid.gutter.mobile}px;
  margin-left: ${grid.gutter.mobile}px;
  ${media.desktop`
    width: 100%;
    max-width: ${maxWidth}px;
    margin-right: auto;
    margin-left: auto;
  `}
`);

export const gridItem = (columns, desktopColumns = columns) => css`
  ${columns && `
    max-width: calc(${100 * (columns / grid.columns)}% - ${grid.gutter.mobile * (1 + (columns / grid.columns))}px);
    margin-left: ${grid.gutter.mobile}px;
    flex-basis: calc(${100 * (columns / grid.columns)}% - ${grid.gutter.mobile * (1 + (columns / grid.columns))}px);
  `}
  ${media.desktop`
    max-width: calc(${100 * (desktopColumns / grid.columns)}% - ${grid.gutter.desktop * (1 + (desktopColumns / grid.columns))}px);
    margin-left: ${grid.gutter.desktop}px;
    flex-basis: calc(${100 * (desktopColumns / grid.columns)}% - ${grid.gutter.desktop * (1 + (desktopColumns / grid.columns))}px);
  `}
`;

export const gridCollapse = (type = 'all') => (type === 'desktop' ? css`
  width: calc(100% + ${grid.gutter.desktop * 2}px);
  margin-right: ${grid.gutter.desktop * -1}px;
  margin-left: ${grid.gutter.desktop * -1}px;
` : css`
  width: calc(100% + ${grid.gutter.mobile * 2}px);
  margin-right: ${grid.gutter.mobile * -1}px;
  margin-left: ${grid.gutter.mobile * -1}px;
  ${media.desktop`
    width: calc(100% + ${grid.gutter.desktop * 2}px);
    margin-right: ${grid.gutter.desktop * -1}px;
    margin-left: ${grid.gutter.desktop * -1}px;
  `}
`);

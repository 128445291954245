import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Media from 'react-media';

import ListHeader from 'components/atoms/ListHeader';
import MoreButton from 'components/atoms/MoreButton';

import media, { mediaQuery } from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';

const List = styled.section`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  ${media.desktop`
    ${gridContainer('desktop')}
    margin-top: 32px;
    margin-bottom: 32px;
  `}
`;

const SmallTitle = styled.h2`
  ${gridItem(null, 12)}
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  ${media.desktop`
    font-size: 26px;
    margin-bottom: 32px;
  `}
`;

const Event = styled.a`
  ${gridItem(null, 12)}
  display: block;
  overflow: hidden;
  ${props => props.expired && `
    opacity: .75;
    filter: grayscale(1);
  `}
  ${media.desktop`
    max-height: 160px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const StyledMoreButton = styled(MoreButton)`
  margin-top: 16px;
  ${media.desktop`
    margin-top: 12px;
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  vertical-align: middle;
`;

export default class EventList extends React.Component {
  static propTypes = {
    events: PropTypes.array.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    smallTitle: PropTypes.string,
    path: PropTypes.string,
    expired: PropTypes.bool
  };

  state = {
    isClient: false
  }

  componentDidMount() {
    this.setState(() => ({ isClient: true }));
  }

  render = () => (
    <List>
      {this.props.title
        && (
        <ListHeader
          title={this.props.title}
          subtitle={this.props.subtitle}
        />
        )
      }
      {this.props.smallTitle
        && (
        <SmallTitle>
          {this.props.smallTitle}
        </SmallTitle>
        )
      }
      {this.state.isClient && this.props.events.map(event => (
        <Event href={event.path} key={event.name} expired={this.props.expired}>
          <Media query={mediaQuery.desktop}>
            {matches => (
              <Image src={matches ? event.desktopImage : event.image} alt={event.name} />
            )}
          </Media>
        </Event>
      ))}
      {this.props.path
        && <StyledMoreButton path={this.props.path} />
      }
    </List>
  );
}

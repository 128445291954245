import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import { red, black, white } from 'styles/palette.js';

const StyledLink = styled.a`
  font-weight: bold;
  text-align: center;
  ${media.mobile`
    flex: 1;
    padding: 12px 0;
    background-color: ${props => (props.active ? red : black)};
    color: ${white};
    font-size: 14px;
  `}
  ${media.desktop`
    flex: 0 0 auto;
    color: ${props => (props.active ? red : black)};
    font-size: 20px;
    line-height: normal;
  `}
`;

const MainHeaderLink = props => (
  <StyledLink href={props.path} active={props.active} className={props.className}>
    {props.text}
  </StyledLink>
);

MainHeaderLink.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  className: PropTypes.string
};

export default MainHeaderLink;

import { useLayoutEffect } from 'react';

function useLockBodyScroll() {
  useLayoutEffect(() => {
    const { body } = document;
    const widthWithScroll = body.offsetWidth;
    body.style.overflow = 'hidden';
    const widthWithoutScroll = body.offsetWidth;
    body.style.paddingRight = `${widthWithoutScroll - widthWithScroll}px`;

    return () => {
      body.style.overflow = null;
      body.style.paddingRight = null;
    };
  }, []);
}

export default useLockBodyScroll;

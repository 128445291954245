import React from 'react';
import PropTypes from 'prop-types';

import CommonTemplate from 'components/templates/CommonTemplate';
import LibraryCardList from 'components/organisms/LibraryCardList';
import { Tabs, Tab } from 'components/atoms/Tab';

const LibraryPage = ({ layoutProps, libraryPaths, ...CardListProps }) => (
  <CommonTemplate
    {...layoutProps}
  >
    <LibraryCardList {...CardListProps} />
  </CommonTemplate>
);

LibraryPage.propTypes = {
  layoutProps: PropTypes.object.isRequired,
  libraryPaths: PropTypes.array.isRequired
};

export default LibraryPage;

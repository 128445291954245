import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { black, white, red } from 'styles/palette.js';
import media from 'styles/media.js';

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  ${props => (props.type === 'error' ? `
    background-color: ${red}
    color: white;
  ` : `
    border-top: 1px solid;
    border-bottom: 1px solid;
    background-color: ${white};
    color: ${black};
  `)};
  font-size: 14px;
  font-weight: bold;
  ${media.desktop`
    height: 42px;
    font-size: 16px;
  `}
`;

const FlashMessage = props => (
  <Message type={props.type}>
    {props.text}
  </Message>
);

FlashMessage.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default FlashMessage;

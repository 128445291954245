import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import { black, white } from 'styles/palette.js';

const Navigation = styled.nav`
  display: flex;
  padding-top: 24px;
  align-items: flex-start;
  justify-content: center;
  ${media.desktop`
    padding-top: 32px;
    padding-bottom: 21px;
    background-color: ${black};
  `}
`;

const Path = styled.a`
  margin: 0 8px;
  ${props => props.active && `
    padding-bottom: 3px;
    border-bottom: 3px solid;
    font-weight: bold;
  `}
  ${media.desktop`
    margin: 0 16px;
    color: ${white};
    font-size: 18px;
    ${props => props.active && `
      padding-bottom: 8px;
    `}
  `}
`;

const SubNavigation = props => (
  <Navigation>
    {props.paths.map(path => (
      <Path
        key={path.text}
        href={path.path}
        active={props.request.includes(path.path)}
      >
        {path.text}
      </Path>
    ))}
  </Navigation>
);

SubNavigation.propTypes = {
  paths: PropTypes.array.isRequired,
  request: PropTypes.string.isRequired
};

export default SubNavigation;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import AuthenticityToken from 'components/atoms/AuthenticityToken';
import ShopRadioButton from 'components/atoms/ShopRadioButton';

import media from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import { primaryButton, secondaryButton } from 'styles/button.js';
import { gridItem, gridContainer, gridCollapse } from 'styles/grid.js';
import { lightGray, white, red } from 'styles/palette.js';

const Container = styled.section`
  display: flex;
  flex-flow: column;
  padding: 32px 16px;
  background-color: ${white};
  ${media.desktop`
    max-width: 414px;
    margin: 80px auto 40px;
    padding: 32px;
    border: 3px solid ${lightGray};
  `}
`;

const AdultIcon = styled.div`
  ${Oswald}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  margin: 0 auto 16px;
  border: 6px solid ${red};
  border-radius: 50%;
  font-size: 80px;
  line-height: 1;
`;

const Description = styled.p`
  margin-bottom: 16px;
  line-height: 1.6;
  text-align: center;
  word-break: keep-all;
`;

const Exit = styled.a`
  ${primaryButton}
  width: 100%;
  margin-bottom: 48px;
  text-align: center;
`;

const Token = styled.input.attrs({
  type: 'hidden'
})``;

const Form = styled.form`
  width: 100%;
  ${media.desktop`
    ${gridContainer('desktop')}
    ${gridCollapse('desktop')}
  `}
`;

const Submit = styled.button.attrs({
  type: 'submit'
})`
  ${gridItem(null, 12)}
  ${secondaryButton}
  width: 100%;
  margin-top: 12px;
  font-weight: bold;
`;

export default class AdultForm extends React.Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    redirect: PropTypes.string.isRequired
  }

  state = {
    method: ''
  }

  handleInput = (event) => {
    event.persist();
    const { target } = event;
    const { name, value } = target;

    this.setState(() => ({ [name]: value }));
  }

  handleSubmit = () => {
    window.open('about:blank', 'certification', 'top=100,left=100,width=600,height=726,scrollbars=yes,resizable=yes,toolbar=no,menubar=no');
    this.target = 'certification';
  }

  render = () => (
    <Container>
      <AdultIcon>
        19
      </AdultIcon>
      <Description>
        이 정보 내용은 청소년 유해매체물로써 정보통신망이용촉진 및 정보 보호 등에 관한 법률 및 청소년 보호법의 규정에 의하여 19세 미만의 청소년이 이용할 수 없습니다.
      </Description>
      <Exit href={this.props.redirect}>
        19세 미만 나가기
      </Exit>
      <Form onSubmit={this.handleSubmit} action={this.props.path} method='POST' target='certification'>
        <AuthenticityToken />
        <ShopRadioButton
          name='method'
          onChange={this.handleInput}
          value='mobile'
          checked={this.state.method === 'mobile'}
        >
          안심 본인 인증(휴대폰)
        </ShopRadioButton>
        <Submit>
          인증하기
        </Submit>
      </Form>
    </Container>
  );
}

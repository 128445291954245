import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { secondaryButton } from 'styles/button.js';
import axios from 'axios';
import Rails from 'rails-ujs';
import SubscribeIcon from 'images/ic-subscribe.svg';
import {
  black
} from 'styles/palette.js';

const StyledIcon = styled(SubscribeIcon)`
  margin-right: 4px;
  vertical-align: text-bottom;
  ${props => (
    props.black && `
      path {
        fill: ${black};
      }
    `
  )};
`;

function SubscriptionButton({ subscribe }) {
  const [subscribed, setSubscribed] = useState(subscribe.active);

  const SubscribeButton = styled.button`
    ${secondaryButton}
    display: block;
    width: 100%;
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
  `;

  const handelSubscription = () => {
    if (subscribed) {
      if (window.confirm('정말 해제하시겠습니까?')) {
        axios({
          method: 'delete',
          url: subscribe.path,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': Rails.csrfToken()
          }
        }).then(() => {
          setSubscribed(false);
        }).catch(() => {
          setSubscribed(false);
        });
      }
    } else {
      axios({
        method: 'post',
        url: subscribe.path,
        responseType: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': Rails.csrfToken()
        }
      }).then(() => {
        setSubscribed(true);
      }).catch((error) => {
        if (error.response.data.url) {
          window.location.href = error.response.data.url;
        } else {
          setSubscribed(true);
        }
      });
    }
  };

  return (
    <SubscribeButton onClick={handelSubscription}>
      <StyledIcon black={subscribed ? 1 : 0} />
      {subscribed ? '구독중' : '구독하기'}
    </SubscribeButton>
  );
}

SubscriptionButton.propTypes = {
  subscribe: PropTypes.shape({
    active: PropTypes.bool,
    path: PropTypes.string
  })
};

export default SubscriptionButton;

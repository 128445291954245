import React from 'react';
import PropTypes from 'prop-types';

import PresentsTemplate from 'components/templates/PresentsTemplate';

export default class PresentsPage extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    presents: PropTypes.array.isRequired,
    presentsCount: PropTypes.number.isRequired
  }

  state = {
    isHidingExpiredItems: this.props.presents[0].expired,
    openedItem: (this.props.presents.find(date => date.today) || { title: '' }).title
  }

  openItem = title => this.setState(
    prevState => ({ openedItem: title === prevState.openedItem ? '' : title })
  )

  renderExpiredItems = () => {
    this.setState({ isHidingExpiredItems: false });
  }

  render = () => (
    <PresentsTemplate
      title={this.props.title}
      presents={this.state.isHidingExpiredItems
        ? this.props.presents.filter(date => !date.expired)
        : this.props.presents
      }
      presentsCount={this.props.presentsCount}
      renderExpiredItems={this.renderExpiredItems}
      isHidingExpiredItems={this.state.isHidingExpiredItems}
      openItem={this.openItem}
      openedItem={this.state.openedItem}
    />
  )
}

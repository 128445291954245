import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';

import styled from 'styled-components';

import Media from 'react-media';

import CoinIcon from 'components/atoms/CoinIcon';
import SearchBar from 'components/atoms/SearchBar';

import media, { mediaQuery } from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import {
  black,
  lightGray,
  white,
  red
} from 'styles/palette.js';

const Container = React.memo(styled.div`
  display: flex;
  flex-flow: column;
`, () => true);

const Menu = styled.div`
  position: absolute;
  z-index: 10;

  ${media.mobile`
    top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 24px 16px 60px;
    background-color: ${black};
    overflow-y: auto;
    color: ${white};
  `}

  ${media.desktop`
    top: 100px;
    right: 20px;
    width: 414px;
    padding: 32px;
    background-color: ${white};
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, .33), 0 1px 2px 0 rgba(0, 0, 0, .24);
    color: ${black};
  `}
`;

const Account = styled.div`
  margin-bottom: 16px;
  text-align: center;
  ${media.desktop`
    margin-bottom: 8px;
  `}
`;

const Email = styled.p`
  ${Oswald}
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: bold;
  word-break: break-all;
`;

const Coin = styled.div`
  ${Oswald}
  display: flex;
  margin: 8px 0 16px;
  align-items: center;
  justify-content: center;
`;

const CoinText = styled.span`
  margin-left: 4px;
  font-size: 18px;
`;

const AccountLink = styled.a`
  display: inline-block;
  padding: 12px 14px;
  margin: 0 8px;
  border-radius: 20px;
  background-color: ${white};
  color: ${black};
  font-weight: bold;
  ${media.desktop`
    background-color: ${black};
    color: ${white};
  `}
`;

const Links = styled.div`
  ${media.mobile`
    margin-bottom: 16px;
  `}
`;

const Link = styled.a`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  font-size: 20px;
  font-weight: bold;
  ${media.desktop`
    border-color: ${lightGray};
  `}
`;

const NewLabel = styled.span`
  color: ${white};
  background-color: ${red};
  padding: 0.2em;
  font-size: 0.7em;
  border-radius: 0.2em;
  margin-left: 0.5em;
`;

export default class SideMenu extends React.Component {
  static propTypes = {
    display: PropTypes.bool.isRequired,
    user: PropTypes.object,
    links: PropTypes.array.isRequired,
    signUpPath: PropTypes.string,
    logInPath: PropTypes.string,
    logOutPath: PropTypes.string,
    accountPath: PropTypes.string,
    libraryPath: PropTypes.string,
    certificationPath: PropTypes.string,
    searchPath: PropTypes.string
  }

  componentWillReceiveProps(props) {
    const { body } = document;

    if (props.display && window.matchMedia(mediaQuery.mobile).matches) {
      body.style.position = 'fixed';
      body.style.top = 0;
      body.style.right = 0;
      body.style.bottom = 0;
      body.style.left = 0;
      body.style.overflowY = 'scroll';
    } else {
      body.style.position = null;
      body.style.top = null;
      body.style.right = null;
      body.style.bottom = null;
      body.style.left = null;
      body.style.overflowY = null;
    }
  }

  renderLinks = () => (
    <Links>
      {this.props.links.map(link => (
        <Link href={link.path} key={link.text}>
          {link.text}
          {link.new && <NewLabel>N</NewLabel>}
        </Link>
      ))}
      {this.props.user.isPresent && (
        <Media
          query={mediaQuery.mobile}
          render={() => (
            <Link href={this.props.logOutPath}>
              로그아웃
            </Link>
          )}
        />
      )}
    </Links>
  )

  renderAccountInfo = () => (
    <Account>
      <Email>
        {this.props.user.identity}
      </Email>
      <Coin>
        <CoinIcon size={22} fontSize={11} />
        <CoinText>
          {this.props.user.coin}
        </CoinText>
      </Coin>
      <AccountLink href={this.props.accountPath}>
        내 정보
      </AccountLink>
      <AccountLink href={this.props.libraryPath}>
        구매 작품
      </AccountLink>
      {!this.props.user.isCertified && (
        <Media
          query={mediaQuery.mobile}
          render={() => (
            <div />
          )}
        />
      )}
    </Account>
  )

  renderLogin = () => (
    <Account>
      <AccountLink href={this.props.logInPath}>
        로그인
      </AccountLink>
    </Account>
  )

  render = () => (
    <Media query={mediaQuery.desktop}>
      {matches => (
        matches ? (
          (this.props.display && this.props.user.isPresent) && (
            <Menu>
              {this.renderAccountInfo()}
              {this.renderLinks()}
            </Menu>
          )
        ) : (
          <Motion style={{ x: spring(!this.props.display * -100) }}>
            { style => (
              <Menu
                id='sidemenu'
                style={{ transform: `translateX(${style.x}%)` }}
              >
                <Container>
                  {this.props.user.isPresent ? this.renderAccountInfo() : this.renderLogin()}
                  <SearchBar searchPath={this.props.searchPath} placeholder='작가명, 작품명을 검색해보세요' />
                  {this.renderLinks()}
                </Container>
              </Menu>
            )}
          </Motion>
        )
      )}
    </Media>
  )
}

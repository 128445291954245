import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import SearchHeader from 'components/atoms/SearchHeader';
import NoticeMessage from 'components/atoms/NoticeMessage';

import ComicCard from 'components/molecules/ComicCard';

import media from 'styles/media.js';
import ellipsis from 'styles/ellipsis.js';
import { gridContainer } from 'styles/grid.js';
import { gray } from 'styles/palette.js';

const List = styled.section`
  display: flex;
  flex-flow: column;
`;

const Cards = styled.div`
  ${gridContainer}
  margin-top: 24px;
  margin-bottom: 24px;
  ${media.desktop`
    margin-top: 32px;
    margin-bottom: 32px;
  `}
`;

const Text = styled.p`
  ${ellipsis}
  margin-top: 8px;
  color: ${gray};
  font-size: 13px;
`;


const ComicCardList = props => (
  <List>
    {props.title
      && (
      <SearchHeader
        title={props.title}
        subtitle={props.subtitle}
      />
      )
    }
    <Cards>
      {props.comics.length
        ? props.comics.map(comic => (
          <ComicCard
            {...comic}
            grid={{
              mobile: 6,
              desktop: 3
            }}
            key={comic.title}
          >
            {comic.text
              && (
              <Text>
                {comic.text}
              </Text>
              )
            }
          </ComicCard>
        ))
        : <NoticeMessage message='검색 결과가 없습니다.' />
      }
    </Cards>
  </List>
);

ComicCardList.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  comics: PropTypes.array.isRequired
};

export default ComicCardList;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { lightGray } from 'styles/palette.js';

const Section = styled.section`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
  width: 100%;
  margin-bottom: 24px;
  padding-top: 24px;
  border-top: 1px solid ${lightGray};
  font-size: 18px;
  font-weight: bold;
`;

const AccountEditorSection = props => (
  <Section>
    <SectionTitle>
      {props.title}
    </SectionTitle>
    {props.children}
  </Section>
);

AccountEditorSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default AccountEditorSection;

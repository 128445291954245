import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Modal from 'components/molecules/Modal';
import { Login, NeedCertification, Certified } from 'components/molecules/ModalBodies';

import ViewerHeader from 'components/molecules/ViewerHeader';
import ViewerFooter from 'components/molecules/ViewerFooter';
import EventList from 'components/organisms/EventList';

import BylineImage from 'images/img-logo-byline.png';

import { white } from 'styles/palette.js';

const HeaderHeight = 60;
const FooterHeight = 60;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  padding: ${HeaderHeight}px 0 ${FooterHeight}px;
  background-color: ${props => props.background};
`;

const Cuts = styled.main`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Cut = styled.img`
  flex: 0 0 auto;
  user-drag: none;
  user-select: none;
`;

const Byline = styled.img`
  margin: 24px 0 32px;
  align-self: center;
`;

function useScroll(hook, threshold = FooterHeight) {
  const handler = () => {
    const scrollTop = window.pageYOffset;
    const scrollBottom = window.innerHeight + scrollTop;
    const bodyHeight = document.body.scrollHeight;

    hook(bodyHeight - scrollBottom < threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);
}

function ViewerForMarketing({
  title, path, cuts, events, user, subscribe, previous, next, ...props
}) {
  const [navigate, setNavigate] = useState(true);
  const [modalBody, setModalBody] = useState(null);

  useScroll(enter => setNavigate(enter));

  const onClickPrev = (e) => {
    if (!previous.isMarketing && previous.url) {
      e.preventDefault();
      if (user.isPresent) {
        if (user.isCertified) {
          setModalBody(<Certified path={previous.url} />);
        } else {
          setModalBody(<NeedCertification certificationPath={`/certification?redirect_to=${previous.url}&exit_to=${path.comic}`} />);
        }
      } else {
        setModalBody(<Login loginPath={`/login?redirect_to=${previous.url}`} signUpPath={`/signup?redirect_to=${previous.url}`} />);
      }
    }
  };

  const onClickNext = (e) => {
    if (!next.isMarketing && next.url) {
      e.preventDefault();
      if (user.isPresent) {
        if (user.isCertified) {
          setModalBody(<Certified path={next.url} />);
        } else {
          setModalBody(<NeedCertification certificationPath={`/certification?redirect_to=${next.url}&exit_to=${path.comic}`} />);
        }
      } else {
        setModalBody(<Login loginPath={`/login?redirect_to=${next.url}`} signUpPath={`/signup?redirect_to=${next.url}`} />);
      }
    }
  };

  return (
    <Wrapper background={white}>
      <ViewerHeader
        active={navigate}
        homePath='/'
        comicPath={path.comic}
        isUser={user.isPresent}
        subscribe={subscribe}
        height={HeaderHeight}
      >
        {title}
      </ViewerHeader>

      <Cuts onClick={() => setNavigate(!navigate)}>
        {cuts.map((cut, index) => (
          <Cut
            onContextMenu={e => e.preventDefault()}
            src={cut}
            alt={`컷 ${index}`}
            key={cut}
          />
        ))}
        <Byline src={BylineImage} alt='MAXIM COMICS LOGO' />
        {props.events && <EventList events={props.events} />}
      </Cuts>

      <ViewerFooter
        active={navigate}
        isPreviousShowable
        isNextShowable
        previousPath={previous.url}
        nextPath={next.url}
        height={FooterHeight}
        onPrev={onClickPrev}
        onNext={onClickNext}
      />

      {modalBody && (<Modal onClose={() => setModalBody(null)}>{modalBody}</Modal>)}
    </Wrapper>
  );
}

export default ViewerForMarketing;

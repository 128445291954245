import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';

import MainHeaderLink from 'components/atoms/MainHeaderLink';
import SideMenu from 'components/molecules/SideMenu';

import MobileLogo from 'images/img-logo-maximcomics-m.svg';
import DesktopLogo from 'images/img-logo-maximcomics.svg';
import MaximKoreaLogo from 'images/img-logo-maximkorea.svg';
import CloseButton from 'images/btn-close.svg';
import MenuButton from 'images/btn-menu.svg';
import PresentButton from 'images/btn-present.svg';

import media, { mediaQuery } from 'styles/media.js';
import { primaryButton } from 'styles/button.js';
import { black, white, red } from 'styles/palette.js';
import { maxWidth } from 'styles/grid.js';

const Header = styled.header`
  background-color: ${white};
  ${media.desktop`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-bottom: 10px;
  `};
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  height: 34px;
  background-color: ${black};
`;

const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${maxWidth}px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 20px;
`;

const MaximKoreaPath = styled.a`
  display: flex;
  margin-right: auto;
`;

const AccountLink = styled.a`
  color: ${white};
  margin-left: 24px;
  font-size: 14px;
`;

const Wrapper = styled.div`
  ${media.desktop`
    display: flex;
    position: relative;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: ${maxWidth}px;
    margin-right: auto;
    margin-left: auto;
    padding: 32px 20px 16px;
  `}
`;

const Navigation = styled.nav`
  display: flex;
  ${media.desktop`
    flex: 0 0 auto;
    align-items: center;
    margin-right: auto;
  `}
`;

const StyledMainHeaderLink = styled(MainHeaderLink)`
  ${media.desktop`
    margin-left: 42px;
  `}
`;

const MainMenu = styled.div`
  display: flex;
  height: 60px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
`;

const CertificationLink = styled.a`
  ${primaryButton}
  padding: 12px 16px;
`;

const Link = React.memo(styled.a`
  display: flex;
  position: relative;
`, () => true);

const Button = styled.button`
  display: flex;
  ${media.desktop`
    margin-left: 24px;
  `}
`;

const NewNotification = styled.div`
  width: 7px;
  height: 7px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background-color: ${red};
`;

const MobileMenu = ({ handleType, type }) => {
  switch (type) {
    default:
      return (
        <MainMenu>
          <Button onClick={() => handleType('Menu')}>
            <MenuButton />
          </Button>
          <Link href='/'>
            <MobileLogo />
          </Link>
          <div />
        </MainMenu>
      );

    case 'Menu':
      return (
        <MainMenu>
          <Button onClick={() => handleType('Default')}>
            <CloseButton />
          </Button>
          <Link href='/'>
            <MobileLogo />
          </Link>
          <div />
        </MainMenu>
      );
  }
};

export default class MainHeader extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    requestPath: PropTypes.string,
    links: PropTypes.array.isRequired,
    sideLinks: PropTypes.array.isRequired,
    paths: PropTypes.object.isRequired,
    searchPath: PropTypes.string.isRequired,
    presentsPath: PropTypes.string.isRequired
  };

  static defaultProps = {
    requestPath: '/'
  }

  state = {
    type: 'Default'
  }

  handleType = (type) => {
    this.setState(() => ({ type }));
  }

  render = () => (
    <Media query={mediaQuery.desktop}>
      {matches => (
        <Header>
          {matches && (
            <TopBar>
              <TopBarWrapper>
                <MaximKoreaPath href='http://www.maximkorea.net'>
                  <MaximKoreaLogo />
                </MaximKoreaPath>
                {this.props.user.isPresent
                  ? (
                    <AccountLink href={this.props.paths.logOutPath}>
                    로그아웃
                    </AccountLink>
                  )
                  : (
                    <React.Fragment>
                      <AccountLink href={this.props.paths.logInPath}>
                      로그인
                      </AccountLink>
                    </React.Fragment>
                  )
                }
              </TopBarWrapper>
            </TopBar>
          )}
          <Wrapper>
            {matches ? (
              <Link href='/'>
                <DesktopLogo />
              </Link>
            ) : (
              <MobileMenu
                handleType={this.handleType}
                type={this.state.type}
                presentLink={(
                  <Link href={this.props.presentsPath}>
                    <PresentButton />
                    {this.props.newPresents && <NewNotification />}
                  </Link>
                )}
              />
            )}
            <Navigation>
              {this.props.links.map(link => (
                <StyledMainHeaderLink
                  active={this.props.requestPath.includes(link.path)}
                  path={link.path}
                  text={link.text}
                  hidden={link.hidden}
                  key={link.text}
                />
              ))}
            </Navigation>
            {matches && (
              <React.Fragment>
                {this.props.user.isPresent
                  && (
                  <Button type='button' onClick={() => this.handleType(this.state.type === 'Menu' ? 'default' : 'Menu')}>
                    <MenuButton />
                  </Button>
                  )
                }
              </React.Fragment>
            )}
            <SideMenu
              links={this.props.sideLinks}
              user={this.props.user}
              display={this.state.type === 'Menu'}
              searchPath={this.props.searchPath}
              {...this.props.paths}
            />
          </Wrapper>
        </Header>
      )}
    </Media>
  );
}

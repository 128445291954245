import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';

import CommonTemplate from 'components/templates/CommonTemplate';
import FeaturedComicCardList from 'components/organisms/FeaturedComicCardList';
import { DesktopSchedules, MobileSchedules } from 'components/organisms/Schedules';

import media, { mediaQuery } from 'styles/media.js';
import { white } from 'styles/palette.js';

// TODO: Tab 컴포넌트로 교체하고 재사용 가능하게 스타일 수정
const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  align-items: flex-start;
  justify-content: center;
  ${media.desktop`
    padding-bottom: 32px;
  `}
`;

const Tab = styled.button`
  margin: 0 8px;
  color: ${white};
  ${props => props.active && `
    padding-bottom: 4px;
    border-bottom: 3px solid;
    font-weight: bold;
    ${media.desktop`
    padding-bottom: 8px;
    `}
  `}
  ${media.desktop`
    margin: 0 16px;
    font-size: 18px;
  `}
`;

const FeaturedComicsLength = {
  mobile: 2,
  desktop: 4
};

export default class SchedulesPage extends React.PureComponent {
  static propTypes = {
    layoutProps: PropTypes.object.isRequired,
    featuredComics: PropTypes.objectOf(PropTypes.array),
    schedules: PropTypes.array
  }

  state = {
    currentTab: 'exclusive',
    selectedDay: this.props.schedules.find(schedule => schedule.default).name
  }

  handleCurrentTab = (value) => {
    this.setState({ currentTab: value });
  }

  selectDay = (value) => {
    this.setState({ selectedDay: value });
  }

  render = () => (
    <CommonTemplate
      {...this.props.layoutProps}
    >
      <Media
        query={mediaQuery.desktop}
      >
        {matches => (
          <React.Fragment>
            {(this.props.featuredComics.exclusive.length > 0
              && this.props.featuredComics.new.length > 0
            ) && (
              <FeaturedComicCardList
                comics={this.props.featuredComics[this.state.currentTab].slice(0, (
                  matches ? FeaturedComicsLength.desktop : FeaturedComicsLength.mobile
                ))}
                header={(
                  <Tabs>
                    <Tab
                      active={this.state.currentTab === 'exclusive'}
                      onClick={() => this.handleCurrentTab('exclusive')}
                    >
                      독점작
                    </Tab>
                    <Tab
                      active={this.state.currentTab === 'new'}
                      onClick={() => this.handleCurrentTab('new')}
                    >
                      신작
                    </Tab>
                  </Tabs>
                )}
              />
            )}
            {matches ? (
              <DesktopSchedules schedules={this.props.schedules} />
            ) : (
              <MobileSchedules
                schedules={this.props.schedules}
                active={this.state.selectedDay}
                selectDay={this.selectDay}
              />
            )}
          </React.Fragment>
        )}
      </Media>
    </CommonTemplate>
  )
}

import { black, lightGray, white } from 'styles/palette.js';

export const primaryButton = () => `
  appearance: none;
  padding: 1em;
  border: 0;
  border-radius: 9999px;
  background-color: ${black};
  color: ${white};
  font-weight: bold;
  cursor: pointer;
  transition: background-color 100ms linear;
  &:hover {
    background-color: #333333;
  }
  &:disabled {
    background-color: ${lightGray};
    cursor: not-allowed;
  }
`;

export const secondaryButton = () => `
  appearance: none;
  position: relative;
  padding: 1em;
  border-radius: 9999px;
  background-color: ${white};
  color: ${black};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 3px solid ${lightGray};
    border-radius: 9999px;
    transition: border-color 100ms linear;
  }
  &:hover::after {
    border-color: #bababa;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

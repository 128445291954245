import React, {
  useState, useContext, useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { forceCheck } from 'react-lazyload';

import Modal from 'components/molecules/Modal';
import EpisodeCard from 'components/molecules/EpisodeCard';

import sortIcon from 'images/ic-sort.svg';

import media from 'styles/media.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { white } from 'styles/palette.js';
import { Login, NeedCertification, Certified } from 'components/molecules/ModalBodies';
import CurrentUserContext from 'components/contexts/CurrentUserContext';

const List = React.memo(styled.section`
  padding: 0 8px 16px;
  ${media.desktop`
    ${gridContainer('desktop')}
    padding: 0 0 24px;
  `}
`);

const Header = React.memo(styled.header`
  ${gridItem(null, 9)}
  display: flex;
  padding: 16px 8px;
  align-items: center;
  justify-content: space-between;
  color: ${white};
  font-size: 14px;
  ${media.desktop`
    padding: 20px 0;
  `}
`);

const Sort = React.memo(styled.button`
  display: inline-flex;
  align-items: center;
  color: ${white};
`);

const SortIcon = React.memo(styled(sortIcon)`
  margin-right: 4px;
  vertical-align: text-bottom;
`);

const Wrapper = React.memo(styled.div`
  ${gridItem(null, 9)}
  display: flex;
  flex-flow: ${props => (props.reverse ? 'column-reverse' : 'column')};
`);

function EpisodeListForMarketing({
  schedules, episodes, comicUrl
}) {
  const [isReversed, setIsReversed] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const user = useContext(CurrentUserContext);

  useEffect(() => {
    forceCheck();
  }, [isReversed]);

  function handleEpisodeClick(event, episode) {
    // TODO: 작품 플래그에 따른 분기 처리
    if (!episode.marketing) {
      event.preventDefault();
      if (user.isPresent) {
        if (user.isCertified) {
          setModalBody(<Certified path={episode.path} />);
        } else {
          setModalBody(<NeedCertification certificationPath={`/certification?redirect_to=${episode.path}&exit_to=${comicUrl}`} />);
        }
      } else {
        setModalBody(<Login loginPath={`/login?redirect_to=${episode.path}`} signUpPath={`/signup?redirect_to=${episode.path}`} />);
      }
    }
  }

  function EpisodeCards() {
    let index = 0;
    return (
      episodes.map((episode) => {
        if (!episode.displayed) {
          index += 1;
        }
        return (
          <EpisodeCard
            {...episode}
            free
            index={episode.displayed ? '-' : index}
            key={episode.title}
            lastViewed={false}
            onClick={e => handleEpisodeClick(e, episode)}
          />
        );
      })
    );
  }

  return (
    <List>
      <Header>
        <span>
          {schedules && `매주 ${schedules} 연재, `}
          {`총 ${episodes.length}화`}
        </span>
        <Sort onClick={() => setIsReversed(!isReversed)}>
          <SortIcon />
          정렬변경
        </Sort>
      </Header>
      <Wrapper reverse={isReversed}>
        <EpisodeCards />
      </Wrapper>
      {modalBody && (
        <Modal onClose={() => setModalBody(null)}>
          {modalBody}
        </Modal>
      )}
    </List>
  );
}

EpisodeListForMarketing.propTypes = {
  episodes: PropTypes.array,
  schedules: PropTypes.string,
  comicUrl: PropTypes.string
};

export default EpisodeListForMarketing;

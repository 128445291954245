export const black = '#000000';
export const darkGray = '#181818';
export const gray = '#999999';
export const lightGray = '#DADADA';
export const backgroundGray = '#EFEFEF';
export const white = '#FFFFFF';
export const red = '#D51315';
export const coinYellow = '#FFE300';
export const freeGreen = '#1EE494';
export const bronze = '#9D8A79';

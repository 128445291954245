
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import media from 'styles/media.js';
import 'styles/center.js';

import { gridContainer } from 'styles/grid.js';
import { primaryButton } from 'styles/button.js';

const Wrapper = styled.section`
  ${gridContainer};
  flex-flow: column;
  align-items: center;
`;

const Title = styled.h1`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
  ${media.desktop`
    font-size: 36px;
    margin-bottom: 32px;
  `}
`;

const Body = styled.span`
  margin-bottom: 16px;
  line-height: 1.6;
  text-align: center;
  white-space: pre-line;
  ${media.desktop`
    margin-bottom: 32px;
    font-size: 20px
  `}
`;

const Button = styled.a`
  ${primaryButton}
  text-align: center;
  ${media.mobile`
    width: 100%;
  `}
  ${media.desktop`
    padding: 1em 1.5em;
  `}
`;

const Unregistered = props => (
  <Wrapper>
    <Title>{props.title}</Title>
    <Body>{props.body}</Body>
    <Button href={props.path}>
      {props.button}
    </Button>
  </Wrapper>
);

Unregistered.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired
};

export default Unregistered;

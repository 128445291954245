import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import styled from 'styled-components';

import Rails from 'rails-ujs';

import Media from 'react-media';

import { Tabs, Tab } from 'components/atoms/Tab';
import ListHeader from 'components/atoms/ListHeader';
import CouponItem from 'components/molecules/CouponItem';

import couponIcon from 'images/ic-coupon.svg';

import media, { mediaQuery } from 'styles/media.js';
import Oswald from 'styles/Oswald.js';
import * as Popup from 'styles/popup.js';
import { gridContainer, gridItem } from 'styles/grid.js';
import { black, lightGray, white } from 'styles/palette.js';
import { primaryButton } from 'styles/button.js';

const List = styled.section`
  margin-bottom: 32px;
  ${media.mobile`
    margin-top: 24px;
  `}
`;

const Container = styled.div`
  ${gridContainer}
  align-items: flex-start;
`;

const CouponAmountWrapper = styled.div`
  ${gridItem(12, 4)}
  display: flex;
  padding: 16px;
  flex-flow: column;
  align-items: center;
  background-color: ${black};
  ${media.desktop`
    padding: 20px;
  `}
`;

const CouponAmount = styled.div`
  ${Oswald}
  display: inline-flex;
  margin-bottom: 12px;
  align-items: center;
  color: ${white};
  font-size: 28px;
  line-height: 1;
`;

const CouponIcon = styled(couponIcon)`
  margin-right: 8px;
`;

const CouponClaimButton = styled.button`
  padding: 12px 16px;
  border-radius: 21px;
  background-color: ${white};
  font-weight: bold;
`;

const Coupons = styled.div`
  ${gridItem(12, 8)}
  border-top: 3px solid ${black};
`;

const CouponHeading = styled.h2`
  ${gridItem(12)}
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: bold;
  ${media.mobile`
    display: none;
  `}
`;

const Coupon = styled.div`
  display: flex;
  padding: 13px 0;
  border-bottom: 1px solid ${lightGray};
  align-items: center;
`;

const EmptyCoupon = styled(Coupon)`
  padding: 48px 0;
  justify-content: center;
  ${media.desktop`
    font-size: 18px;
  `}
`;

const CouponExpiredText = styled.span`
  margin-left: auto;
  font-size: 14px;
`;

const ExchangeButton = styled.button`
  ${primaryButton}
  margin-left: auto;
`;

export default class CouponList extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    coupons: PropTypes.array.isRequired,
    expiredCoupons: PropTypes.array.isRequired,
    claimPath: PropTypes.string.isRequired,
    exchangePath: PropTypes.string.isRequired
  };

  state = {
    currentTab: 'valid',
    isClaiming: false,
    couponCode: '',
    claimError: undefined,
    isClient: false,
    isLoading: false
  }

  componentDidMount() {
    this.setState(() => ({ isClient: true }));
  }

  handleCurrentTab = (tab) => {
    this.setState({ currentTab: tab });
  }

  handleCouponCode = (event) => {
    event.persist();
    this.setState(() => ({ couponCode: event.target.value }));
  }

  handleExchangeOnClick = (couponId) => {
    this.setState({ isLoading: true });
    axios({
      method: 'post',
      url: this.props.exchangePath,
      data: {
        coupon_id: couponId
      },
      headers: {
        'Content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then(() => {
      window.location.reload();
    }).catch((error) => {
      window.location.href = error.response.data.url;
    });
  }

  openClaimPopup = () => {
    const { body } = document;
    const widthWithScroll = body.offsetWidth;
    body.style.overflow = 'hidden';
    const widthWithoutScroll = body.offsetWidth;
    body.style.paddingRight = `${widthWithoutScroll - widthWithScroll}px`;

    this.setState(() => ({ isClaiming: true }));
  }

  closeClaimPopup = (event) => {
    if (event.target === event.currentTarget) {
      const { body } = document;
      body.style.removeProperty('overflow');
      body.style.removeProperty('padding-right');

      this.setState(() => ({ isClaiming: false, claimError: undefined }));
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: 'post',
      url: this.props.claimPath,
      data: {
        code: this.state.couponCode
      },
      headers: {
        'Content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': Rails.csrfToken()
      }
    }).then(() => {
      window.location.reload();
    }).catch((error) => {
      this.setState(() => ({ claimError: error.response.data.message }));
    });
  }

  renderCoupons = coupons => (
    coupons.length
      ? coupons.map(coupon => (
        <Coupon
          key={coupon.id}
        >
          <CouponItem
            {...coupon}
          />
          {
            (!coupon.expiredType && (coupon.exchangeCoin || coupon.exchangeBonuscoin)) ? (
              <ExchangeButton
                onClick={() => this.handleExchangeOnClick(coupon.id)}
                disabled={this.state.isLoading}
              >
                사용
              </ExchangeButton>
            ) : ('')
          }
          {
            coupon.expiredType
            && (
            <CouponExpiredText>
              {
                coupon.expiredType === 'used'
                  ? '사용 완료'
                  : '기간 만료'
              }
            </CouponExpiredText>
            )
          }
        </Coupon>
      ))
      : (
        <EmptyCoupon>
          {this.state.currentTab === 'valid'
            ? '보유한 쿠폰이 없습니다.'
            : '쿠폰 내역이 없습니다.'
        }
        </EmptyCoupon>
      )
  )

  renderClaimPopup = () => (
    <Popup.Background onClick={event => this.closeClaimPopup(event)}>
      <Popup.Popup onSubmit={this.handleSubmit}>
        <Popup.Title>
          쿠폰 등록
        </Popup.Title>
        <Popup.Input
          value={this.state.couponCode}
          onChange={this.handleCouponCode}
          placeholder='쿠폰 코드를 입력하세요'
          error={this.state.claimError && true}
        />
        <Popup.ErrorMessage>
          {this.state.claimError}
        </Popup.ErrorMessage>
        <Popup.Submit value='입력' primary />
        <Popup.Close onClick={event => this.closeClaimPopup(event)}>
          취소
        </Popup.Close>
      </Popup.Popup>
    </Popup.Background>
  )

  renderTabs = () => (
    <Tabs>
      <Tab
        active={this.state.currentTab === 'valid'}
        onClick={() => this.handleCurrentTab('valid')}
      >
        보유 쿠폰
      </Tab>
      <Tab
        active={this.state.currentTab === 'expired'}
        onClick={() => this.handleCurrentTab('expired')}
      >
        지난 쿠폰 내역
      </Tab>
    </Tabs>
  )

  render = () => (
    <List>
      {this.state.isClient
        && (
        <Media query={mediaQuery.desktop}>
          {matches => (
            matches ? this.renderTabs() : (
              <ListHeader title={this.props.title} />
            ))
          }
        </Media>
        )
      }
      <Container>
        <CouponHeading>
          보유 쿠폰
        </CouponHeading>
        <CouponAmountWrapper>
          <CouponAmount>
            <CouponIcon />
            {this.props.coupons.length}
          </CouponAmount>
          <CouponClaimButton onClick={() => this.openClaimPopup()}>
            쿠폰등록
          </CouponClaimButton>
        </CouponAmountWrapper>
        {this.state.isClient
          && (
          <Media
            query={mediaQuery.mobile}
            render={() => this.renderTabs()}
          />
          )
        }
        <Coupons>
          {this.renderCoupons((
            this.state.currentTab === 'valid' ? this.props.coupons : this.props.expiredCoupons
          ))}
        </Coupons>
      </Container>
      {this.state.isClaiming && this.renderClaimPopup()}
    </List>
  );
}

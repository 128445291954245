import styled from 'styled-components';
import media from 'styles/media.js';
import { black, white } from 'styles/palette.js';

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 0 16px;
  align-items: flex-start;
  justify-content: center;
  ${media.desktop`
    margin-bottom: 32px;
    padding: 32px 0 21px;
    background-color: ${black};
  `}
`;

export const Tab = styled.a`
  margin: 0 8px;
  ${props => props.active && `
    padding-bottom: 4px;
    border-bottom: 3px solid;
    font-weight: bold;
  `}

  ${media.desktop`
    margin: 0 16px;
    color: ${white};
    font-size: 18px;
    ${props => props.active && `
      padding-bottom: 8px;
    `}
  `}
`;

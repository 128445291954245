import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import SearchIcon from 'images/btn-search.svg';

import FormTemplate from 'components/templates/FormTemplate';
import AuthenticityToken from 'components/atoms/AuthenticityToken';
import InputField from 'components/atoms/InputField';
import Checkbox from 'components/atoms/Checkbox';

import media from 'styles/media.js';
import { primaryButton } from 'styles/button.js';
import { black, gray } from 'styles/palette.js';

import 'vendor/DaumPostcode.js';

const Title = styled.h1`
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  ${media.desktop`
    font-size: 26px;
  `}
`;

const Form = styled.form`
  display: flex;
  flex-flow: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
`;

const PostcodeField = styled.div`
  display: flex;
  border: 2px solid ${black};
  margin-bottom: 16px;
`;

const PostcodeButton = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  padding: 9px;
  border-right: 2px solid ${black};
`;

const PostcodeInput = styled.input.attrs({
  type: 'text',
  readOnly: true
})`
  flex: 1;
  padding: 0 14px;
`;

const Terms = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${gray};
  font-size: 12px;
  line-height: 1.6;
`;

const Submit = styled.input.attrs({
  type: 'submit'
})`
  ${primaryButton}
`;

export default class GenresPage extends React.PureComponent {
  static propTypes = {
    layoutProps: PropTypes.object.isRequired,
    actionPath: PropTypes.string.isRequired
  }

  state = {
    isAccepted: false,
    address: '',
    zipcode: ''
  }

  openPostcodePopup = () => {
    window.daum.postcode.load(() => {
      new window.daum.Postcode({
        oncomplete: (data) => {
          this.setState({
            zipcode: data.zonecode,
            address: data.address
          });
        }
      }).open();
    });
  }

  render = () => (
    <FormTemplate
      {...this.props.layoutProps}
    >
      <Title>배송정보 입력</Title>
      <Form
        action={this.props.actionPath}
        method='post'
      >
        <AuthenticityToken />
        <InputField
          label='고객명'
          name='name'
          placeholder='홍길동'
          required
        />
        <InputField
          type='tel'
          label='연락처'
          name='phone_number'
          placeholder='000-0000-0000'
          required
        />
        <Label>주소</Label>
        <PostcodeField>
          <input
            type='hidden'
            name='zipcode'
            value={this.state.zipcode}
          />
          <PostcodeButton onClick={this.openPostcodePopup}>
            <SearchIcon />
          </PostcodeButton>
          <PostcodeInput name='address' value={this.state.address} />
        </PostcodeField>
        <InputField
          name='address_detail'
          placeholder='상세주소 직접 입력'
        />
        <Checkbox
          name='terms'
          checked={this.state.isAccepted}
          onChange={() => this.setState(prevState => ({ isAccepted: !prevState.isAccepted }))}
          required
        >
          개인정보 수집 및 이용 동의
        </Checkbox>
        <Terms>
          개인정보 수집 및 이용에 대한 안내
          <br />
          1. 목적 : 이벤트 참여자 개인 식별 및 경품 발송
          <br />
          2. 항목 : 이름, 연락처, 주소
          <br />
          3. 보유기간 : 이벤트 종료 후 3개월까지 보유
          <br />
          위 개인정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부 시에는 이벤트 참여가 제한될 수 있습니다.
        </Terms>
        <Submit
          value='완료'
          disabled={!this.state.isAccepted}
        />
      </Form>
    </FormTemplate>
  )
}
